import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { loadLobby, loadLobbyWithUser } from "./lobby.api";
import { PromotionStatusEnum } from "../pickemWidget/pickem.types";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { useLobbyConfig } from "@/providers/LobbyConfigProvider";
import { trackLobbyTabChange } from "@/services/TagManagerService";

import BannerSlider from "@/components/bannerSlider/BannerSlider";
import PageNotFound from "@/components/pageNotFound/PageNotFound";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import LobbyFilter from "./components/lobbyFilter/LobbyFilter";
import EmptyState from "@/components/emptyState/EmptyState";
import LobbyCard from "./components/lobbyCard/LobbyCard";

import styles from "./LobbyWidget.module.scss";

import notFound from "@/assets/img/newEmpty.svg";

const LobbyWidget = () => {
  const authContext = useContext<AuthContextProps>(AuthContext);

  const {
    data: lobbyData,
    isLoading: isLobbyDataLoading,
    isError: isLobbyDataError,
  } = loadLobby(authContext);
  const lobbyConfig = useLobbyConfig();
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  const filters = [
    { label: t("lobby.running"), value: "running" },
    { label: t("lobby.myPromotions"), value: "myPromotions" },
    { label: t("lobby.completed"), value: "completed" },
  ];

  const [activeFilter, setActiveFilter] = useState(filters[0].value);

  useEffect(() => {
    const runningData = lobbyData?.filter(
      (data: any) => PromotionStatusEnum.Active === data.status
    );
    if (runningData?.length === 0) {
      setActiveFilter(filters[2].value);
    }
  }, [lobbyData]);

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
    trackLobbyTabChange(filter);
  };

  const {
    data: lobbyUserData,
    isLoading: isLobbyUserDataLoading,
    isError: isLobbyUserDataError,
  } = loadLobbyWithUser(authContext);

  if (isLobbyDataError || isLobbyUserDataError || lobbyData?.error) {
    return <PageNotFound />;
  }
  if (isLobbyDataLoading || isLobbyUserDataLoading) {
    return <LoadingPage />;
  }

  const filteredData = lobbyData?.filter((data: any) => {
    switch (activeFilter) {
      case "myPromotions":
        return lobbyUserData?.hasOwnProperty(data.promoRef);
      case "running":
        return PromotionStatusEnum.Active === data.status;
      case "completed":
        return PromotionStatusEnum.Completed === data.status;
      default:
        return PromotionStatusEnum.Completed === data.status;
    }
  });

  const banners = lobbyConfig.banner ? JSON.parse(lobbyConfig.banner)[i18n.language] : [];

  return (
    <div className={styles.lobby}>
      <BannerSlider banners={banners} />
      <div className="container">
        <div className={styles.promotions}>
          <LobbyFilter
            filters={filters}
            activeFilter={activeFilter}
            onSelectFilter={handleFilterChange}
          />
          <div className={styles.cardContainer}>
            {filteredData.length > 0 ? (
              filteredData.map((data: any) => (
                <LobbyCard
                  key={data.promoRef}
                  promoData={data}
                  userJoined={lobbyUserData?.[data?.promoRef]}
                />
              ))
            ) : (
              <EmptyState
                backgroundColor="var(--color-lobby-bg-tertiary)"
                img={notFound}
                title={t("lobby.empty.title")}
                text={t("lobby.empty.desc")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LobbyWidget;
