import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Timer.module.scss";

const calculateTimeRemaining = (endDate: number) => {
  const now = new Date().getTime();
  const difference = endDate - now;

  if (difference <= 0) {
    // Timer has reached its target date
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

type Props = {
  titleBox?: string | React.ReactNode;
  endDate: string | null;
  className?: string;
};

const Timer = ({ titleBox, endDate, className = "" }: Props) => {
  const endDateObject = new Date(endDate as any).getTime();

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(endDateObject));
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(endDateObject));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={`${styles.timer} ${className}`}>
      {titleBox && (
        <div className={styles.timer__box}>
          <div className={styles.timer__value}>{titleBox}</div>
        </div>
      )}
      <div className={styles.timer__box}>
        <p className={styles.timer__value}>{timeRemaining.days}</p>
        <p className={styles.timer__text}>{t("timer.days")}</p>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.timer__box}>
        <p className={styles.timer__value}>{timeRemaining.hours}</p>
        <p className={styles.timer__text}>{t("timer.hours")}</p>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.timer__box}>
        <p className={styles.timer__value}>{timeRemaining.minutes}</p>
        <p className={styles.timer__text}>{t("timer.minutes")}</p>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.timer__box}>
        <p className={styles.timer__value}>{timeRemaining.seconds}</p>
        <p className={styles.timer__text}>{t("timer.seconds")}</p>
      </div>
    </div>
  );
};

export default Timer;
