import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { useOptIn, useOptInStatus } from "../../casino.api";
import { CasinoPromoConfig } from "../../casino.types";
import { setToast } from "@/components/toast/Toast";

import LoadingPage from "@/components/loadingPage/LoadingPage";
import PromotionRules from "../promotionRules/PromotionRules";
import Popup from "@/components/popup/Popup";

import styles from "./UserAuthPrompt.module.scss";

import userIcon from "@/assets/img/user.svg";
import rulesIcon from "@/assets/img/rules.svg";
import { PostUserSignInMsg } from "@/services/PostMessageService";

type Props = {
  hasOptin: boolean;
  status: CasinoPromoConfig["status"];
};
const UserAuthPrompt = ({ hasOptin, status }: Props) => {
  const { t } = useTranslation();
  const authContext = useContext<AuthContextProps>(AuthContext);
  const isLoggedIn = useMemo(() => authContext.isLoggedIn, [authContext.isLoggedIn]);
  const { data: optInStatus, isLoading: isOptInStatusLoading } = useOptInStatus(
    authContext,
    hasOptin && isLoggedIn
  );
  const isOptedIn = optInStatus === true || (optInStatus && !optInStatus?.error) || false;
  const { mutate: optIn, isPending: isOptInLoading } = useOptIn(authContext);

  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const onOptIn = () => {
    optIn(undefined, {
      onSuccess: () => {
        setToast("success", t("casinoWidget.successOptIn"));
      },
      onError: () => {
        setToast("error", t("casinoWidget.errorOptIn"));
      },
    });
  };

  const onLogin = () => {
    window.parent.postMessage(PostUserSignInMsg(), "*");
  };

  const onShowRules = () => {
    setIsRulesOpen(true);
  };

  const shouldShowRules = status === "COMPLETED" || status === "DRAFT";

  if (isOptInStatusLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {shouldShowRules || (isLoggedIn && !hasOptin) || (isLoggedIn && hasOptin && isOptedIn) ? (
        <button className={styles.rules} onClick={onShowRules}>
          <img src={rulesIcon} alt="rules" />
          {t("userAuthPrompt.rules")}
        </button>
      ) : (
        <div className={styles.userAuthPrompt}>
          <div className={styles.userInfo}>
            <div className={styles.avatar}>
              <img src={userIcon} alt="user-icon" />
            </div>
            <div className={styles.separator}></div>
            <p className={styles.message}>
              {!isLoggedIn ? t("userAuthPrompt.logInText") : t("userAuthPrompt.optInText")}
            </p>
          </div>

          <div className={styles.actionButtons}>
            {!isLoggedIn ? (
              <button className={styles.logIn} onClick={onLogin}>
                {t("userAuthPrompt.logIn")}
              </button>
            ) : (
              hasOptin &&
              !isOptedIn && (
                <button className={styles.optIn} onClick={onOptIn}>
                  {t("userAuthPrompt.optIn")}
                </button>
              )
            )}
            <div className={styles.separator}></div>
            <button className={styles.rules} onClick={onShowRules}>
              <img src={rulesIcon} alt="rules" />
            </button>
          </div>
        </div>
      )}
      <Popup
        title={
          <div className={styles.rulesHeader}>
            <img src={rulesIcon} alt="rules" />
            {t("userAuthPrompt.rules")}
          </div>
        }
        headerClassName={styles.modalHeader}
        isOpen={isRulesOpen}
        onClose={() => setIsRulesOpen(false)}
      >
        <PromotionRules />
      </Popup>
    </>
  );
};

export default UserAuthPrompt;
