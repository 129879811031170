import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider.tsx";
import { formatMatchDate } from "../utils/formatMatchDate";
import { Points } from "@/assets/svg";
import styles from "./WidgetCard.module.scss";
import ExactScoreTooltip from "./ExactScoreTooltip";
import { ConfigContext } from "@/providers/ConfigProvider";

interface WidgetCardHeaderProps {
  headerInfo?: {
    matchDate?: string;
    userPick?: any;
    question?: string;
    point?: number;
    isHistory: boolean;
    isMatchFinished: boolean;
    exactScoreDisplay?: number;
    exactScorePoint?: number;
  };
}
enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

const WidgetCardHeader: React.FC<WidgetCardHeaderProps> = ({ headerInfo }) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const promoConfig = useContext<any>(ConfigContext);
  const { t } = useTranslation();

  const showPoints = useMemo(
    () =>
      authContext.isLoggedIn &&
      headerInfo?.userPick &&
      headerInfo?.isHistory &&
      headerInfo?.isMatchFinished &&
      headerInfo?.userPick?.correct !== null &&
      Object.keys(headerInfo?.userPick).length > 0 &&
      promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS,
    [authContext.isLoggedIn, headerInfo?.userPick]
  );

  const showInProgress = useMemo(
    () =>
      headerInfo?.isHistory &&
      (!headerInfo?.isMatchFinished || headerInfo?.userPick?.correct === null),
    [headerInfo?.isHistory, headerInfo?.isMatchFinished]
  );

  const renderExactScorePoints = (display: any) => (
    <ExactScoreTooltip
      point={headerInfo?.point || 0}
      pointsDisplay={display}
      exactScoreDisplay={headerInfo?.exactScoreDisplay}
    />
  );

  return (
    <div className={styles.widget__header}>
      <p>{headerInfo?.question}</p>
      {showPoints ? (
        <p className={`${headerInfo?.userPick.correct ? styles.correct : styles.incorrect}`}>
          {headerInfo?.exactScoreDisplay && !isNaN(headerInfo?.exactScoreDisplay)
            ? renderExactScorePoints(
                t("historyWidget.weekStatus.points", {
                  pointsAmount: headerInfo?.exactScorePoint,
                })
              )
            : t("historyWidget.weekStatus.points", {
                pointsAmount: headerInfo?.point,
              })}
        </p>
      ) : showInProgress ? (
        <p className={styles.inProgress}>
          {headerInfo?.point}&nbsp;
          {t("historyWidget.weekStatus.pointsShort")}
        </p>
      ) : headerInfo?.isHistory ? (
        <p>{headerInfo?.matchDate ? formatMatchDate(headerInfo?.matchDate) : "Unknown"}</p>
      ) : (
        <>
          {promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS &&
          headerInfo?.exactScoreDisplay &&
          !isNaN(headerInfo?.exactScoreDisplay) ? (
            <div className={styles.widgetPoints}>
              <Points className={styles.pointsIcon} />
              {renderExactScorePoints(
                <>
                  {headerInfo?.point}&nbsp;{t("historyWidget.weekStatus.pointsShort")}
                </>
              )}
            </div>
          ) : (
            promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS && (
              <div className={styles.widgetPoints}>
                <Points className={styles.pointsIcon} />
                <p>
                  {headerInfo?.point}&nbsp;{t("historyWidget.weekStatus.pointsShort")}
                </p>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default WidgetCardHeader;
