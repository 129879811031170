const Trophy = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FDD106"
      {...props}
    >
      <path
        d="M7.99992 11.8333C5.60668 11.8333 3.66659 9.89323 3.66659 7.5V3.53704C3.66659 3.19159 3.66659 3.01887 3.72905 2.88492C3.79529 2.74287 3.90946 2.6287 4.05151 2.56246C4.18546 2.5 4.35818 2.5 4.70362 2.5H11.2962C11.6417 2.5 11.8144 2.5 11.9483 2.56246C12.0904 2.6287 12.2046 2.74287 12.2708 2.88492C12.3333 3.01887 12.3333 3.19159 12.3333 3.53704V7.5C12.3333 9.89323 10.3932 11.8333 7.99992 11.8333ZM7.99992 11.8333V14.5M11.3333 14.5H4.66659M14.6666 3.83333V7.16667M1.33325 3.83333V7.16667"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trophy;
