import { useEffect } from "react";
import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import useViewportSize from "@/hooks/useViewportSize";

import styles from "./SlidingProgressBar.module.scss";

import progressArrow from "@/assets/img/arrow-icon-right.svg";
import info from "@/assets/img/info.svg";
import refresh from "@/assets/img/refresh-square.svg";
import Tooltip from "@/components/tooltip/Tooltip";

const setProgressValue = (
  value: number,
  lastCheckpoint: number,
  sectionWidth: number,
  setValueWidth: Function
) => {
  const commonInterval = 30000;
  let percentPerPixel = sectionWidth / 100;
  let amountPercentage = (value / commonInterval) * 100;
  let additionalWidth = 0;
  let widthcoefficient = 1;
  // if (swiper) {
  //   if (value > commonInterval) {
  //     let slideToIndex = Math.floor(value / commonInterval);
  //     swiper.slideTo(slideToIndex);
  //   }
  // }
  if (value <= 1000) {
    widthcoefficient = commonInterval / 1000;
  } else if (1000 < value && value <= 5000) {
    additionalWidth = sectionWidth;
    widthcoefficient = commonInterval / 5000;
  } else if (5000 < value && value <= 15000) {
    additionalWidth = sectionWidth * 2;
    widthcoefficient = commonInterval / 15000;
  } else if (15000 < value && value <= commonInterval) {
    additionalWidth = sectionWidth * 3;
    widthcoefficient = 1;
  } else if (value > lastCheckpoint) {
    additionalWidth = sectionWidth * 4;
    value = lastCheckpoint;
    amountPercentage = (value / commonInterval) * 100;
  } else {
    additionalWidth = sectionWidth * 3;
  }
  setValueWidth(additionalWidth + percentPerPixel * amountPercentage * widthcoefficient);
};

interface Props {
  progressValue?: number;
  checkpoints?: number[];
  checkpointsPerView?: number;
  checkpointsPerViewMobile?: number;
  currency?: string;
  [key: string]: any; // for handling ...rest props
}

const SlidingProgressBar = ({
  progressValue = 0,
  checkpoints = [0, 1, 2],
  checkpointsPerView = 6,
  checkpointsPerViewMobile = 6,
  currency = "",
  ...rest
}: Props) => {
  const swiperRef = useRef<any>(null);
  const { width } = useViewportSize();
  const [lineWidth, setLineWidth] = useState<number>(0);
  const [valueWidth, setValueWidth] = useState<number>(0);
  const currcheckpointsPerView = width <= 940 ? checkpointsPerViewMobile : checkpointsPerView;

  useEffect(() => {
    if (swiperRef.current) {
      const checkpointWidth = swiperRef.current.offsetWidth / currcheckpointsPerView || 0;

      setProgressValue(
        progressValue,
        checkpoints[checkpoints.length - 1],
        checkpointWidth,
        setValueWidth
      );
      swiperRef.current.swiper.slideTo(valueWidth / checkpointWidth - 2);

      setTimeout(() => {
        setLineWidth(checkpointWidth * checkpoints.length);
      }, 100);
    }
  }, [progressValue, valueWidth, swiperRef.current]);

  return (
    <div className={styles.progress}>
      <div className={styles.progress__header}>
        <div className={styles.texts}>
          <h4>Wheel Progress</h4>
        </div>
        <div className={styles.buttons}>
          <button className={styles.refresh}>
            <img src={refresh} alt="refresh" />
          </button>
          <button className={styles.info}>
            <img src={info} alt="info" />
          </button>
          <Tooltip targetClassName={styles.info}>
            <p className={styles.tooltip}>
              Complete the tasks to earn tries on the wheel of fortune amd win awesome prizes!
            </p>
          </Tooltip>
        </div>
      </div>
      <div className={styles.progress__break}></div>
      <div className={styles.progress__container}>
        <div className={`${styles.progress__arrow} ${styles.progress__arrow_prev}`}>
          <img src={progressArrow} alt="progress left" />
        </div>
        <Swiper
          modules={[Navigation]}
          className={styles.progress__slider}
          spaceBetween={0}
          slidesPerView={currcheckpointsPerView}
          ref={swiperRef}
          navigation={{
            nextEl: `.${styles.progress__arrow_next}`,
            prevEl: `.${styles.progress__arrow_prev}`,
          }}
        >
          {checkpoints.map((element, index) => {
            return (
              <SwiperSlide key={index} className={styles.progress__slide}>
                <span className={`${styles.progress__txt} ${styles.progress__txt_top}`}>
                  {element + currency}
                </span>
                <span className={`${styles.progress__txt} ${styles.progress__txt_bottom}`}>
                  {index !== 0 && "+1"}
                </span>
              </SwiperSlide>
            );
          })}
          <SwiperSlide className={styles.progress__slide}>
            <span className={`${styles.progress__txt} ${styles.progress__txt_top}`}>ထ</span>
            <span className={`${styles.progress__txt} ${styles.progress__txt_bottom}`}>+1ထ</span>
          </SwiperSlide>
          <SwiperSlide className={styles.progress__line} style={{ width: lineWidth + "px" }}>
            <div className={styles.progress__line_fill} style={{ width: valueWidth + "px" }}>
              {/* <div className={styles.progress__line_value}>{progressValue + currency}</div> */}
            </div>
          </SwiperSlide>
        </Swiper>
        <div className={`${styles.progress__arrow} ${styles.progress__arrow_next}`}>
          <img src={progressArrow} alt="progress right" />
        </div>
      </div>
    </div>
  );
};
export default SlidingProgressBar;
