import ConfigService from "./ConfigService";

const ON_USER_DEFAULT_PICKS = "ON_USER_DEFAULT_PICKS";
const ON_USER_PICK = "ON_USER_PICK";
const ON_USER_SIGN_IN = "ON_USER_SIGN_IN";
const ACK_USER_SIGN_OUT = "ACK_USER_SIGN_OUT";

const PROMOTION_ROUTE = "/f2p/game/pickem";

function PostUserSignInMsg() {
  return {
    event: ON_USER_SIGN_IN,
    data: {
      path: PROMOTION_ROUTE,
      promoId: ConfigService.getPromoId(),
    },
  };
}

function AckUserSignOut() {
  return {
    event: ACK_USER_SIGN_OUT,
  };
}

function PostUserPickMsg(weekId: string, propId: number, option: any) {
  return {
    event: ON_USER_PICK,
    payload: {
      weekId: weekId,
      propId: propId,
      value: option.value,
    },
  };
}

export {
  ON_USER_DEFAULT_PICKS,
  ON_USER_PICK,
  ON_USER_SIGN_IN,
  ACK_USER_SIGN_OUT,
  PostUserPickMsg,
  PostUserSignInMsg,
};
