import { useTranslation } from "react-i18next";

import FAQ from "./components/faq/FAQ.tsx";

import styles from "./FAQWidget.module.scss";
import EmptyState from "@/components/emptyState/EmptyState.tsx";

import bell from "@/assets/img/newEmpty.svg";
import SafeHtmlRenderer from "@/components/safeHtmlRenderer/SafeHtmlRenderer.tsx";

const FaqWidget = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.faq}>
      <div className={styles.faq__title}>
        <h2>{t("faqWidget.title")}</h2>
      </div>
      <div className={styles.faq__contents}>
        {t("faq") && t("faq").length > 0 ? (
          <SafeHtmlRenderer htmlContent={t("faq")} />
        ) : (
          <EmptyState img={bell} title={t("emptyState.oops")} text={t("emptyState.FAQText")} />
        )}
      </div>
    </div>
  );
};

export default FaqWidget;
