import React, { useEffect, useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import styles from "./Tooltip.module.scss";
import _ from "lodash";

interface TooltipProps {
  targetClassName: string;
  children: React.ReactNode;
  maxHeight?: number;
  arrowPosition?: "left" | "center" | "right";
}

const Tooltip: React.FC<TooltipProps> = ({
  targetClassName,
  children,
  maxHeight = 300,
  arrowPosition = "right",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [placement, setPlacement] = useState<"top" | "bottom">("top");
  const tooltipRef = useRef<HTMLDivElement>(null);
  const isTooltipHovered = useRef(false);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const calculatePosition = useCallback(() => {
    if (!target || !tooltipRef.current) return;

    const targetRect = target.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const arrowWidth = 10; // Total width of the arrow
    const arrowHalfWidth = arrowWidth / 2;

    let top = window.scrollY + targetRect.top - tooltipRect.height - 10;
    let left = window.scrollX + targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;

    // Adjust left position based on arrowPosition
    if (arrowPosition === "left") {
      left = window.scrollX + targetRect.left - arrowHalfWidth;
    } else if (arrowPosition === "right") {
      left = window.scrollX + targetRect.right - tooltipRect.width + arrowHalfWidth;
    }

    // Check if tooltip goes beyond the right edge of the viewport
    if (left + tooltipRect.width > window.innerWidth) {
      left = window.innerWidth - tooltipRect.width - 10;
    }

    // Check if tooltip goes beyond the left edge of the viewport
    if (left < 0) {
      left = 10;
    }

    // Check if tooltip goes beyond the top of the viewport
    if (top < window.scrollY) {
      top = window.scrollY + targetRect.bottom + 10;
      setPlacement("bottom");
    } else {
      setPlacement("top");
    }

    setPosition({ top, left });
  }, [target, arrowPosition]);

  useEffect(() => {
    const targetElement = document.querySelector(`.${targetClassName}`);
    setTarget(targetElement as HTMLElement);

    const handleMouseEnter = _.debounce(() => {
      setIsOpen(true);
    }, 50);

    const handleMouseLeave = _.debounce(() => {
      if (!isTooltipHovered.current) {
        setIsOpen(false);
      }
    }, 50);

    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    if (targetElement) {
      targetElement.addEventListener("mouseenter", handleMouseEnter);
      targetElement.addEventListener("mouseleave", handleMouseLeave);
      targetElement.addEventListener("click", handleClick);

      const childElements = targetElement.querySelectorAll("*");
      childElements.forEach((child) => {
        child.addEventListener("mouseenter", handleMouseEnter);
        child.addEventListener("mouseleave", handleMouseLeave);
      });

      // Set up ResizeObserver
      resizeObserver.current = new ResizeObserver(
        _.debounce(() => {
          if (isOpen) {
            calculatePosition();
          }
        }, 100)
      );
      resizeObserver.current.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener("mouseenter", handleMouseEnter);
        targetElement.removeEventListener("mouseleave", handleMouseLeave);
        targetElement.removeEventListener("click", handleClick);

        const childElements = targetElement.querySelectorAll("*");
        childElements.forEach((child) => {
          child.removeEventListener("mouseenter", handleMouseEnter);
          child.removeEventListener("mouseleave", handleMouseLeave);
        });

        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      }
    };
  }, [targetClassName, isOpen, calculatePosition]);

  useEffect(() => {
    if (isOpen && target) {
      if (!rootElement) {
        const rootDiv = document.createElement("div");
        document.body.appendChild(rootDiv);
        setRootElement(rootDiv);
      }

      const recalculatePosition = () => {
        calculatePosition();
        requestAnimationFrame(calculatePosition);
      };

      // Initial calculations
      recalculatePosition();
      const immediateTimeout = setTimeout(recalculatePosition, 0);
      const shortDelayTimeout = setTimeout(recalculatePosition, 50);
      const longerDelayTimeout = setTimeout(recalculatePosition, 200);

      const handleResize = _.debounce(recalculatePosition, 100);
      const handleScroll = _.debounce(recalculatePosition, 50);
      const handleOrientationChange = _.debounce(recalculatePosition, 100);

      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll, true);
      window.addEventListener("orientationchange", handleOrientationChange);

      // Periodic check for layout changes
      const intervalId = setInterval(recalculatePosition, 500);

      return () => {
        clearTimeout(immediateTimeout);
        clearTimeout(shortDelayTimeout);
        clearTimeout(longerDelayTimeout);
        clearInterval(intervalId);
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleScroll, true);
        window.removeEventListener("orientationchange", handleOrientationChange);
      };
    } else {
      if (rootElement && document.body.contains(rootElement)) {
        document.body.removeChild(rootElement);
      }
      setRootElement(null);
    }
  }, [isOpen, target, calculatePosition]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  if (!rootElement || !isOpen) {
    return null;
  }

  const getArrowStyle = () => {
    const arrowOffset = 10;
    switch (arrowPosition) {
      case "left":
        return { left: `${arrowOffset}px` };
      case "right":
        return { right: `${arrowOffset}px` };
      default:
        return { left: "50%", transform: "translateX(-50%)" };
    }
  };

  return createPortal(
    <div
      ref={tooltipRef}
      className={`${styles.tooltip} ${styles[placement]} ${styles[`arrow-${arrowPosition}`]}`}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        opacity: position.top === 0 && position.left === 0 ? 0 : 1,
      }}
      onMouseEnter={() => {
        isTooltipHovered.current = true;
      }}
      onMouseLeave={() => {
        isTooltipHovered.current = false;
        setIsOpen(false);
      }}
    >
      <div className={styles.content} style={{ maxHeight: `${maxHeight}px` }}>
        {children}
      </div>
      <div className={styles.arrow} style={getArrowStyle()} />
    </div>,
    rootElement
  );
};

export default Tooltip;
