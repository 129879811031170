import React, { useMemo, useState } from "react";
import styles from "./MapPoint.module.scss";
import MapPointDecor from "../../assets/img/mapPointDecor.svg";
import playBtn from "../../assets/img/playBtn.svg";
import locker from "../../assets/img/locker.svg";
import present from "../../assets/img/present.svg";
import rewardBox from "../../assets/img/rewardBox.svg";

type MapPointProps = {
  className?: string;
  style?: React.CSSProperties;
  step: number;
  status?: string;
  onClick?: () => void;
  userProgress: any;
  quest: any;
};

const MapPoint = ({
  className,
  style,
  step,
  status,
  onClick,
  userProgress,
  quest,
}: MapPointProps) => {
  const [isShaking, setIsShaking] = useState(false);
  const [isPulsing, setIsPulsing] = useState(false);

  const [statusClassName, statusStep] = useMemo(() => {
    switch (status) {
      case "COMPLETED":
        return [styles.claimed, <p>{step}</p>];
      case "CURRENT":
        return [
          styles.active,
          <div className={styles.playBtnWrapper}>
            <p className={styles.playBtnCount}>{step}</p>
            {[...Array(6)].map((_, index) => (
              <div
                key={index}
                className={`${styles.playBtnPulse} ${styles[`pulse${index + 1}`]}`}
              ></div>
            ))}
          </div>,
        ];
      case "UPCOMING":
        return [
          styles.locked,
          <p className={`${styles.lockerIcon} ${isPulsing ? styles.pulse : ""}`}>{step}</p>,
        ];
      default:
        return ["", null];
    }
  }, [status, step, isPulsing]);

  const hasUnclaimedAward = useMemo(() => {
    const stepProgress = userProgress?.quests?.find((quest: any) => quest.stepId === step);
    if (stepProgress?.award?.claimed === false) {
      return true;
    }
    return stepProgress?.tasks?.some((task: any) => task?.award?.claimed === false);
  }, [userProgress, quest, step]);

  const handleClick = () => {
    if (status === "UPCOMING") {
      setIsShaking(true);
      setIsPulsing(true);
      setTimeout(() => {
        setIsShaking(false);
        setIsPulsing(false);
      }, 600);
    }
    onClick?.();
  };

  return (
    <div
      className={`${styles.mapPoint} ${className || ""} ${statusClassName} ${isShaking ? styles.shake : ""}`}
      onClick={handleClick}
      style={style}
    >
      <div className={styles.mainWrap}>
        {statusStep}
        <img src={MapPointDecor} className={styles.decor} alt="" />
      </div>
      <div className={styles.frameBorder} />
      {status !== "UPCOMING" && hasUnclaimedAward && (
        <div className={styles.reward}>
          <img src={rewardBox} alt="" />
          <img src={present} className={styles.present} alt="" />
        </div>
      )}
    </div>
  );
};

export default MapPoint;
