import { useState } from "react";
import { useTranslation } from "react-i18next";

import EmptyState from "@/components/emptyState/EmptyState.tsx";
import SafeHtmlRenderer from "@/components/safeHtmlRenderer/SafeHtmlRenderer.tsx";

import styles from "./PromotionRules.module.scss";

import bell from "@/assets/img/newEmpty.svg";
import { Arrow } from "@/assets/svg";

type Props = {
  className?: string;
  isCollapsible?: boolean;
};

const PromotionRules = ({ className = "", isCollapsible = false }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!isCollapsible);

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.rules} ${className}`}>
      {isCollapsible && (
        <div className={styles.title} onClick={toggleVisibility}>
          {t("casino.rules")}
          <Arrow />
        </div>
      )}
      <div className={`${styles.container} ${isOpen ? styles.visible : ""}`}>
        <div className={styles.body}>
          {t("faq") && t("faq").length > 0 ? (
            <SafeHtmlRenderer htmlContent={t("faq")} />
          ) : (
            <EmptyState img={bell} title={t("emptyState.oops")} text={t("emptyState.FAQText")} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionRules;
