import { useContext, useRef, useState, useMemo, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

import { Pickem, PromotionStatusEnum, WeekPool, WeekStatus } from "../pickem.types.ts";
import useViewportSize from "../../../hooks/useViewportSize.ts";
import { loadUserPicks } from "../pickem.api.ts";

import WidgetSelector from "./components/widgetSelector/WidgetSelector.tsx";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider.tsx";
import LoadingPage from "@/components/loadingPage/LoadingPage.tsx";
import MainOverview from "@/components/mainOverview/MainOverview.tsx";
import EmptyState from "@/components/emptyState/EmptyState.tsx";
import LogInModal from "@/components/logInModal/LogInModal.tsx";
import Auth from "@/components/auth/Auth.tsx";

import styles from "./PickemWidget.module.scss";
import "swiper/scss";
import "swiper/scss/navigation";

import arrowRight from "../../../assets/img/arrow-icon-right.svg";
import emptyStateImg from "@/assets/img/newEmpty.svg";
import Completed from "../../../assets/img/completed.svg";
import { ON_USER_DEFAULT_PICKS } from "@/services/PostMessageService.ts";

type Props = {
  pickemData: Pickem;
};

const PickemWidget = ({ pickemData }: Props) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const controlledSwiper = useRef<any>(null);
  const { width } = useViewportSize();
  const { t } = useTranslation();
  const authContext = useContext<AuthContextProps>(AuthContext);

  const toggleLogInModal = () => {
    setIsLoginModalOpen((prev) => !prev);
  };

  let { data: userPicks, isLoading, isError } = loadUserPicks(authContext);

  const filteredWeekPools = useMemo(() => {
    if (!pickemData) {
      return [];
    }
    return pickemData.weekPools.filter(
      (obj: { status: string }) =>
        obj.status !== WeekStatus.completed && obj.status !== WeekStatus.live
    );
  }, [pickemData]);

  if (isError || (userPicks as any)?.error) {
    userPicks = {};
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.pickem}>
      {width <= 940 && PromotionStatusEnum.Active === pickemData.status && (
        <MainOverview
          promoData={{ startDate: pickemData.startDate, endDate: pickemData.endDate }}
        />
      )}

      {!authContext.isLoggedIn && <Auth />}
      {PromotionStatusEnum.Completed === pickemData.status ? (
        <EmptyState
          img={Completed}
          title={t("emptyState.complete")}
          text={t("emptyState.completeText")}
          className={styles.completedPromotion}
        />
      ) : filteredWeekPools.length > 0 ? (
        <>
          <div className={styles.pickem__controls}>
            <div className={`${styles.arrow} ${styles.arrow__prev} pickem-prev`}>
              <img src={arrowRight} alt="" />
            </div>
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              className={styles.pickem__slider}
              pagination={true}
              navigation={{
                nextEl: ".pickem-next",
                prevEl: ".pickem-prev",
                disabledClass: "pickem-disabled",
              }}
              onSlideChange={(swiper) => {
                controlledSwiper.current?.swiper.slideTo(swiper.activeIndex);
              }}
            >
              {filteredWeekPools.map((e: WeekPool) => {
                return (
                  <SwiperSlide className={styles.pickem__control} key={e.uuid}>
                    <h4>{e.week}</h4>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className={`${styles.arrow} ${styles.arrow__next} pickem-next`}>
              <img src={arrowRight} alt="" />
            </div>
          </div>
          <div className={styles.pickem__content}>
            <Swiper
              ref={controlledSwiper}
              slidesPerView={1}
              pagination={true}
              allowTouchMove={false}
            >
              {filteredWeekPools.map((e: WeekPool, index: number) => {
                return (
                  <SwiperSlide key={index} className={styles.pickem__wrapper}>
                    <WidgetSelector
                      weekPool={e}
                      userPicks={userPicks}
                      toggleLogInModal={toggleLogInModal}
                      promoRef={pickemData.promoRef}
                      isBooster={pickemData.booster && e.status === WeekStatus.unlocked}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </>
      ) : (
        <EmptyState img={emptyStateImg} title={""} text={t("emptyState.upcomingMatches")} />
      )}
      {isLoginModalOpen && <LogInModal toggleLogInModal={toggleLogInModal} />}
    </div>
  );
};

export default PickemWidget;
