import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import PageNotFound from "../components/pageNotFound/PageNotFound";
import LoadingPage from "../components/loadingPage/LoadingPage";

import i18n from "../i18n";
import httpClient from "@/api/httpClient.ts";
import { setGoogleTagManager, useGTagConfig } from "./utils";

const LobbyConfigContext = createContext<any>(null);

const setColorVariables = (paramKey: string, paramValue: string) => {
  document.documentElement.style.setProperty(`${paramKey}`, `${paramValue}`);
};

const setLobbyColors = (layout: {
  primaryColor: string;
  textColor: string;
  backgroundColor: string;
  backgroundSecondaryColor: string;
}) => {
  setColorVariables("--color-lobby-primary", layout.primaryColor);
  setColorVariables("--color-lobby-primary-hover", `${layout.primaryColor}cc`);
  setColorVariables("--color-lobby-text", layout.textColor);
  setColorVariables("--color-lobby-text-secondary", `${layout.textColor}99`);
  setColorVariables("--color-lobby-bg-primary", layout.backgroundColor);
  setColorVariables("--color-lobby-bg-secondary", layout.backgroundSecondaryColor);
};

const setLobbyBackground = (bgString: string | undefined) => {
  if (!bgString || bgString.length === 0) {
    return;
  }
  const bgObj = JSON.parse(bgString);
  let desktopUri = bgObj?.["desktop"]?.[0]?.uri;
  let mobileUri = bgObj?.["mobile"]?.[0]?.uri;

  if (!desktopUri && !mobileUri) {
    const enUri = bgObj?.["en"]?.[0]?.uri;
    if (enUri) {
      desktopUri = enUri;
      mobileUri = enUri;
    }
  }
  if (desktopUri) {
    setColorVariables("--background-image-web-lobby", `url(${desktopUri})`);
  }
  if (mobileUri) {
    setColorVariables("--background-image-mobile-lobby", `url(${mobileUri})`);
  }
};

const getColorVariable = (varName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};
const updateFontVariables = (fontWeight: string, fontName: string) => {
  const currentFonts = getColorVariable(`--font-${fontWeight}`);
  const newFontFace = `${fontName}, ${currentFonts}`;
  document.documentElement.style.setProperty(`--font-${fontWeight}`, newFontFace);
};
function createFontFaceStyle(fontPath: string, fontWeight: string) {
  let fontName = (fontPath as any)?.split("/")?.pop()?.split(".")?.[0];
  const fontFaceStyleElement = document.createElement("style");
  fontFaceStyleElement.innerHTML = `
    @font-face {
        font-family: ${fontName};
        font-weight: ${fontWeight?.toLowerCase()};
        src: url('${import.meta.env.VITE_WIDGET_CDN_BASE_URL}/assets/fonts/${fontPath}') format('truetype');
      }
    `;
  document.head.appendChild(fontFaceStyleElement);
  updateFontVariables(fontWeight?.toLowerCase(), fontName);
}

export const LobbyConfigProvider = ({
  dataRoute,
  children,
}: {
  children: React.ReactNode;
  dataRoute: string;
}) => {
  const {
    data: lobbyConfig,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [`lobbyConfig`],
    queryFn: () => httpClient.get(dataRoute),
    select: (lobbyConfig) => lobbyConfig.data,
  });

  const { data: gtagConfig } = useGTagConfig(lobbyConfig?.orgUuid);

  if (gtagConfig && gtagConfig?.tag) {
    setGoogleTagManager(gtagConfig.tag);
  }

  if (isError) {
    return <PageNotFound />;
  }
  if (isLoading) {
    return <LoadingPage />;
  }

  if (!lobbyConfig) {
    return <PageNotFound />;
  }

  const layout = (() => {
    try {
      return JSON.parse(lobbyConfig.layout);
    } catch (error) {
      return null;
    }
  })();

  if (!layout) {
    return <PageNotFound />;
  }

  const loadFonts = async () => {
    if (!lobbyConfig.fonts) return;

    try {
      const fonts = JSON.parse(lobbyConfig.fonts);
      if (!Array.isArray(fonts) || fonts.length === 0) return;

      const lang = i18n.language;
      const selectedLang =
        fonts.find((font: any) => font.lang === lang) ||
        fonts.find((font: any) => font.default) ||
        fonts[0];

      if (selectedLang && selectedLang.fontFamily) {
        Object.entries(selectedLang.fontFamily).forEach(([fontWeight, fontPath]) => {
          if (typeof fontPath === "string") {
            createFontFaceStyle(fontPath, fontWeight);
          }
        });
      }
    } catch (error) {
      console.error("Failed to load fonts:", error);
    }
  };

  loadFonts();
  setLobbyColors(layout);
  setLobbyBackground(lobbyConfig.background);

  return <LobbyConfigContext.Provider value={lobbyConfig}>{children}</LobbyConfigContext.Provider>;
};

export const useLobbyConfig = () => {
  return useContext(LobbyConfigContext);
};

export { LobbyConfigContext };
