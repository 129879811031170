import { useContext, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TieBreaker from "../../../pickem/components/tieBreaker/TieBreaker.tsx";
import EmptyState from "@/components/emptyState/EmptyState.tsx";
import { ConfigContext } from "@/providers/ConfigProvider.tsx";

import { PickemProp, QuestionType, WeekStatus } from "../../../pickem.types.ts";

import styles from "./HistoryListItem.module.scss";

import FT1x2Match from "@/widgets/pickemWidget/pickem/components/FT1x2Match/FT1x2Match.tsx";
import ExactMatch from "@/widgets/pickemWidget/pickem/components/exactMatch/ExactMatch.tsx";
import { MatchPredictionTemplate } from "@/widgets/pickemWidget/pickem/components/MatchStatuses.ts";
import DualChoice from "@/widgets/pickemWidget/pickem/components/dualChoice/DualChoice.tsx";

import ArrowRight from "./assets/img/chevron-right.svg";
import logo from "./assets/img/ball.png";
import emptyStateImg from "@/assets/img/newEmpty.svg";
import Skipped from "./assets/img/skipped.svg";
import Pending from "./assets/img/pending.svg";
import Completed from "./assets/img/completed.svg";
import GoalScorers from "@/widgets/pickemWidget/pickem/components/goalScorers/GoalScorers.tsx";

import { calculateExactScorePoint } from "@/components/utils/exactScoreCalcs";
import PlayerProps from "@/widgets/pickemWidget/pickem/components/playerProps/PlayerProps.tsx";
import BoosterBanner from "@/components/boosterBanner/BoosterBanner.tsx";

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

type Props = {
  roundName: string | undefined;
  userHistoryRoundData?: any;
  historyWeekStatus: WeekStatus;
  matchInfo?: any;
  index: number;
  isBooster?: boolean;
};

const safeJsonParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

const HistoryListItem = ({
  roundName,
  matchInfo,
  userHistoryRoundData,
  historyWeekStatus,
  index,
  isBooster,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setisOpen] = useState(index === 0);
  const promoConfig = useContext<any>(ConfigContext);

  const { sponsor } = useContext<any>(ConfigContext);
  const parsedSponsor = safeJsonParse(sponsor);

  const sponsorName = parsedSponsor?.name ?? "";
  const sponsorImageUri = parsedSponsor?.image?.[0]?.uri ?? "";

  const roundHistoryData = matchInfo?.props?.sort((a: PickemProp, b: PickemProp) => {
    const dateA = a.matchTime ? new Date(a.matchTime).getTime() : Infinity;
    const dateB = b.matchTime ? new Date(b.matchTime).getTime() : Infinity;
    if (dateA === Infinity && dateB === Infinity) {
      return 0;
    } else if (dateA === Infinity) {
      return 1;
    } else if (dateB === Infinity) {
      return -1;
    } else {
      return dateA - dateB;
    }
  });

  const weekAction = useMemo(() => {
    const isSkiped = !userHistoryRoundData;
    if (historyWeekStatus === WeekStatus.live) {
      return {
        icon: Pending,
        label: t("historyWidget.weekStatus.pending"),
        className: styles.pending,
        isSkiped,
      };
    }

    if (isSkiped) {
      return {
        icon: Skipped,
        label: t("historyWidget.weekStatus.skipped"),
        className: styles.skipped,
        isSkiped,
      };
    } else {
      return {
        icon: Completed,
        label: t("historyWidget.weekStatus.completed"),
        className: styles.completed,
      };
    }
  }, [userHistoryRoundData]);

  const weekPoints = useMemo(() => {
    if (!Array.isArray(userHistoryRoundData) || !Array.isArray(matchInfo?.props)) {
      return 0;
    }
    let pointsSum = 0;
    userHistoryRoundData.forEach((e: any) => {
      if (e.correct) {
        let pointAmount = 0;
        const matchProp = matchInfo.props.find((round: any) => round.propId === e.propId);

        if (
          [MatchPredictionTemplate.EXACT_SCORE, MatchPredictionTemplate.HT_EXACT_SCORE].includes(
            matchProp?.questionTemplate
          )
        ) {
          let extractedValues = matchProp?.eventValue?.split("-");
          pointAmount = calculateExactScorePoint(
            e,
            {
              homeTeamPoints: Number(extractedValues?.[0] ?? 0),
              awayTeamPoints: Number(extractedValues?.[1] ?? 0),
            },
            matchProp.point
          );
        } else {
          pointAmount = matchProp?.point ?? 0;
        }
        if (e.booster) {
          pointAmount *= 2;
        }

        pointsSum += pointAmount;
      }
    });
    return pointsSum;
  }, [userHistoryRoundData, matchInfo?.props]);

  return (
    <div className={styles.historyListItem}>
      <div className={`${styles.historyListItem__inner}  ${isOpen ? styles.open : ""}`}>
        <div className={styles.historyListItem__box}>
          <div className={styles.historyListItem__logo}>
            <img src={logo} />
          </div>
          <div className={styles.historyListItem__dateAndPoint}>
            <p>{roundName}</p>
            {promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS && (
              <span className={styles.weekPoints}>
                ({weekPoints} {t("historyWidget.weekStatus.pointsShort")})
              </span>
            )}
          </div>
        </div>
        <div className={styles.rowActionWrapper}>
          <div className={styles.weekWrapper}>
            <img src={weekAction.icon} />
            <span className={weekAction.className}>{weekAction.label}</span>
          </div>
          <button
            className={styles.historyListItem__openBtn}
            onClick={() => {
              setisOpen((prev) => !prev);
            }}
          >
            <img
              src={ArrowRight}
              className={`${styles.historyListItem__img}  ${isOpen ? styles.open : ""} `}
              alt=""
            />
          </button>
        </div>
      </div>
      <div className={`${styles.historyListItem__content} ${isOpen ? styles.open : ""}`}>
        {(sponsorName || sponsorImageUri) && (
          <BoosterBanner sponsorName={sponsorName} sponsorImageUri={sponsorImageUri} />
        )}
        <div className={styles.historyListItem__content__wrapper}>
          {!Array.isArray(roundHistoryData) || !roundHistoryData.length ? (
            <EmptyState
              className={styles.emptyState}
              img={emptyStateImg}
              title={t("emptyState.oops")}
              text={t("emptyState.historyInDropdown")}
            />
          ) : (
            roundHistoryData.map((e: PickemProp) => {
              const userPick = userHistoryRoundData
                ? userHistoryRoundData.filter((obj: any) => {
                    return obj.propId === e.propId;
                  })[0]
                : {};

              if (e.questionType === QuestionType.team_vs_team) {
                switch (e.questionTemplate) {
                  case MatchPredictionTemplate.EXACT_SCORE:
                  case MatchPredictionTemplate.HT_EXACT_SCORE:
                  case MatchPredictionTemplate.HT_PERFECT_SCORE:
                  case MatchPredictionTemplate.PERFECT_SCORE:
                    return (
                      <ExactMatch
                        type={e.questionTemplate}
                        key={e.propId}
                        matchInfo={e}
                        editable={false}
                        weekStatus={historyWeekStatus}
                        userPick={userPick}
                        isBooster={isBooster}
                      />
                    );
                  case MatchPredictionTemplate.FT_1X2:
                  case MatchPredictionTemplate.DOUBLE_CHANCE:
                  case MatchPredictionTemplate.MORE_CORNERS:
                  case MatchPredictionTemplate.MORE_OFFSIDES:
                    return (
                      <FT1x2Match
                        key={e.propId}
                        type={e.questionTemplate}
                        matchInfo={e}
                        editable={false}
                        weekStatus={historyWeekStatus}
                        userPick={userPick}
                        isBooster={isBooster}
                      />
                    );
                  case MatchPredictionTemplate.YELLOW_CARD_IN_MATCH:
                  // case MatchPredictionTemplate.RED_CARD_IN_MATCH:
                  case MatchPredictionTemplate.BOTH_TEAMS_SCORE:
                  case MatchPredictionTemplate.OVER_FOULS_10_5:
                  case MatchPredictionTemplate.OVER_FOULS_11_5:
                  case MatchPredictionTemplate.OVER_FOULS_12_5:
                  case MatchPredictionTemplate.OVER_FOULS_13_5:
                  case MatchPredictionTemplate.OVER_FOULS_14_5:
                  case MatchPredictionTemplate.OVER_FOULS_15_5:
                  case MatchPredictionTemplate.OVER_FOULS_16_5:
                  case MatchPredictionTemplate.OVER_FOULS_17_5:
                  case MatchPredictionTemplate.OVER_FOULS_18_5:
                  case MatchPredictionTemplate.OVER_FOULS_19_5:
                  case MatchPredictionTemplate.OVER_FOULS_20_5:
                  case MatchPredictionTemplate.OVER_FOULS_21_5:
                  case MatchPredictionTemplate.OVER_FOULS_22_5:
                  case MatchPredictionTemplate.OVER_FOULS_23_5:
                  case MatchPredictionTemplate.OVER_FOULS_24_5:
                  case MatchPredictionTemplate.OVER_FOULS_25_5:
                  case MatchPredictionTemplate.OVER_FOULS_26_5:
                  case MatchPredictionTemplate.OVER_FOULS_27_5:
                  case MatchPredictionTemplate.OVER_FOULS_28_5:
                  case MatchPredictionTemplate.OVER_FOULS_29_5:
                  case MatchPredictionTemplate.OVER_FOULS_30_5:
                  case MatchPredictionTemplate.OVER_GOALS_0_5:
                  case MatchPredictionTemplate.OVER_GOALS_1_5:
                  case MatchPredictionTemplate.OVER_GOALS_2_5:
                  case MatchPredictionTemplate.OVER_GOALS_3_5:
                  case MatchPredictionTemplate.OVER_GOALS_4_5:
                  case MatchPredictionTemplate.OVER_GOALS_5_5:
                  case MatchPredictionTemplate.OVER_GOALS_6_5:
                  case MatchPredictionTemplate.OVER_CORNERS_6_5:
                  case MatchPredictionTemplate.OVER_CORNERS_7_5:
                  case MatchPredictionTemplate.OVER_CORNERS_8_5:
                  case MatchPredictionTemplate.OVER_CORNERS_9_5:
                  case MatchPredictionTemplate.OVER_CORNERS_10_5:
                  case MatchPredictionTemplate.OVER_CORNERS_11_5:
                  case MatchPredictionTemplate.OVER_CORNERS_12_5:
                  case MatchPredictionTemplate.OVER_CORNERS_13_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_0_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_1_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_2_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_3_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_4_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_5_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_6_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_7_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_8_5:
                  case MatchPredictionTemplate.OVER_YELLOW_CARDS_9_5:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_0_5:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_1_5:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_2_5:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_3_5:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_4_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_5_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_6_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_7_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_8_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_9_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_10_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_11_5:
                  case MatchPredictionTemplate.TOTAL_SAVES_OVER_12_5:
                  case MatchPredictionTemplate.TOTAL_GOALS_EVEN_OR_ODD:
                  case MatchPredictionTemplate.TOTAL_OFFSIDES_EVEN_OR_ODD:
                  case MatchPredictionTemplate.TOTAL_CORNERS_EVEN_OR_ODD:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_5_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_6_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_7_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_8_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_9_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_10_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_11_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_12_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_13_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_14_5:
                  case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_15_5:
                    return (
                      <DualChoice
                        key={e.propId}
                        type={e.questionTemplate}
                        matchInfo={e}
                        editable={false}
                        weekStatus={historyWeekStatus}
                        userPick={userPick}
                        isBooster={isBooster}
                      />
                    );
                  case MatchPredictionTemplate.PLAYER_TO_SCORE:
                  case MatchPredictionTemplate.PLAYER_TO_SCORE_FIRST:
                    return (
                      <GoalScorers
                        key={e.propId}
                        type={e.questionTemplate}
                        matchInfo={e}
                        editable={false}
                        weekStatus={historyWeekStatus}
                        userPick={userPick}
                        isBooster={isBooster}
                      />
                    );
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_5_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_6_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_7_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_8_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_9_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_10_5:
                  case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_5_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_6_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_7_5:
                  case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_8_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_GOALS_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_GOALS_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_GOALS_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_GOALS_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_GOALS_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_0_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_1_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_2_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_3_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_4_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_5_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_6_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_7_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_8_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_9_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_10_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_11_5:
                  case MatchPredictionTemplate.PLAYER_SAVES_OVER_12_5:
                  case MatchPredictionTemplate.PLAYER_GOALSCORE:
                  case MatchPredictionTemplate.PLAYER_GOAL_OR_ASSIST:
                    return (
                      <PlayerProps
                        key={e.propId}
                        type={e.questionTemplate}
                        matchInfo={e}
                        editable={false}
                        weekStatus={historyWeekStatus}
                        userPick={userPick}
                        isBooster={isBooster}
                      />
                    );
                }
                return;
              } else if (e.questionType === QuestionType.tie_breaker) {
                if (weekAction.isSkiped) {
                  return;
                }
                return (
                  <TieBreaker
                    key={e.propId}
                    matchInfo={e}
                    weekStatus={historyWeekStatus}
                    isLocked={false}
                    isEditable={false}
                    className={styles.match}
                    userPick={userPick}
                  />
                );
              } else {
                return <p>unknown question type</p>;
              }
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryListItem;
