import React from "react";
import styles from "./BoosterBanner.module.scss";
import boosterLogo from "@/assets/img/boosterButton.svg";
import { useTranslation } from "react-i18next";

type Props = {
  sponsorName: string;
  sponsorImageUri: string;
};

const BoosterBanner: React.FC<Props> = ({ sponsorName, sponsorImageUri }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.boosterBanner}>
      <div className={styles.boosterImg}>
        <img src={boosterLogo} alt="Booster Logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.contentLine}>
          <p className={styles.sponsoredText}>{t("matchBet.sponsoredBy")}</p>
          {sponsorName && <p className={styles.sponsorName}>{sponsorName}</p>}
          {sponsorImageUri && (
            <img className={styles.sponsorImage} src={sponsorImageUri} alt={sponsorName} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoosterBanner;
