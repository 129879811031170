import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MobileSlider from "@/components/mobileSlider/MobileSlider";
import useViewportSize from "@/hooks/useViewportSize";
import { ConfigContext } from "@/providers/ConfigProvider";
import { generateMapPoints } from "./utils";

import MapPoint from "./components/mapPoint/MapPoint";
import Popup from "@/components/popup/Popup";
import StepMissions from "../../components/stepMissions/StepMissions";

import { CasinoPromoConfig } from "../../casino.types";
import styles from "./AdventureMap.module.scss";
import { BullseyeArrow } from "@/assets/svg";

type Props = {
  data: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: any;
  isOptedIn: boolean;
};

type Point = {
  step: number;
  top: string;
  left: string;
  stepId: number;
};

const AdventureMap: React.FC<Props> = ({ data, userProgress, setUserProgress, isOptedIn }) => {
  const { width } = useViewportSize();
  const { t } = useTranslation();
  const promoConfig = useContext<any>(ConfigContext);

  const [isPopupOpen, setIsPopupOpen] = useState<any>(null);
  const [chosenData, setChosenData] = useState<any>(null);

  const mapPoints = useMemo(() => generateMapPoints(data?.quest?.length ?? 5), []);
  const [isPopupVisibility, setIsPopupVisibility] = useState<any>(false);

  const getCurrentStep = useMemo(() => {
    if (!userProgress?.quests || userProgress.quests.length === 0) return 1;

    for (const currentQuest of data.quest) {
      const userQuest = userProgress.quests.find((q: any) => q.questId === currentQuest.id);
      if (!userQuest) return currentQuest.stepId;

      const allTasksCompleted = currentQuest.task.every((task: any) => {
        const userTask = userQuest.tasks.find((t: any) => t.taskId === task.id);
        return userTask && userTask.status === "COMPLETED";
      });

      if (!allTasksCompleted) return currentQuest.stepId;
    }
    return data.quest[data.quest.length - 1].stepId + 1;
  }, [data.quest, userProgress]);

  const getStatus = (questStepId: number) => {
    if (!isOptedIn) return "UPCOMING";
    if (questStepId < getCurrentStep) return "COMPLETED";
    if (questStepId === getCurrentStep) return "CURRENT";
    return "UPCOMING";
  };

  const handleStep = (point: Point) => {
    if (getStatus(point.stepId) === "UPCOMING") return;
    setIsPopupOpen(point);
    setChosenData(point);
  };

  const closePopup = () => {
    setIsPopupOpen(null);
    setTimeout(() => setChosenData(null), 1);
  };

  const bannerType = width >= 940 ? "desktop" : "mobile";
  const banner = promoConfig?.components?.banner?.[bannerType] ?? [];

  return (
    <div className={`${styles.box} ${styles.adventureMap}`}>
      <div className={styles.mapWrapper}>
        <div className={styles.map}>
          <div className={styles.mapInner}>
            <div className={styles.mapRelative}>
              <img src={banner?.[0]?.uri} className={styles.mapImg} alt="" />
              {data?.quest?.map((quest: any) => {
                const correctMapPoint = mapPoints?.find((point) => point.step === quest.stepId);
                if (!correctMapPoint) return null;
                const status = getStatus(quest.stepId);

                return (
                  <MapPoint
                    key={quest.stepId}
                    step={quest.stepId}
                    status={status}
                    onClick={() => handleStep(quest)}
                    style={{ top: correctMapPoint.top, left: correctMapPoint.left }}
                    userProgress={userProgress}
                    quest={quest}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {width >= 940 ? (
        <Popup
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={
            <p className={styles.title}>
              <BullseyeArrow className={styles.bullsEye} />
              {t("casino.popupTitle")}
            </p>
          }
          className={`${styles.popup} ${isPopupVisibility ? styles.hidden : ""} `}
          headerClassName={styles.popupHeader}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
            setIsPopupVisibility={setIsPopupVisibility}
          />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={t("casino.popupTitle")}
          className={styles.mobileSlider}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
          />
        </MobileSlider>
      )}
    </div>
  );
};

export default AdventureMap;
