import styles from "./PrizeHistory.module.scss";

import arrow from "@/assets/img/arrow-icon-right.svg";
import prize from "@/assets/img/present.svg";

interface Reward {
  date: string;
  amount: string;
  description: string;
}

const data: Reward[] = [
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
  { date: "23.03", amount: "2x 5₾", description: "15 Freespins burning Hot" },
];

const PrizeHistory = () => {
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={styles.row}>
              <td className={styles.date}>{row.date}</td>
              <td className={styles.amount}>
                <img src={prize} alt="prize" />
                {row.amount}
              </td>
              <td className={styles.description}>{row.description}</td>
              <td className={styles.buttonCell}>
                <button className={styles.button}>
                  <img src={arrow} alt="arrow" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrizeHistory;
