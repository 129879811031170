import axios, { AxiosInstance } from "axios";
import AuthService from "../services/AuthService.ts";
import ConfigService from "../services/ConfigService.ts";
import { AuthContextProps } from "../providers/AuthProvider.tsx";

const authHeaders = () => {
  let promoId = ConfigService.getPromoId();
  return {
    "promofy-auth-hash": AuthService.getHash(),
    "promofy-auth-ts": AuthService.getTs(),
    "promofy-auth-api-key": AuthService.getApiKey(),
    "promofy-lng": ConfigService.getLng(),
    "promofy-session": ConfigService.getSession(),
    ...(promoId && promoId !== "" && { "promofy-promo-id": promoId }),
  };
};

export const PromofyAuthHeaders = (context?: AuthContextProps) => {
  return {
    ...authHeaders(),
    ...(context && context.isLoggedIn && { "promofy-token": `Bearer ${context.token}` }),
  };
};
export const PromofyTokenHeaders = (context: AuthContextProps) => {
  return {
    ...(context && context.isLoggedIn && { Authorization: `Bearer ${context.token}` }),
  };
};

const httpClient: AxiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_WIDGET_GATEWAY_BASE_URL}`,
  headers: {
    ...authHeaders(),
  },
});

export default httpClient;
