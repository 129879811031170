import toast, { Toaster } from "react-hot-toast";

import styles from "./Toast.module.scss";
import successIcon from "./assets/img/successIcon.svg";
import infoIcon from "./assets/img/infoIcon.svg";
import warningIcon from "./assets/img/warningIcon.svg";
import errorIcon from "./assets/img/errorIcon.svg";
import close from "./assets/img/closeIcon.svg";

type Props = {};

type ToasterType = "success" | "info" | "warning" | "error";

const setToast = (type: ToasterType, message: string | React.ReactNode) => {
  if (type === "success") {
    return toast.custom((i) => {
      return (
        <div className={`${styles.toast} ${styles.success}`}>
          <div className={styles.box}>
            <img src={successIcon} />
            <p>{message}</p>
          </div>
          <button
            className={`${styles.closeButton} ${styles.success}`}
            onClick={() => toast.dismiss(i.id)}
          >
            <img src={close} />
          </button>
        </div>
      );
    });
  } else if (type === "info") {
    return toast.custom((i) => {
      return (
        <div className={`${styles.toast} ${styles.info}`}>
          <div className={styles.box}>
            <img src={infoIcon} />
            <p>{message}</p>
          </div>
          <button
            className={`${styles.closeButton} ${styles.info}`}
            onClick={() => toast.dismiss(i.id)}
          >
            <img src={close} />
          </button>
        </div>
      );
    });
  } else if (type === "warning") {
    return toast.custom((i) => {
      return (
        <div className={`${styles.toast} ${styles.warning}`}>
          <div className={styles.box}>
            <img src={warningIcon} />
            <p>{message}</p>
          </div>
          <button
            className={`${styles.closeButton} ${styles.warning}`}
            onClick={() => toast.dismiss(i.id)}
          >
            <img src={close} />
          </button>
        </div>
      );
    });
  } else if (type === "error") {
    return toast.custom((i) => {
      return (
        <div className={`${styles.toast} ${styles.error}`}>
          <div className={styles.box}>
            <img src={errorIcon} />
            <p>{message}</p>
          </div>

          <button
            className={`${styles.closeButton} ${styles.error}`}
            onClick={() => toast.dismiss(i.id)}
          >
            <img src={close} />
          </button>
        </div>
      );
    });
  }
};
const Toast = (props: Props) => {
  return <Toaster />;
};

export default Toast;
export { setToast };
