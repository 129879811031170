import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import HistoryListItem from "./components/history/HistoryListItem.tsx";
import { AuthContext, AuthContextProps } from "../../../providers/AuthProvider.tsx";
import EmptyState from "../../../components/emptyState/EmptyState.tsx";
import LoadingPage from "../../../components/loadingPage/LoadingPage.tsx";

import { Pickem, WeekPool, WeekStatus } from "../pickem.types.ts";
import { loadUserPicks } from "../pickem.api.ts";

import styles from "./HistoryWidget.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";

type Props = { pickemData: Pickem };

const HistoryWidget = ({ pickemData }: Props) => {
  const { t } = useTranslation();

  const authContext = useContext<AuthContextProps>(AuthContext);

  const { data: userHistoryData, isLoading, isError } = loadUserPicks(authContext);

  const filteredWeekPools = useMemo(() => {
    if (!pickemData) {
      return [];
    }

    const livePools = pickemData.weekPools.filter((obj) => obj.status === WeekStatus.live);
    const completedPools = pickemData.weekPools.filter(
      (obj) => obj.status === WeekStatus.completed
    );

    // Replace 'startDate' with the correct date property in your WeekPool object
    livePools.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    completedPools.sort(
      (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );

    return [...livePools, ...completedPools];
  }, [pickemData]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const emptyState = (
      <EmptyState
          img={emptyStateImg}
          title={t("emptyState.history")}
          // text={t("emptyState.historyText")}
      />
  );

  return (
    <div className={styles.history}>
      <h2 className={styles.title}>{t("historyWidget.title")}</h2>
      {userHistoryData && filteredWeekPools.length > 0 ? (
        filteredWeekPools.map((weekPool: WeekPool, index: number) => {
          return (
            <div key={weekPool.uuid}>
              <HistoryListItem
                index={index}
                matchInfo={weekPool}
                roundName={weekPool.week}
                userHistoryRoundData={userHistoryData?.picksByGameRound?.[weekPool.uuid]}
                historyWeekStatus={weekPool.status}
                isBooster={pickemData.booster && userHistoryData?.picksByGameRound?.[weekPool.uuid]}
              />
            </div>
          );
        })
      ) : emptyState}
    </div>
  );
};

export default HistoryWidget;
