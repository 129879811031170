import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useConfig } from "@/providers/ConfigProvider";
import { CasinoPromoConfig } from "../../casino.types";
import EmptyState from "@/components/emptyState/EmptyState";
import WheelGame from "./components/wheelGame/WheelGame";
import SlidingNavigation from "@/components/slidingNavigation/SlidingNavigation";
import useViewportSize from "@/hooks/useViewportSize";
import Popup from "@/components/popup/Popup";
import PrizeHistory from "../../components/prizeHistory/PrizeHistory";
import MobileSlider from "@/components/mobileSlider/MobileSlider";

import styles from "./WheelWidget.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";
import prize from "@/assets/img/present.svg";
import SlidingProgressBar from "../../components/slidingProgressBar/SlidingProgressBar";

type Props = { casinoData: CasinoPromoConfig; userProgress: any; setUserProgress: Function };

//TEMP CODE
const mockPeriods = [
  {
    value: "2d44af44-2115-4ed1-9fe3-595d04903a231",
    label: "25 sep",
    type: "WEEKLY",
    status: "completed",
  },
  {
    value: "3cba9ff5-68ab-457e-8285-f2aa5ae731a12",
    label: "26 sep",
    type: "WEEKLY",
    status: "completed",
  },
  {
    value: "c754f403-f362-4b1a-914c-892bb413c6603",
    label: "27 sep",
    type: "WEEKLY",
    status: "unlocked",
  },
  {
    value: "c754f403-f362-4b1a-914c-892bb413c6604",
    label: "28 sep",
    type: "WEEKLY",
    status: "unlocked",
  },
  {
    value: "c754f403-f362-4b1a-914c-892bb413c6605",
    label: "29 sep",
    type: "WEEKLY",
    status: "unlocked",
  },
  {
    value: "c754f403-f362-4b1a-914c-892bb413c6606",
    label: "30 sep",
    type: "WEEKLY",
    status: "unlocked",
  },
  {
    value: "c754f403-f362-4b1a-914c-892bb413c6607",
    label: "31 sep",
    type: "WEEKLY",
    status: "unlocked",
  },
];

const mockProgressCheckpoints = [
  0, 50, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000,
];

//END OF TEMP CODE
const WheelWidget = ({ casinoData, userProgress, setUserProgress }: Props) => {
  const config = useConfig() as any;
  const { t } = useTranslation();
  const { width } = useViewportSize();

  const [spins, setSpins] = useState(1000);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!casinoData || !config)
    return (
      <div className={styles.container}>
        <EmptyState
          img={emptyStateImg}
          title={t("emptyState.oops")}
          text={t("emptyState.globalWrong")}
        />
      </div>
    );

  return (
    <div className={styles.container}>
      <SlidingNavigation
        navItems={mockPeriods}
        slidesPerView={width < 740 ? 3 : 6}
        onChange={(opt) => console.log(opt)}
        className={styles.dateNavigation}
        containerClassName={styles.dateNavigationContainer}
      />
      <SlidingProgressBar
        checkpoints={mockProgressCheckpoints}
        progressValue={5000}
        currency={"₾"}
        checkpointsPerView={6}
        checkpointsPerViewMobile={4}
      />
      <WheelGame spinState={{ spins, setSpins }} />
      <div className={styles.footer}>
        <div className={styles.spins}>
          <h4>Your Spins</h4>
          <div className={styles.spins__box}>
            <p>{spins}</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.history} onClick={() => setIsPopupOpen(true)}>
            History
          </button>
          <button className={styles.prizes}>
            <img src={prize} alt="prize" />
          </button>
        </div>
      </div>
      {width >= 940 ? (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          title={<p className={styles.title}>{t("navbar.nav3")}</p>}
          className={styles.popup}
          headerClassName={styles.popupHeader}
        >
          <PrizeHistory />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          title={<p className={styles.title}>{t("navbar.nav3")}</p>}
          className={styles.mobileSlider}
        >
          <PrizeHistory />
        </MobileSlider>
      )}
    </div>
  );
};

export default WheelWidget;
