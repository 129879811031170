interface LeaderboardEntry {
  playerId: string;
  username: string;
  score: number;
  rank: number;
}

interface Reward {
  rank: string;
  period: null | string;
  name: string;
  value: number;
}

const expandArrayByRank = (array: any[]): any[] => {
  let expandedArray: any[] = [];

  const individualRanks = new Set();

  array.forEach((obj) => {
    if (obj.rank.includes("-")) {
      let [start, end] = obj.rank.split("-").map(Number);
      for (let i = start; i <= end; i++) {
        let newObj = { ...obj, rank: String(i) };
        expandedArray.push(newObj);
        individualRanks.add(String(i));
      }
    }
  });

  array.forEach((obj) => {
    if (!obj.rank.includes("-") && !individualRanks.has(obj.rank)) {
      expandedArray.push(obj);
    }
  });

  expandedArray.sort((a, b) => Number(a.rank) - Number(b.rank));

  return expandedArray;
};

const mergeArrays = (
  leaderboardData: LeaderboardEntry[],
  rewards: Reward[]
): (LeaderboardEntry & Reward)[] => {
  const mergedArray: any[] = [];
  const maxLength = Math.max(leaderboardData.length, rewards.length);

  for (let i = 0; i < maxLength; i++) {
    const leaderboardEntry = leaderboardData[i] || {
      playerId: "",
      username: "",
      score: "",
      rank: "",
    };
    const reward = rewards[i];
    const mergedObject = {
      ...leaderboardEntry,
      ...reward,
      name: reward ? `${reward?.value} ${reward?.name}` : "",
    };

    mergedArray.push(mergedObject);
  }

  return mergedArray;
};

const filterRewardsByPeriodType = (rewards: any[], periodType?: string | null) => {
  if (!rewards) return [];
  if (!periodType) return rewards;
  return rewards.filter((e) => e.period === periodType);
};

export { mergeArrays, expandArrayByRank, filterRewardsByPeriodType };
