import { Picks } from "@/widgets/pickemWidget/pickem.types";

export enum ExactScoreType {
  BOTH_TEAMS_SCORE = "BOTH_TEAMS_SCORE",
  RESULT_AND_ONE_TEAM_SCORE = "RESULT_AND_ONE_TEAM_SCORE",
  RESULT = "RESULT",
  ONE_TEAM_SCORE = "ONE_TEAM_SCORE",
}

const exactScorePointsPercentage: Map<ExactScoreType, number> = new Map([
  [ExactScoreType.BOTH_TEAMS_SCORE, 100],
  [ExactScoreType.RESULT_AND_ONE_TEAM_SCORE, 60],
  [ExactScoreType.RESULT, 30],
  [ExactScoreType.ONE_TEAM_SCORE, 10],
]);

function matchResult(localTeamScore: number, awayTeamScore: number): string {
  if (localTeamScore > awayTeamScore) {
    return "1";
  } else if (awayTeamScore > localTeamScore) {
    return "2";
  }
  return "X";
}

export function calculatePoint(point: number, exactScoreType: ExactScoreType) {
  const percentage = exactScorePointsPercentage.get(exactScoreType);
  if (!percentage) return 0;
  return Math.round((point * percentage) / 100);
}

export const calculateExactScorePoint = (
  userPick: Picks | undefined,
  actualResult: { homeTeamPoints: number; awayTeamPoints: number },
  point: number
): number => {
  if (!userPick?.value || !actualResult) return 0;

  const [pickLocalTeamScore, pickAwayTeamScore] = userPick.value.split("-").map(Number);
  const { homeTeamPoints: propLocalTeamScore, awayTeamPoints: propAwayTeamScore } = actualResult;

  const pickResult = matchResult(pickLocalTeamScore, pickAwayTeamScore);
  const propResult = matchResult(propLocalTeamScore, propAwayTeamScore);

  let userGuess: ExactScoreType;

  switch (true) {
    case pickResult === propResult &&
      pickLocalTeamScore === propLocalTeamScore &&
      pickAwayTeamScore === propAwayTeamScore:
      userGuess = ExactScoreType.BOTH_TEAMS_SCORE;
      break;
    case pickResult === propResult &&
      (pickLocalTeamScore === propLocalTeamScore || pickAwayTeamScore === propAwayTeamScore):
      userGuess = ExactScoreType.RESULT_AND_ONE_TEAM_SCORE;
      break;
    case pickResult === propResult:
      userGuess = ExactScoreType.RESULT;
      break;
    case pickLocalTeamScore === propLocalTeamScore || pickAwayTeamScore === propAwayTeamScore:
      userGuess = ExactScoreType.ONE_TEAM_SCORE;
      break;
    default:
      return 0;
  }
  return calculatePoint(point, userGuess);
};
