import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ConfigProvider } from "@/providers/ConfigProvider";
import { AuthProvider } from "@/providers/AuthProvider";
import { LobbyConfigProvider } from "@/providers/LobbyConfigProvider";

import WidgetApp from "@/widgets/widgetApp/WidgetApp";
import LobbyWidget from "@/widgets/lobbyWidget/LobbyWidget";
import CasinoWidget from "@/widgets/casinoWidget/CasinoWidget";
import MissionsWidget from "@/widgets/casinoWidget/subWidgets/missionsWidget/MissionsWidget";
import Toast from "@/components/toast/Toast";

function App() {
  const F2P_LOBBY_CONFIG_ROUTE = `lobby/config`;
  const CASINO_MISSIONS_LOBBY_CONFIG_ROUTE = `lobby/config/mission`;

  return (
    <div className="app">
      <Toast />
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/f2p/*">
              <Route
                path="lobby"
                element={
                  <LobbyConfigProvider dataRoute={F2P_LOBBY_CONFIG_ROUTE}>
                    <LobbyWidget />
                  </LobbyConfigProvider>
                }
              />
              <Route
                path="game/*"
                element={
                  <ConfigProvider>
                    <WidgetApp />
                  </ConfigProvider>
                }
              />
            </Route>

            <Route path="/casino/*">
              <Route
                path="game"
                element={
                  <ConfigProvider>
                    <CasinoWidget />
                  </ConfigProvider>
                }
              ></Route>
              <Route
                path="missions"
                element={
                  <LobbyConfigProvider dataRoute={CASINO_MISSIONS_LOBBY_CONFIG_ROUTE}>
                    <MissionsWidget />
                  </LobbyConfigProvider>
                }
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
