import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import ConfigService from "../services/ConfigService";

import PageNotFound from "../components/pageNotFound/PageNotFound";
import LoadingPage from "../components/loadingPage/LoadingPage";
import { setGoogleTagManager, useGTagConfig } from "./utils";

import i18n from "../i18n";

const ConfigContext = createContext(null);

const setCSSVariables = (paramKey: string, paramValue: string) => {
  document.documentElement.style.setProperty(`${paramKey}`, `${paramValue}`);
};

const setPromoColors = (layout: {
  primaryColor: string;
  textColor: string;
  backgroundColor: string;
  backgroundSecondaryColor: string;
  successColor?: string;
  errorColor?: string;
  progressColor?: string;
  completedButtonColor?: string;
  playButtonColor?: string;
  upcomingButtonColor?: string;
}) => {
  setCSSVariables("--color-primary", layout.primaryColor);
  setCSSVariables("--color-primary-hover", `${layout.primaryColor}cc`);
  setCSSVariables("--color-text", layout.textColor);
  setCSSVariables("--color-text-secondary", `${layout.textColor}99`);
  setCSSVariables("--color-bg-primary", layout.backgroundColor);
  setCSSVariables("--color-bg-secondary", layout.backgroundSecondaryColor);
  if (layout.successColor) {
    setCSSVariables("--toast-color-success", layout.successColor);
  }
  if (layout.errorColor) {
    setCSSVariables("--toast-color-error", layout.errorColor);
  }
  if (layout.progressColor) {
    setCSSVariables("--toast-color-warning", layout.progressColor);
  }
  if (layout.completedButtonColor) {
    setCSSVariables("--color-completed-button-dark", layout.completedButtonColor);
    setCSSVariables("--color-completed-button", `${layout.completedButtonColor}aa`);
  } else {
    setCSSVariables("--color-completed-button-dark", "#411e60");
    setCSSVariables("--color-completed-button", "#863ec6");
  }
  if (layout.playButtonColor) {
    setCSSVariables("--color-play-button-dark", layout.playButtonColor);
    setCSSVariables("--color-play-button", layout.playButtonColor);
  } else {
    setCSSVariables("--color-play-button-dark", "#26580f");
    setCSSVariables("--color-play-button", "#14ff00");
  }
  if (layout.upcomingButtonColor) {
    setCSSVariables("--color-upcoming-button-dark", layout.upcomingButtonColor);
    setCSSVariables("--color-upcoming-button", layout.upcomingButtonColor);
  } else {
    setCSSVariables("--color-upcoming-button-dark", "#353535");
    setCSSVariables("--color-upcoming-button", "#8e8e8e");
  }
};

const setPromoBackground = (bgString: string | undefined) => {
  if (!bgString || bgString.length === 0) {
    return;
  }

  const bgObj = JSON.parse(bgString);
  setCSSVariables("--background-image-web", `url(${bgObj?.["desktop"]?.[0]?.uri})`);
  setCSSVariables("--background-image-mobile", `url(${bgObj?.["mobile"]?.[0]?.uri})`);
};

const getColorVariable = (varName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};

const updateFontVariables = (fontWeight: string, fontName: string) => {
  const currentFonts = getColorVariable(`--font-${fontWeight}`);
  const newFontFace = `${fontName}, ${currentFonts}`;
  document.documentElement.style.setProperty(`--font-${fontWeight}`, newFontFace);
};

const createFontFaceStyle = (fontPath: string, fontWeight: string) => {
  let fontName = (fontPath as any)?.split("/")?.pop()?.split(".")?.[0];
  const fontFaceStyleElement = document.createElement("style");
  fontFaceStyleElement.innerHTML = `
    @font-face {
        font-family: ${fontName};
        font-weight: ${fontWeight?.toLowerCase()};
        src: url('${import.meta.env.VITE_WIDGET_CDN_BASE_URL}/assets/fonts/${fontPath}') format('truetype');
      }
    `;
  document.head.appendChild(fontFaceStyleElement);
  updateFontVariables(fontWeight?.toLowerCase(), fontName);
};

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: promoConfig,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [`promoConfig`],
    queryFn: () => ConfigService.getPromoConfig(),
    select: (promoConfig) => promoConfig?.data,
  });

  const { data: gtagConfig } = useGTagConfig(promoConfig?.orgUuid);

  if (gtagConfig && gtagConfig?.tag) {
    setGoogleTagManager(gtagConfig.tag);
  }

  if (isError) {
    return <PageNotFound />;
  }
  if (isLoading) {
    return <LoadingPage />;
  }

  async function loadFonts() {
    const lang = i18n.language;
    let selectedLang;

    if (lang) {
      selectedLang = promoConfig.fonts.find((font: any) => font.lang === lang);
    }
    if (!selectedLang) {
      selectedLang = promoConfig.fonts.find((font: any) => font.default);
    }
    if (!selectedLang) {
      selectedLang = promoConfig.fonts[0];
    }

    const fontFaceStyles = Object.entries(selectedLang?.fontFamily ?? {});
    fontFaceStyles.forEach(([fontWeight, fontPath]) => {
      createFontFaceStyle(typeof fontPath === "string" ? fontPath : "", fontWeight);
    });
  }

  if (Array.isArray(promoConfig?.fonts) && promoConfig?.fonts?.length > 0) {
    loadFonts();
  }
  setPromoColors(promoConfig.layout);
  setPromoBackground(promoConfig.components.background);

  return <ConfigContext.Provider value={promoConfig}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  return useContext(ConfigContext);
};

export { ConfigContext };
