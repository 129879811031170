export enum ProductCategoryEnum {
  AdventureMapWith_5Quests = "ADVENTURE_MAP_WITH_5_QUESTS",
  AdventureMapWith_10Quests = "ADVENTURE_MAP_WITH_10_QUESTS",
  AdventureMapWith_15Quests = "ADVENTURE_MAP_WITH_15_QUESTS",
  AdventureMapWith_20Quests = "ADVENTURE_MAP_WITH_20_QUESTS",
  AdventureMapWith_25Quests = "ADVENTURE_MAP_WITH_25_QUESTS",
  AdventureMapWith_30Quests = "ADVENTURE_MAP_WITH_30_QUESTS",
  DailyCalendar = "DAILY_CALENDAR",
  DailyMission = "DAILY_MISSION",
  HourlyMission = "HOURLY_MISSION",
  Matrix_2X_2 = "MATRIX_2_X_2",
  Matrix_3X_3 = "MATRIX_3_X_3",
  Matrix_4X_4 = "MATRIX_4_X_4",
  MonthlyMission = "MONTHLY_MISSION",
  Nba = "NBA",
  None = "NONE",
  Quiz = "QUIZ",
  Soccer = "SOCCER",
  WeeklyMission = "WEEKLY_MISSION",
  LeaderboardCumulative = "LEADERBOARD_CUMULATIVE",
  LeaderboardHighestMultiplier = "LEADERBOARD_HIGHEST_MULTIPLIER",
  Wheel_4_Section = "WHEEL_4_SECTION",
  Wheel_6_Section = "WHEEL_6_SECTION",
  Wheel_8_Section = "WHEEL_8_SECTION",
  Wheel_10_Section = "WHEEL_10_SECTION",
  Wheel_12_Section = "WHEEL_12_SECTION",
}

export enum CasinoGameType {
  AdventureMap = "ADVENTURE_MAP",
  Matrix = "MATRIX",
  DailyOfferCalendar = "DAILY_OFFER_CALENDAR",
  Leaderboard = "LEADERBOARD",
  Wheel = "WHEEL",
  MysteryBox = "MYSTERY_BOX",
}

export type CasinoPromoConfig = {
  id: number;
  name: string;
  slug: string | null;
  promoRef: string;
  categoryId: number;
  teaserDays: number;
  startDate: string; // ISO date string
  endDate: string; // ISO date string
  status: "ACTIVE" | "INACTIVE" | "EXPIRED" | string; // Temp values
  periodType: string | null;
  periodValue: string;
  participants: number;
  optIn: boolean;
  campaignAccess: "PUBLIC" | "PRIVATE" | "RESTRICTED" | string; // Temp values
  restrictedSegments: any[]; // any[] if the type of elements is unknown
  segments: any[]; // any[] if the type of elements is unknown
  category: ProductCategoryEnum; // use union for known values
  questsUnlockStrategy: "PARALLEL" | "SEQUENTIAL" | string; // use union for known values
  quest: any[];
  questsSet: any[];
};

export type CasinoQuestProgress = {
  status: "INPROGRESS" | "COMPLETED" | "NOPROGRESS";
  currentProgress: number | null;
  maxProgress: number | null;
  needsClaiming: boolean;
};
