import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useViewportSize from "@/hooks/useViewportSize";
import styles from "./MissionCards.module.scss";
import defaultBannerImage from "@/widgets/lobbyWidget/assets/img/defaulBannerImg.svg";
import EmptyState from "@/components/emptyState/EmptyState";
import AutoRotatingSlider from "@/components/autoRotatingSlider/AutoRotatingSlider";
import Popup from "@/components/popup/Popup";
import MobileSlider from "@/components/mobileSlider/MobileSlider";
import { BullseyeArrow, Arrow } from "@/assets/svg";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import emptyStateImg from "@/assets/img/newEmpty.svg";
import AuthModalBody from "@/components/auth/AuthModalBody";
import { PromotionStatusEnum } from "@/widgets/pickemWidget/pickem.types";
import StepMissions from "../../../../components/stepMissions/StepMissions";

interface Mission {
  id: number;
  stepId: number;
  unlocks: any[];
  startDate: string;
  endDate: string;
  activeDays: any[];
  task: any[];
  promotion: {
    id: number;
    promoRef: string;
    orderingScore: string;
    name: string;
    translations: {
      [key: string]: {
        title: string;
        description: string;
        banner: string;
      };
    };
    status?: PromotionStatusEnum;
  };
}

interface MissionCategory {
  orgUuid: string;
  id: string;
  translations: {
    [key: string]: {
      title: string;
    };
  };
  missions: Mission[];
  type: string;
}

interface MissionCardProps {
  mission: Mission;
  language: string;
  onClick: () => void;
}

interface SpecialMissionCardProps {
  mission: Mission;
  language: string;
  onClick: () => void;
}

interface MissionSectionProps {
  title: string;
  missions: Mission[];
  showAll: boolean;
  onSeeAll: () => void;
  language: string;
  isSpecial: boolean;
  onMissionClick: (mission: Mission) => void;
}

interface MissionCardsProps {
  missionCategories: MissionCategory[];
  activeFilter: string;
  activeSubFilter: string;
  onSubFilterChange: (subFilter: string) => void;
  userProgress?: any;
  setUserProgress?: React.Dispatch<React.SetStateAction<any>>;
}

const SpecialMissionCard: React.FC<SpecialMissionCardProps> = ({ mission, language, onClick }) => (
  <div className={styles.specialMissionCard} onClick={onClick}>
    <img
      src={
        mission.promotion.translations[language]?.banner ||
        mission.promotion.translations["en"]?.banner ||
        defaultBannerImage
      }
      className={styles.specialBanner}
      onError={(e) => {
        e.currentTarget.src = defaultBannerImage;
      }}
      alt=""
    />
  </div>
);

const MissionCard: React.FC<MissionCardProps> = ({ mission, language, onClick }) => {
  const totalSteps = mission?.task.length ?? 1;

  const currentStep = 0;

  const getProgressBars = () => {
    const maxSteps = 5;
    const steps = Math.min(totalSteps, maxSteps);
    return Array.from({ length: steps }, (_, index) => (
      <div
        key={index}
        className={`${styles.progressBar} ${index < currentStep ? styles.filled : ""}`}
      />
    ));
  };
  return (
    <div className={styles.missionCard} onClick={onClick}>
      <img
        src={
          mission.promotion.translations[language]?.banner ||
          mission.promotion.translations["en"]?.banner ||
          defaultBannerImage
        }
        className={styles.banner}
        onError={(e) => {
          e.currentTarget.src = defaultBannerImage;
        }}
        alt={""}
      />
      <div className={styles.cardContent}>
        <h3 className={styles.cardContent__title}>
          {mission.promotion.translations[language]?.title || mission.promotion.name}
        </h3>
        <div className={styles.progress}>
          <div className={styles.progressTrack}>{getProgressBars()}</div>
          <p>
            {currentStep}/{totalSteps}
          </p>
        </div>
      </div>
    </div>
  );
};

const MissionSection: React.FC<MissionSectionProps> = ({
  title,
  missions,
  showAll,
  onSeeAll,
  language,
  isSpecial,
  onMissionClick,
}) => {
  const { t } = useTranslation();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);

  const handleSlideChange = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  if (isSpecial) {
    const items = missions.map((mission) => ({
      id: mission.id.toString(),
      content: (
        <SpecialMissionCard
          mission={mission}
          language={language}
          onClick={() => onMissionClick(mission)}
        />
      ),
    }));

    return (
      <div className={styles.missionSection}>
        <div className={styles.sectionHeader}>
          <h2>{title}</h2>
        </div>
        <AutoRotatingSlider items={items} className={styles.specialMissions} />
      </div>
    );
  }

  return (
    <div className={styles.missionSection}>
      <div className={styles.sectionHeader}>
        <h2>{title}</h2>
        {showAll && (
          <div className={styles.sectionControls}>
            <div
              className={`${styles.navigationButton} ${styles.navigationButtonLeft} `}
              onClick={handlePrev}
            >
              <Arrow direction="left" />
            </div>
            <div
              className={`${styles.navigationButton} ${styles.navigationButtonRight} `}
              onClick={handleNext}
            >
              <Arrow direction="right" />
            </div>
            <span onClick={onSeeAll} className={styles.seeAll}>
              {t("casino.seeAll")} ({missions.length})
            </span>
          </div>
        )}
      </div>
      {showAll ? (
        <div className={styles.sliderContainer}>
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView="auto"
            spaceBetween={16}
            modules={[Navigation, Pagination]}
            onSlideChange={handleSlideChange}
            className={styles.swiper}
          >
            {missions.map((mission) => (
              <SwiperSlide key={mission.id} className={styles.swiperSlide}>
                <MissionCard
                  mission={mission}
                  language={language}
                  onClick={() => onMissionClick(mission)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className={styles.cardGrid}>
          {missions.map((mission) => (
            <MissionCard
              key={mission.id}
              mission={mission}
              language={language}
              onClick={() => onMissionClick(mission)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const MissionCards: React.FC<MissionCardsProps> = ({
  missionCategories,
  activeFilter,
  activeSubFilter,
  onSubFilterChange,
  userProgress,
  setUserProgress,
}) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { t, i18n } = useTranslation();
  const { width } = useViewportSize();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [isPopupVisibility, setIsPopupVisibility] = useState<any>(false);

  const filterMissions = (missions: Mission[], filter: string): Mission[] => {
    switch (filter) {
      case "running":
        return missions.filter(
          (mission) => mission?.promotion?.status === PromotionStatusEnum.Active
        );
      case "completed":
        return missions.filter(
          (mission) => mission?.promotion?.status === PromotionStatusEnum.Completed
        );
      case "myPromotions":
        return missions.filter(
          (mission) => mission?.promotion?.promoRef === PromotionStatusEnum.Draft
        );
      default:
        return missions;
    }
  };

  const groupMissionsByCategory = (
    categories: MissionCategory[],
    filter: string
  ): Record<string, Mission[]> => {
    const groups: Record<string, Mission[]> = {};
    categories.forEach((category) => {
      const filteredMissions = filterMissions(category.missions, filter);
      if (filteredMissions.length > 0) {
        groups[category.id] = filteredMissions;
      }
    });
    return groups;
  };

  const handleMissionClick = (mission: Mission) => {
    if (!authContext.isLoggedIn) {
      setAuthModalOpen(true);
    }

    setSelectedMission(mission);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setTimeout(() => {
      setSelectedMission(null);
    }, 1);
  };

  const groupedMissions = groupMissionsByCategory(missionCategories, activeFilter);

  const PopupContent = () => (
    <StepMissions
      tasks={selectedMission as any}
      userSegment={userProgress?.segmentId ?? null}
      userProgress={userProgress}
      setUserProgress={setUserProgress}
      setIsPopupVisibility={setIsPopupVisibility}
    />
  );

  const isAllEmpty = Object.values(groupedMissions).every((missions) => missions.length === 0);

  if (isAllEmpty) {
    return (
      <EmptyState
        img={emptyStateImg}
        title={t("emptyState.oops")}
        text={t("emptyState.FAQText")}
        className={styles.emptyState}
      />
    );
  }

  return (
    <div className={styles.missionCards}>
      {activeSubFilter === "all" ? (
        Object.entries(groupedMissions).map(([categoryId, missions]) => {
          const category = missionCategories.find((cat) => cat.id === categoryId);
          if (category) {
            return (
              <MissionSection
                key={categoryId}
                title={
                  category.translations[i18n.language]?.title || category.translations["en"]?.title
                }
                missions={missions}
                showAll={true}
                onSeeAll={() => onSubFilterChange(categoryId)}
                language={i18n.language}
                isSpecial={category.type === "SPECIAL_OFFER"}
                onMissionClick={handleMissionClick}
              />
            );
          }
          return null;
        })
      ) : (
        <MissionSection
          title={
            missionCategories.find((cat) => cat.id === activeSubFilter)?.translations[i18n.language]
              ?.title || ""
          }
          missions={groupedMissions[activeSubFilter] || []}
          showAll={false}
          onSeeAll={() => {}}
          language={i18n.language}
          isSpecial={
            missionCategories.find((cat) => cat.id === activeSubFilter)?.type === "SPECIAL_OFFER"
          }
          onMissionClick={handleMissionClick}
        />
      )}

      {width >= 940 ? (
        <Popup
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={
            <p className={styles.title}>
              <BullseyeArrow className={styles.bullsEye} />
              {t("casino.popupTitle")}
            </p>
          }
          className={`${styles.popup} ${isPopupVisibility ? styles.hidden : ""} `}
          headerClassName={styles.popupHeader}
        >
          <PopupContent />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={t("casino.popupTitle")}
          className={styles.mobileSlider}
        >
          <PopupContent />
        </MobileSlider>
      )}
      <Popup
        isOpen={authModalOpen}
        onClose={() => setAuthModalOpen(false)}
        title={"Sign in"}
        className={styles.authPopup}
        headerClassName={styles.popupHeader}
        contentClassName={styles.contentBody}
      >
        <AuthModalBody />
      </Popup>
    </div>
  );
};

export default MissionCards;
