const PrizeBox = ({ ...props }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FDD106"
      {...props}
    >
      <path
        d="M8.49992 4.50016V15.1668M8.49992 4.50016H6.14278C5.79547 4.50016 5.46239 4.35969 5.2168 4.10964C4.97122 3.85959 4.83325 3.52045 4.83325 3.16683C4.83325 2.81321 4.97122 2.47407 5.2168 2.22402C5.46239 1.97397 5.79547 1.8335 6.14278 1.8335C7.97611 1.8335 8.49992 4.50016 8.49992 4.50016ZM8.49992 4.50016H10.8571C11.2044 4.50016 11.5375 4.35969 11.783 4.10964C12.0286 3.85959 12.1666 3.52045 12.1666 3.16683C12.1666 2.81321 12.0286 2.47407 11.783 2.22402C11.5375 1.97397 11.2044 1.8335 10.8571 1.8335C9.02373 1.8335 8.49992 4.50016 8.49992 4.50016ZM13.8333 7.8335V13.0335C13.8333 13.7802 13.8333 14.1536 13.6879 14.4388C13.5601 14.6897 13.3561 14.8937 13.1052 15.0215C12.82 15.1668 12.4467 15.1668 11.6999 15.1668L5.29992 15.1668C4.55318 15.1668 4.17981 15.1668 3.8946 15.0215C3.64372 14.8937 3.43974 14.6897 3.31191 14.4388C3.16659 14.1536 3.16659 13.7802 3.16659 13.0335V7.8335M1.83325 5.56683L1.83325 6.76683C1.83325 7.1402 1.83325 7.32688 1.90591 7.46949C1.96983 7.59493 2.07182 7.69692 2.19726 7.76083C2.33987 7.8335 2.52655 7.8335 2.89992 7.8335L14.0999 7.8335C14.4733 7.8335 14.66 7.8335 14.8026 7.76083C14.928 7.69692 15.03 7.59493 15.0939 7.46949C15.1666 7.32688 15.1666 7.1402 15.1666 6.76683V5.56683C15.1666 5.19346 15.1666 5.00678 15.0939 4.86417C15.03 4.73873 14.928 4.63674 14.8026 4.57283C14.66 4.50016 14.4733 4.50016 14.0999 4.50016L2.89992 4.50016C2.52655 4.50016 2.33987 4.50016 2.19726 4.57283C2.07182 4.63674 1.96983 4.73873 1.90591 4.86417C1.83325 5.00678 1.83325 5.19346 1.83325 5.56683Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PrizeBox;
