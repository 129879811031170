import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { loadExactGuessScores } from "../../../pickem.api";
import { ConfigContext } from "@/providers/ConfigProvider";

import LoadingPage from "@/components/loadingPage/LoadingPage";
import EmptyState from "@/components/emptyState/EmptyState";
import PrizeTable from "../prizeTable/PrizeTable";

import styles from "./ExactGuessAwardsTable.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";
import UserWinnings from "../userWinnings/UserWinnings";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { PickemPeriodType } from "@/widgets/pickemWidget/pickem.types";

type Props = {
  selectedFilter: any;
};
enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

const getMinimumRewardValue = (rewards: any[], periodType: PickemPeriodType) => {
  if (!rewards) {
    console.log("No rewards available!");
  }
  const scopedRewards = rewards ?? [];
  const minimumRewardScore = scopedRewards
    .filter((reward) => reward.period === periodType)
    .sort((a, b) => parseInt(a.rank) - parseInt(b.rank))[0]?.rank;
  return minimumRewardScore;
};

const assignPrizesToUsers = (users: any, rewards: any) => {
  // Step 1: Sort the users by score
  users.sort((a: any, b: any) => b.score - a.score);

  // Step 2: Assign rewards to users based on their scores
  const scoreToRewardMap: any = {};
  rewards.forEach((reward: any) => {
    scoreToRewardMap[reward.rank] = reward;
  });

  // Step 3: Count the number of users for each score
  const scoreCounts: any = {};
  users.forEach((user: any) => {
    scoreCounts[user.score] = (scoreCounts[user.score] || 0) + 1;
  });

  // Step 4: Calculate the prize for each user and add it to the user's data
  users.forEach((user: any) => {
    const reward = scoreToRewardMap[user.score];
    if (reward) {
      let prizeAmount = reward.value / scoreCounts[user.score];
      if (prizeAmount < 1) {
        prizeAmount = Math.ceil(prizeAmount); // Ceil to 1 if less than 1
      } else {
        prizeAmount = Math.floor(prizeAmount); // Floor if 1 or more
      }
      user.name = `${prizeAmount} x ${reward.name}`;
    } else {
      user.name = "-"; // If there is no reward for the score
    }
  });

  return users;
};

const findUserInAwardData = (users: any[], userId: string | undefined) => {
  const userData = users.find((e: any) => e.playerId === userId);

  if (userData) {
    return userData;
  }

  return null;
};

const ExactGuessAwardsTable = ({ selectedFilter }: Props) => {
  const { reward, rewarding, periodType } = useContext<any>(ConfigContext);
  const authContext = useContext<AuthContextProps>(AuthContext);

  const { t } = useTranslation();
  const {
    data: exactGuessData,
    isLoading,
    isError,
  } = loadExactGuessScores({
    type: selectedFilter.type,
    partitionKey: selectedFilter.value,
    minimumScore: getMinimumRewardValue(reward, periodType),
  });

  if (isError) {
    return (
      <EmptyState
        img={emptyStateImg}
        title={t("emptyState.oops")}
        text={t("emptyState.globalWrong")}
      />
    );
  }

  const combinedArray = assignPrizesToUsers(exactGuessData ?? [], reward);

  return (
    <>
      <UserWinnings
        selectedFilter={selectedFilter}
        isManual={rewarding === RewardTemplateEnum.EXACT_GUESS}
        userData={findUserInAwardData(combinedArray, authContext.getCurrentUser?.id)}
      />
      {isLoading ? (
        <LoadingPage />
      ) : Array.isArray(combinedArray) && combinedArray.length === 0 ? (
        <EmptyState
          img={emptyStateImg}
          title={t("emptyState.oops")}
          text={t("emptyState.exactGuessScores")}
          className={styles.empty}
        />
      ) : (
        <PrizeTable rewardType={rewarding} leaderboardData={combinedArray} />
      )}
    </>
  );
};

export default ExactGuessAwardsTable;
