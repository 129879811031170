import DOMPurify from "dompurify";

type Props = {
  htmlContent: string;
};

function addTargetBlankToLinksInString(domString: string) {
  // Regular expression to find all <a> tags that do not already have a target attribute
  const regex = /<a(?![^>]*\btarget=)([^>]*)>/gi;

  const updatedDomString = domString.replace(regex, '<a target="_blank"$1>');

  return updatedDomString;
}

// this component sanitizes the unsafe html Content
const SafeHtmlRenderer = ({ htmlContent }: Props) => {
  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const modifiedHtml = addTargetBlankToLinksInString(sanitizedHtml);
  return <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />;
};

export default SafeHtmlRenderer;
