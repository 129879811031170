import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useViewportSize from "@/hooks/useViewportSize";
import {
  getUserProgressForQuest,
  getUserProgressForQuestSets,
  splitArrayIntoGrid,
  splitRewards,
} from "@/widgets/casinoWidget/utils";

import StepMissions from "@/widgets/casinoWidget/components/stepMissions/StepMissions";
import QuestSetReward from "@/widgets/casinoWidget/components/questSetReward/QuestSetReward";

import CircularProgressBar from "@/components/circularProgressBar/CircularProgressBar";
import Popup from "@/components/popup/Popup";
import MobileSlider from "@/components/mobileSlider/MobileSlider";

import styles from "./MatrixGame.module.scss";

import prizeIconReady from "./assets/img/prize-icon-ready.svg";
import prizeIconOpened from "./assets/img/prize-box-open.png";
import prizeIconClosed from "./assets/img/prize-box-closed.png";
import prizeArrowIcon from "./assets/img/prize-arrow-icon.svg";
import { BullseyeArrow } from "@/assets/svg";

type Props = {
  quests: any[];
  questSets: any[];
  userProgress: any;
  setUserProgress: Function;
  backgroundImage?: string;
  isOptedIn: boolean;
};
type Section = {
  casinoReward: any[];
  startDate: string | null;
  endDate: string | null;
  id: number;
  stepId: number;
  task: any[];
  unlocks: any[];
};

const MatrixGame = ({
  quests,
  questSets,
  userProgress,
  setUserProgress,
  backgroundImage = "",
  isOptedIn,
}: Props) => {
  const { width } = useViewportSize();
  const [isQuestRewardModalOpen, setIsQuestRewardModalOpen] = useState<any>(null);
  const [isQuestSetRewardModalOpen, setIsQuestSetRewardModalOpen] = useState<any>(null);
  const [chosenData, setChosenData] = useState<any>(null);
  const [isPopupVisibility, setIsPopupVisibility] = useState<any>(false);
  const { t } = useTranslation();

  if (!quests || !questSets) return null;

  quests.sort((a, b) => a.stepId - b.stepId);
  const gridSize = Math.sqrt(quests.length || 0);

  const questsGrid = useMemo(() => splitArrayIntoGrid(quests, gridSize), [quests]);
  const { rowRewards, columnRewards } = useMemo(() => splitRewards(questSets), [questSets]);

  const closeQuestRewardModal = () => {
    setIsQuestRewardModalOpen(null);
    setTimeout(() => setChosenData(null), 1);
  };

  const closeQuestSetRewardModal = () => {
    setIsQuestSetRewardModalOpen(null);
    setTimeout(() => setChosenData(null), 1);
  };

  const handleSectionClick = (section: Section) => {
    if (!isOptedIn) return;
    setIsQuestRewardModalOpen(section);
    setChosenData(section);
  };

  const handleSetClick = (set: any) => {
    if (!isOptedIn) return;
    setIsQuestSetRewardModalOpen(set);
    setChosenData(set);
  };

  return (
    <div className={styles.matrix}>
      <div className={styles.matrix__container}>
        <div className={styles.matrix__game}>
          {questsGrid.map((row: Section[], rowIndex: number) => {
            return row.map((element: Section, columnIndex: number) => {
              const backgroundPositionX = (columnIndex / (gridSize - 1)) * 100;
              const backgroundPositionY = (rowIndex / (gridSize - 1)) * 100;

              const currentQuestReward = getUserProgressForQuest(userProgress?.quests, element.id);

              return (
                <div
                  key={`${rowIndex}-${columnIndex}`}
                  className={`${styles.matrix__tile} ${currentQuestReward?.status === "NOPROGRESS" ? styles.disabled : ""}`}
                  style={{
                    width: `${100 / gridSize - 2}%`,
                    height: `${100 / gridSize - 2}%`,
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: `${backgroundPositionX}% ${backgroundPositionY}%`,
                    backgroundSize: `${gridSize * 100}% ${gridSize * 100}%`,
                    animationDelay: `${(columnIndex + rowIndex) * 0.1}s`,
                  }}
                  onClick={() => {
                    handleSectionClick(element);
                  }}
                >
                  {currentQuestReward?.status === "INPROGRESS" && (
                    <CircularProgressBar
                      current={currentQuestReward.currentProgress || 0}
                      total={currentQuestReward.maxProgress || 0}
                      size={width >= 940 ? 80 : 40}
                      strokeWidth={width >= 940 ? 4 : 2}
                      backgroundColor="var(--color-bg-tertiary)"
                      strokeColor="var(--toast-color-success)"
                      className={styles.circularProgressBar}
                    />
                  )}
                  {currentQuestReward?.status === "COMPLETED" &&
                    currentQuestReward?.needsClaiming && <div className={styles.claim}>Claim</div>}
                </div>
              );
            });
          })}
        </div>
        <div className={styles.matrix__column}>
          {columnRewards.map((prize, index) => {
            const currentQuestSetReward = getUserProgressForQuestSets(
              userProgress?.questSet,
              prize.id
            );

            return (
              <div
                key={prize.id}
                className={`${styles.prize} ${styles.column}`}
                style={{ height: `${100 / gridSize - 2}%`, animationDelay: `${index * 0.1}s` }}
              >
                <div className={styles.prize__container}>
                  <div className={`${styles.prize__arrow} ${styles.left}`}>
                    <img src={prizeArrowIcon} alt="prize arrow" />
                  </div>
                  <div
                    className={`${styles.prize__box} ${currentQuestSetReward?.status !== "COMPLETED" ? styles.disabled : ""} ${currentQuestSetReward?.needsClaiming ? styles.ready : ""}`}
                    onClick={() =>
                      handleSetClick({
                        ...prize,
                        currentSetProgress: currentQuestSetReward?.currentProgress,
                        maxSetProgress: columnRewards.length,
                      })
                    }
                  >
                    <div className={styles.prize__img_wrapper}>
                      {currentQuestSetReward?.status === "NOPROGRESS" && (
                        <img src={prizeIconClosed} alt="" />
                      )}
                      {currentQuestSetReward?.status === "COMPLETED" && (
                        <img src={prizeIconOpened} alt="" />
                      )}
                      {currentQuestSetReward?.status === "INPROGRESS" && (
                        <img src={prizeIconClosed} alt="" />
                      )}
                      <div className={styles.prize__progress}>
                        <p>
                          {currentQuestSetReward?.currentProgress}/{columnRewards.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.matrix__row}>
          {rowRewards.map((prize, index) => {
            const currentQuestSetReward = getUserProgressForQuestSets(
              userProgress?.questSet,
              prize.id
            );
            return (
              <div
                key={prize.id}
                className={`${styles.prize} ${styles.row}`}
                style={{ width: `${100 / gridSize - 2}%`, animationDelay: `${index * 0.1}s` }}
              >
                <div className={styles.prize__container}>
                  <div className={styles.prize__arrow}>
                    <img src={prizeArrowIcon} alt="prize arrow" />
                  </div>
                  <div
                    className={`${styles.prize__box} ${currentQuestSetReward?.status !== "COMPLETED" ? styles.disabled : ""} ${currentQuestSetReward?.needsClaiming ? styles.ready : ""}`}
                    onClick={() =>
                      handleSetClick({
                        ...prize,
                        currentSetProgress: currentQuestSetReward?.currentProgress,
                        maxSetProgress: columnRewards.length,
                      })
                    }
                  >
                    <div className={styles.prize__img_wrapper}>
                      {currentQuestSetReward?.status === "NOPROGRESS" && (
                        <img src={prizeIconClosed} alt="" />
                      )}
                      {currentQuestSetReward?.status === "COMPLETED" && (
                        <img src={prizeIconOpened} alt="" />
                      )}
                      {currentQuestSetReward?.status === "INPROGRESS" && (
                        <img src={prizeIconClosed} alt="" />
                      )}
                      <div
                        className={`${styles.prize__progress} ${styles.prize__progress__bottom}`}
                      >
                        <p>
                          {currentQuestSetReward?.currentProgress}/{columnRewards.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {width >= 940 ? (
        <Popup
          isOpen={isQuestRewardModalOpen}
          onClose={closeQuestRewardModal}
          title={
            <p className={styles.title}>
              <BullseyeArrow className={styles.bullsEye} />
              {t("casino.popupTitle")}
            </p>
          }
          className={`${styles.popup} ${isPopupVisibility ? styles.hidden : ""} `}
          headerClassName={styles.popupHeader}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
            setIsPopupVisibility={setIsPopupVisibility}
          />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isQuestRewardModalOpen}
          onClose={closeQuestRewardModal}
          title={t("casino.popupTitle")}
          className={styles.mobileSlider}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
          />
        </MobileSlider>
      )}
      {width >= 940 ? (
        <Popup
          isOpen={isQuestSetRewardModalOpen}
          onClose={closeQuestSetRewardModal}
          title={
            <p className={styles.title}>
              <BullseyeArrow className={styles.bullsEye} />
              {t("casino.popupTitle")}
            </p>
          }
          className={styles.popup}
          headerClassName={styles.popupHeader}
        >
          <QuestSetReward
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
          />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isQuestSetRewardModalOpen}
          onClose={closeQuestSetRewardModal}
          title={t("casino.popupTitle")}
          className={styles.mobileSlider}
        >
          <QuestSetReward
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
          />
        </MobileSlider>
      )}
    </div>
  );
};

export default MatrixGame;
