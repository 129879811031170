import { useTranslation } from "react-i18next";

import ModalPassthrough from "../modalPassthrough/ModalPassthrough";

import styles from "./LogInModal.module.scss";

import close from "./assets/img/x-close.svg";

type Props = {
  toggleLogInModal: VoidFunction;
};

const LogInModal = (props: Props) => {
  const { t } = useTranslation();
  return (
    <ModalPassthrough>
      <div className={styles.container}>
        <div className={styles.container__header}>
          <h1>{t("loginModal.title")}</h1>
          <img src={close} alt="close" onClick={props.toggleLogInModal} />
        </div>
        <p>{t("loginModal.decription")}</p>
        <div className={styles.container__footer}>
          <button className={styles.signInButton}>{t("loginModal.buttons.signIn")}</button>
          <button className={styles.registerButton}>{t("loginModal.buttons.register")}</button>
        </div>
      </div>
    </ModalPassthrough>
  );
};

export default LogInModal;
