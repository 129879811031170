import { useTranslation } from "react-i18next";
import styles from "./Booster.module.scss";
import { useState, useMemo, useCallback, useRef } from "react";
import boosterLogo from "@/assets/img/boosterButton.svg";
import { Plus } from "@/assets/svg";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";

type Props = {
  isBoosted: boolean | undefined;
  toggleBooster: () => void;
  matchStatus?: string | null;
};

type ModalContent = {
  title: string;
  description: string;
};

const Booster = ({ isBoosted, toggleBooster, matchStatus }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef<ModalContent>({ title: "", description: "" });

  const updateModalContent = useCallback(() => {
    contentRef.current = !isBoosted
      ? {
          title: t("matchBet.confirmBoosterHeader"),
          description: t("matchBet.confirmBooster"),
        }
      : {
          title: t("matchBet.cancelBoosterHeader"),
          description: t("matchBet.cancelBooster"),
        };
  }, [isBoosted, t]);

  const handleButtonClick = useCallback(() => {
    updateModalContent();
    setIsModalOpen(true);
  }, [updateModalContent]);

  const handleConfirm = useCallback(() => {
    setIsModalOpen(false);
    toggleBooster();
    setTimeout(updateModalContent, 1);
  }, [toggleBooster, updateModalContent]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(updateModalContent, 1);
  }, [updateModalContent]);

  const buttonClassName = `${styles.booster__sponsor} ${
    (matchStatus && isBoosted) || matchStatus ? styles.outlinedButton : ""
  }`;

  return (
    <div
      className={`${styles.booster} ${matchStatus ? styles.notBoosted : ""} ${isBoosted && matchStatus ? styles[matchStatus] : ""} ${isBoosted && styles.isBoosted}`}
    >
      <p>{matchStatus && !isBoosted ? t("matchBet.boosterNotActive") : t("matchBet.booster")}</p>
      {matchStatus && !isBoosted ? null : (
        <div className={buttonClassName}>
          <button onClick={handleButtonClick}>
            {isBoosted ? <img src={boosterLogo} alt="Booster" /> : <Plus stroke="white" />}
          </button>
        </div>
      )}
      <ConfirmationModal
        visible={isModalOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        title={contentRef.current.title}
        description={contentRef.current.description}
      />
    </div>
  );
};

export default Booster;
