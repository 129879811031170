import React, { useState, useEffect, useRef } from "react";
import styles from "./WheelGame.module.scss";

import prize from "@/assets/img/present.svg";

// import CircleSlice from "../circleSlice/CircleSlice";

interface WheelGameProps {
  animationTiming?: number;
  dummySpins?: number;
  spinState: {
    spins: number;
    setSpins: Function;
  };
}

let savedSpinValue = 0;

const WheelGame: React.FC<WheelGameProps> = ({
  animationTiming = 5,
  dummySpins = 4,
  spinState,
}) => {
  const wheelWrapperRef = useRef<any>(null);
  const { spins, setSpins } = spinState;

  console.log("spins", spins);

  // this function sets custom style tag for the given layer
  const setAnimationKeyframe = (winner: number, layer: number) => {
    const currLayerChildren = wheelWrapperRef.current.children[0].children;
    const spinValue =
      360 * dummySpins - ((winner - 1) * 360) / currLayerChildren.length - (savedSpinValue % 360);

    let currentStyle = document.getElementById(`keyframe-level-${layer}`);
    currentStyle && currentStyle.remove();

    document.head.insertAdjacentHTML(
      "beforeend",
      `<style id=keyframe-level-${layer}>
      @keyframes spinning-${layer} {
        from {
          transform: rotate(${savedSpinValue}deg);
        }
        to {
          transform: rotate(${savedSpinValue + spinValue}deg);
        }
      }
    </style>`
    );

    savedSpinValue += spinValue;
    setTimeout(() => {
      wheelWrapperRef.current.style.transform = `rotate(${savedSpinValue}deg)`;
    }, 500);
  };

  //this function spins the layer that you provide to the given winning section
  const spin = (winner: number, layer: number) => {
    if (
      winner <= 0 ||
      typeof winner !== "number" ||
      winner > wheelWrapperRef.current.children[0].children.length
    ) {
      return;
    }

    setSpins(spins - 1);

    winner = Math.floor(winner);
    setAnimationKeyframe(winner, layer);

    wheelWrapperRef.current.classList.remove(styles.active);
    setTimeout(() => {
      wheelWrapperRef.current.classList.add(styles.active);
    }, 10);
  };

  const initWheel = () => {
    if (!wheelWrapperRef || !wheelWrapperRef.current) {
      return;
    }
    const currLayerChildren = wheelWrapperRef.current.children[0].children;
    for (let i = 0; i < currLayerChildren.length; i++) {
      const element = currLayerChildren[i];
      element.style.transform = `rotate(${(360 / currLayerChildren.length) * i}deg)`;
      element.style.width = `${(100 / currLayerChildren.length) * 3.14}%`;
      element.style.zIndex = i + 10; //10 here is a z-index offset so that there is some room
    }
    wheelWrapperRef.current.style.transform = "rotate(0deg)";
    wheelWrapperRef.current.style.animationDuration = animationTiming + "s";
  };

  useEffect(() => {
    initWheel();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wheel}>
        <div className={styles.wrapper} ref={wheelWrapperRef}>
          <div className={styles.anker}>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}
                <img src={prize} alt="prize" />
                <h5>150 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>1000 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>245 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>450 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>150 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>550 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>750 $</h5>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.section__content}>
                {/* <CircleSlice degree={45} color="dodgerblue" strokeColor={"yellow"} /> */}{" "}
                <img src={prize} alt="prize" />
                <h5>450 $</h5>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frame}></div>
        <div className={styles.arrow}></div>
        <button
          className={styles.spinButton}
          onClick={() => {
            const randNumber = Math.floor(Math.random() * (8 - 1 + 1) + 1);
            spin(randNumber, 0);
          }}
        >
          Spin
        </button>
      </div>
    </div>
  );
};

export default WheelGame;
