import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfigService from "@/services/ConfigService";
import NameTooltip from "../tooltip/nameTooltip/NameTooltip";

type Props = {
  player: {
    id: string;
    name: string;
  };
  language: string;
};

const PlayerNames = ({ player, language = "en" }: Props) => {
  const { t } = useTranslation();
  const [playerName, setPlayerName] = useState("");

  useEffect(() => {
    const fetchPlayerName = async () => {
      if (player?.id) {
        const playerNamesURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/player/name/${player.id}.json`;
        try {
          const response = await fetch(playerNamesURL);
          const data = await response.json();
          setPlayerName(data[language]);
        } catch (error) {
          console.error("Error fetching player name:", error);
        }
      }
    };

    fetchPlayerName();
  }, [player, language]);

  return (
    <div
      data-tooltip-id="name"
      data-tooltip-content={player?.name || t("playerProps.unknownPlayer")}
      data-tooltip-place="bottom"
    >
      {playerName || player?.name || t("playerProps.unknownPlayer")}
      <NameTooltip id="name" />
    </div>
  );
};

export default PlayerNames;
