const urlObject = new URL(window.location.href);

// Extract path parameters
const getPathParams = () =>
  urlObject.pathname.split("/").filter((part) => part !== "");
const getQueryParams = (): any => {
  let queryParams = {};
  urlObject.searchParams.forEach((value, key) => {
    // @ts-ignore
    queryParams[key] = value;
  });
  return queryParams;
};

const URLParamService = { urlObject, getPathParams, getQueryParams };

export default URLParamService;
