import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CasinoPromoConfig } from "../../casino.types";

import { useConfig } from "@/providers/ConfigProvider";
import EmptyState from "@/components/emptyState/EmptyState";
import MatrixGame from "./components/matrixGame/MatrixGame";

import styles from "./MatrixWidget.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";

type Props = {
  casinoData: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: Function;
  isOptedIn: boolean;
};

const MatrixWidget = ({ casinoData, userProgress, setUserProgress, isOptedIn }: Props) => {
  const { t } = useTranslation();
  const config = useConfig() as any;

  const backgroundImage = useMemo(
    () => config?.components.banner?.desktop?.[0]?.uri ?? "",
    [config]
  );

  if (!casinoData || !config)
    return (
      <div className={styles.container}>
        <EmptyState
          img={emptyStateImg}
          title={t("emptyState.oops")}
          text={t("emptyState.globalWrong")}
        />
      </div>
    );
  return (
    <div className={styles.container}>
      <MatrixGame
        userProgress={userProgress}
        quests={casinoData.quest}
        questSets={casinoData.questsSet}
        backgroundImage={backgroundImage}
        setUserProgress={setUserProgress}
        isOptedIn={isOptedIn}
      />
    </div>
  );
};

export default MatrixWidget;
