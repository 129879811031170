const Circles = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#FDD106"
      {...props}
    >
      <path
        d="M6.73393 3.16683C7.57562 2.34204 8.72838 1.8335 9.99992 1.8335C12.5772 1.8335 14.6666 3.92283 14.6666 6.50016C14.6666 7.77172 14.158 8.9245 13.3332 9.76619M10.6666 10.5002C10.6666 13.0775 8.57725 15.1668 5.99992 15.1668C3.42259 15.1668 1.33325 13.0775 1.33325 10.5002C1.33325 7.92283 3.42259 5.8335 5.99992 5.8335C8.57725 5.8335 10.6666 7.92283 10.6666 10.5002Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Circles;
