import prizeBox from "../../assets/img/prizeBox.png";
import prizeBoxClosed from "../../assets/img/prizeboxClosed.png";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import httpClient from "@/api/httpClient";
import ConfigService from "@/services/ConfigService";
import { ConfigContext } from "@/providers/ConfigProvider";

import { setToast } from "@/components/toast/Toast.tsx";
import Tooltip from "@/components/tooltip/Tooltip";

import styles from "./StepMissions.module.scss";
import { Arrow, BullseyeArrow, CheckMark } from "@/assets/svg";
import awardIcon from "@/assets/casinoImg/awardIcon.svg";
import checkBookIcon from "@/assets/casinoImg/checkBookIcon.svg";
import coinsIcon from "@/assets/casinoImg/coinsIcon.svg";

import ShowProviderNameModal from "./renderGameNames/ShowProviderNameModal";

interface Props {
  tasks: {
    task: any[];
    casinoReward: any[];
    id: number;
    stepId: number;
  };
  userSegment: string | null;
  userProgress: any;
  setUserProgress: any;
  setIsPopupVisibility?: any;
}

const StepMissions = ({
  tasks,
  userSegment,
  userProgress,
  setUserProgress,
  setIsPopupVisibility,
}: Props) => {
  const { t } = useTranslation();
  const configContext = useContext<any>(ConfigContext);
  const redirectUrl = configContext?.redirectUrl || "";

  const [claimingRewards, setClaimingRewards] = useState<Record<string, boolean>>({});

  const filteredTasks = tasks?.task?.reduce<Record<number, any[]>>((acc, task) => {
    const { stageId, segments, casinoReward } = task;
    if (
      (userSegment && segments.includes(userSegment)) ||
      (!userSegment && segments.length === 0)
    ) {
      if (!acc[stageId]) {
        acc[stageId] = [];
      }

      const reward = casinoReward?.find((r: any) => r.segmentId === (userSegment || null));

      acc[stageId].push({ ...task, reward });
    }
    return acc;
  }, {});

  const stepReward = useMemo(() => {
    const reward = tasks?.casinoReward?.find(
      (reward) =>
        (userSegment === null && reward.segmentId === null) ||
        (userSegment === "" && reward.segmentId === null) ||
        userSegment === reward.segmentId
    );

    const stepAward = userProgress?.awards?.find(
      (award: any) => award?.casinoReward?.quest?.id === tasks?.id
    );

    return reward ? { ...reward, userProgress: stepAward } : null;
  }, [tasks?.casinoReward, userSegment, userProgress, tasks?.id]);

  const stepRewardProgress = useMemo(() => {
    const stepAward = userProgress?.quests?.find((quest: any) => quest.stepId === tasks?.stepId);

    return stepAward;
  }, [tasks?.id, userProgress?.awards]);
  // if (!filteredTasks) return null;

  const missions = Object.values(filteredTasks ?? [])?.map((stageTasks) => {
    const task = stageTasks[0] as any;
    let status;

    let taskProgress = stepRewardProgress?.tasks?.find(
      (award: any, i: number) => award?.taskId === task.id
    );
    if (taskProgress && taskProgress?.award?.claimed === false) {
      status = "unclaimed";
    } else if (
      (taskProgress && taskProgress?.award?.claimed === true) ||
      taskProgress?.status === "COMPLETED"
    ) {
      status = "claimed";
    } else {
      status = "active";
    }

    return {
      ...task,
      status,
      progress: taskProgress?.progress ?? 0,
      total: taskProgress?.total ?? 1,
    };
  });

  function getMissionAction(mission: any) {
    switch (mission.status) {
      case "claimed":
        return (
          <div className={styles.price}>
            <div className={styles.check}>
              <CheckMark className={styles.checkMarkIcon} />
            </div>
          </div>
        );
      case "unclaimed":
        return (
          <div className={styles.price}>
            <div
              className={`${styles.claimBtn} ${claimingRewards[mission.id] ? styles.disabled : ""}`}
              onClick={() => handleRewardClaim("taskId", mission.id)}
            >
              {claimingRewards[mission.id] ? t("casinoWidget.loading") : t("casino.claim")}
            </div>
          </div>
        );
      case "active":
        return (
          // <div className={styles.arrowBg} onClick={() => console.log("go to mission")}>
          //   <Arrow direction="right" />
          // </div>
          null
        );
    }
  }

  let finishedMissions = missions?.filter((mission) => mission.status !== "active");

  async function handleRewardClaim(fieldName: string, id: string | number) {
    setClaimingRewards((prev) => ({ ...prev, [id]: true }));
    try {
      let { data } = await httpClient.post(
        `/casino/${ConfigService.getPromoId()}/user/award/claim`,
        {
          [fieldName]: id,
        }
      );
      if (data) {
        updateUserProgress(tasks.id, fieldName, id);
        setToast("success", t("casino.success"));
      } else {
        setToast("error", t("casino.error"));
      }
    } catch (err) {
      setToast("error", t("casino.error"));
    } finally {
      setClaimingRewards((prev) => ({ ...prev, [id]: false }));
    }
  }

  async function updateUserProgress(questId: any, name: string, id: string | number) {
    switch (name) {
      case "taskId":
        setUserProgress((prev: any) => {
          const newProgress = { ...prev };

          const task = newProgress.quests.find((quest: any) => quest.questId === questId);
          if (task) {
            task.tasks.forEach((task: any) => {
              if (task.taskId === id) {
                task.award.claimed = true;
              }
            });
          }
          return newProgress;
        });
        break;
      case "questId":
        setUserProgress((prev: any) => {
          const newProgress = { ...prev };

          const quest = newProgress.quests.find((quest: any) => quest.questId === id);
          if (quest) {
            quest.award.claimed = true;
          }
          return newProgress;
        });
        break;
      default:
        break;
    }
  }

  const formatList = (
    items: string[],
    maxDisplay: number = 2
  ): { visible: string[]; others: string[] } => {
    if (items.length <= maxDisplay) {
      return { visible: items, others: [] };
    }
    return {
      visible: items.slice(0, maxDisplay),
      others: items.slice(maxDisplay),
    };
  };

  const renderWithTooltip = (visible: string[], others: string[], tooltipId: string) => (
    <span>
      {visible.join(", ")}
      {others.length > 0 && (
        <>
          {" "}
          {t("casino.and")}{" "}
          <span
            data-tooltip-id={tooltipId}
            data-tooltip-content={others.join(", ")}
            data-tooltip-place="bottom"
            className={`tooltip-${tooltipId}`}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {others.length} {t("casino.others")}
          </span>
          <Tooltip targetClassName={`tooltip-${tooltipId}`} arrowPosition="center">
            {others.join(", ")}
          </Tooltip>
        </>
      )}
    </span>
  );

  function translateTitle(mission: any): JSX.Element {
    const { visible: gameVisible, others: gameOthers } = mission.gameNames
      ? formatList(mission.gameNames)
      : { visible: [], others: [] };
    const { visible: providerVisible, others: providerOthers } = mission.providerNames
      ? formatList(mission.providerNames)
      : { visible: [], others: [] };

    const gameTooltipId = `game-tooltip-${mission.id}`;
    const providerTooltipId = `provider-tooltip-${mission.id}`;

    const gameElement = mission.gameNames
      ? renderWithTooltip(gameVisible, gameOthers, gameTooltipId)
      : null;
    const providerElement = mission.providerNames
      ? renderWithTooltip(providerVisible, providerOthers, providerTooltipId)
      : null;

    const translationParams = {
      amount: mission.taskType === "Bet" ? mission.sumAmount : mission.count ?? 1,
      currency: mission.currency,
      minBet: mission.minAmount,
    };

    let translationKey: string;
    let showGame = false;
    let showProvider = false;

    switch (mission.taskType) {
      case "Win":
        translationKey = "casino.win";
        showGame = true;
        break;
      case "Login":
        translationKey = "casino.login";
        break;
      case "Deposit":
        translationKey = "casino.deposit";
        break;
      case "Win_multiplier":
        translationKey = "casino.winMultiplier";
        showProvider = true;
        break;
      case "Spin":
        translationKey = "casino.spin";
        showGame = true;
        break;
      case "Win_in_a_row":
        translationKey = "casino.winInARow";
        showGame = true;
        break;
      case "Lose_in_a_row":
        translationKey = "casino.loseInARow";
        showGame = true;
        break;
      case "Bet":
        translationKey = "casino.bet";
        showGame = true;
        showProvider = true;
        break;
      default:
        return <span>{mission.taskType}</span>;
    }

    return (
      <span>
        {t(translationKey, translationParams)}
        {showGame && gameElement && mission.gameNames.length === 1 && (
          <>
            &nbsp;
            {t("casino.onGame", { game: "" })}
            {mission.gameNames}
            {/* {gameElement} */}
          </>
        )}
        {/* {showProvider && providerElement && (
          <>
            &nbsp;
            {t("casino.inProvider", { provider: "" })}
            {providerElement}
          </>
        )} */}
      </span>
    );
  }

  function minbetVisibility(mission: any) {
    switch (mission.taskType) {
      case "Login":
      case "Deposit":
        return null;
      default:
        return (
          <>
            <img src={coinsIcon} /> {t("casino.minBet")}: {mission.minAmount} {mission.currency}
          </>
        );
    }
  }

  return (
    <div className={styles.stepMissions}>
      {stepReward && (
        <div className={`${styles.reward}  ${styles.animate__slide__up} ${styles[`delay-1`]}`}>
          {stepRewardProgress?.award?.claimed ? (
            <img src={prizeBox} alt="" />
          ) : (
            <img src={prizeBoxClosed} alt="" />
          )}
          <p className={styles.rewardTitle}>{t("casino.missionReward")}</p>
          <h3 className={styles.rewardAmount}>
            {t("casino.rewardTranslation", {
              amount: stepReward.value,
              name: stepReward.name,
              game: stepReward.gameName,
              provider: stepReward.providerName,
            })}
          </h3>
          {(missions?.length || missions?.length !== 0) && (
            <div className={styles.progress}>
              <div className={styles.progressTrack}>
                <div
                  style={{
                    width: `${((finishedMissions?.length ?? 0) / (missions?.length > 0 ? missions?.length : 1)) * 100}%`,
                  }}
                ></div>
              </div>
              <p>
                {finishedMissions?.length ?? 0}/{missions?.length ?? 1}
              </p>
            </div>
          )}
        </div>
      )}
      <div className={styles.missions}>
        {missions.map((mission, index) => {
          return (
            <div
              key={index}
              className={`${styles.mission} ${styles[mission.status]} ${styles.animate__slide__up} ${styles[`delay-${index + 2}`]}`}
            >
              <div className={styles.missionData}>
                <div className={styles.games}>
                  <h3>
                    <img src={checkBookIcon} />
                    {translateTitle(mission)}
                  </h3>
                  <div>
                    {mission.gameNames.length > 1 && (
                      <ShowProviderNameModal
                        mission={mission}
                        setIsPopupVisibility={setIsPopupVisibility}
                      />
                    )}
                  </div>
                </div>
                <p>{minbetVisibility(mission)}</p>

                <div className={styles.progress}>
                  <div className={styles.progressTrack}>
                    <div
                      style={{
                        width: `${((mission.progress ?? 0) / (mission.taskType === "Bet" ? mission.sumAmount || 1 : mission.count > 0 || 1)) * 100}%`,
                      }}
                    ></div>
                  </div>
                  <p>
                    {mission.progress}/
                    {mission.taskType === "Bet" ? mission.sumAmount || 1 : mission.count > 0 || 1}
                  </p>
                </div>
                {mission.reward && (
                  <p>
                    <img src={awardIcon} />
                    {t("casino.reward")}:{" "}
                    {t("casino.rewardTranslation", {
                      amount: mission.reward.value,
                      name: mission.reward.name,
                      game: mission.reward.gameName,
                      provider: mission.reward.providerName,
                    })}
                  </p>
                )}
              </div>
              {getMissionAction(mission)}
            </div>
          );
        })}
      </div>
      {finishedMissions.length === missions.length &&
      stepRewardProgress?.award?.claimed === false ? (
        <div
          className={`${styles.mainClaim} ${claimingRewards[tasks.id] ? styles.disabled : ""}`}
          onClick={() => handleRewardClaim("questId", tasks.id)}
        >
          {claimingRewards[tasks.id] ? t("casinoWidget.loading") : t("casino.claim")}
        </div>
      ) : (
        <button
          className={styles.playBtn}
          onClick={(e) => {
            e.preventDefault();
            if (redirectUrl) {
              window.open(redirectUrl, "_blank");
            } else {
              console.warn("Redirect URL is not defined");
            }
          }}
        >
          Play
        </button>
      )}
    </div>
  );
};

export default StepMissions;
