import { useState, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Picks, WeekStatus } from "@/widgets/pickemWidget/pickem.types";
import WidgetCard from "@/components/widgetCard/WidgetCard";
import Popup from "@/components/popup/Popup";
import MobileSlider from "@/components/mobileSlider/MobileSlider";
import useViewportSize from "@/hooks/useViewportSize";
import ConfigService from "@/services/ConfigService.ts";

import ChoosePlayer from "./ChoosePlayer";
import { MatchPredictionTemplate, MatchStatus } from "../MatchStatuses";

import ArrowRight from "../assets/img/ArrowRight.svg";
import UserIcon from "../assets/img/notChoosenIcon.svg";
import ChoosenUserIcon from "../assets/img/choosenIcon.svg";
import CorrectIcon from "../assets/img/Correct.svg";
import IncorrectIcon from "../assets/img/Incorrect.svg";
import InProgressIcon from "../assets/img/InProgress.svg";

import { formatMatchDate } from "@/components/utils/formatMatchDate";

import styles from "./GoalScorers.module.scss";
import axios from "axios";
import NameTooltip from "@/components/tooltip/nameTooltip/NameTooltip";
import SelectedPlayer from "@/assets/svg/SelectedPlayer";

interface Props {
  matchInfo: any;
  editable?: boolean;
  addOptionToArray?: any;
  weekStatus?: WeekStatus;
  userPick?: Picks;
  type: MatchPredictionTemplate;
  selectedOption?: string | null;
  loadedLocalStorage?: boolean;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
}

interface Player {
  id: string;
  name: string;
  position: string;
  teamId: string;
  teamName: string;
}

interface Team {
  players: Player[];
}

const GoalScorers = ({
  matchInfo,
  editable = true,
  weekStatus,
  userPick,
  addOptionToArray,
  type,
  selectedOption,
  loadedLocalStorage,
  isBooster,
  isBoosted,
  toggleBooster,
}: Props) => {
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const [chosenPlayer, setChosenPlayer] = useState<{ name: string; id: string }>({
    name: "",
    id: "",
  });
  const [teams, setTeams] = useState<Team[]>([]);
  const { width } = useViewportSize();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const teamIds = [eventInfo?.homeTeamId, eventInfo?.awayTeamId];
        const teamDataPromises = teamIds.map((teamId) =>
          axios.get(`${cdnBaseURL}info/${teamId}.json`)
        );
        const teamDataResponses = await Promise.all(teamDataPromises);
        const teamData = teamDataResponses.map((response) => ({ players: response.data }));
        setTeams(teamData);
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };
    fetchTeamData();
  }, [cdnBaseURL, eventInfo?.homeTeamId, eventInfo?.awayTeamId]);

  function setDefaultValue(value: string) {
    const findPlayer = (playerId: string) => {
      if (playerId === "none") return { name: "No goal", id: "none" };
      if (playerId === "owngoal") return { name: "Own goal", id: "owngoal" };
      return teams.flatMap((team) => team.players).find((player) => player.id === playerId);
    };

    const player = findPlayer(value);
    if (player) {
      setChosenPlayer({ name: player.name, id: player.id });
    }
  }
  useEffect(() => {
    if (userPick?.value) {
      setDefaultValue(userPick.value);
    } else if (selectedOption) {
      setDefaultValue(selectedOption);
    }
  }, [userPick, teams, loadedLocalStorage]);

  useEffect(() => {
    if (chosenPlayer.id) {
      if (chosenPlayer.id === "none" || chosenPlayer.id === "owngoal") {
        addOptionToArray &&
          addOptionToArray(matchInfo.propId, {
            value: chosenPlayer.id,
          });
      } else {
        addOptionToArray &&
          addOptionToArray(matchInfo.propId, {
            value: chosenPlayer.id,
          });
      }
    }
  }, [chosenPlayer]);

  const isMatchFinished = useMemo(
    () =>
      eventInfo.homeTeamPoints !== null &&
      !isNaN(eventInfo.homeTeamPoints) &&
      eventInfo.awayTeamPoints !== null &&
      !isNaN(eventInfo.awayTeamPoints),
    [eventInfo]
  );

  const isHistory = useMemo(() => {
    if (!weekStatus) return false;
    return [WeekStatus.live, WeekStatus.completed].includes(weekStatus);
  }, [weekStatus]);

  const cancelledOrPostponed = useMemo(() => {
    return [MatchStatus.postponed, MatchStatus.cancelled].includes(eventInfo.status);
  }, [eventInfo.status]);

  const translatedChosenPlayer = useMemo(() => {
    if (chosenPlayer.id === "none") return t("goalscorer.noGoal");
    if (chosenPlayer.id === "owngoal") return t("goalscorer.ownGoal");
    return chosenPlayer.name;
  }, [chosenPlayer]);

  function getOptionClassName(player: { name: string; id: string }) {
    const eventValueArray = matchInfo.eventValue?.split("|") || [];
    return ` ${
      isHistory && isMatchFinished && eventValueArray.includes(player.id) ? styles.correct : ""
    } ${
      isHistory && isMatchFinished && userPick?.value === player.id && userPick?.correct === false
        ? styles.incorrect
        : ""
    } ${isHistory && (!isMatchFinished || userPick?.correct === null) && userPick?.value === player.id ? styles.inProgress : ""}`;
  }

  const eventValueArray = useMemo(() => {
    return matchInfo.eventValue?.split("|") || [];
  }, [matchInfo.eventValue]);

  const matchStatus = useMemo(() => {
    if (!isHistory || !userPick) return null;
    if (!isMatchFinished || userPick.correct === null) return "inProgress";
    return userPick.correct ? "correct" : "incorrect";
  }, [isHistory, isMatchFinished, userPick]);

  function renderOptionIcon(player: { name: string; id: string }) {
    if (!isHistory || !userPick || userPick.value !== player.id) return null;

    switch (matchStatus) {
      case "inProgress":
        return <img src={InProgressIcon} className={styles.optionIcon} alt="" />;
      case "correct":
        if (eventValueArray.includes(player.id)) {
          return <img src={CorrectIcon} className={styles.optionIcon} alt="" />;
        }
        return null;
      case "incorrect":
        return <img src={IncorrectIcon} className={styles.optionIcon} alt="" />;
      default:
        return null;
    }
  }
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const headerQuestion = useMemo(() => {
    switch (type) {
      case MatchPredictionTemplate.PLAYER_TO_SCORE:
        return t("goalscorer.willScore");
      case MatchPredictionTemplate.PLAYER_TO_SCORE_FIRST:
        return t("goalscorer.firstScorer");
    }
  }, [eventInfo.status]);

  return (
    <WidgetCard
      booster={false}
      headerInfo={{
        matchDate: matchInfo.matchTime,
        userPick,
        point:
          (isHistory && userPick?.booster) || isBoosted ? matchInfo?.point * 2 : matchInfo?.point,
        question: headerQuestion,
        isHistory,
        isMatchFinished,
      }}
      editable={editable}
      isBooster={isBooster}
      isBoosted={isHistory && userPick?.booster ? true : isBoosted}
      toggleBooster={toggleBooster}
      matchStatus={matchStatus}
    >
      <div className={styles.matchBet__teams}>
        <div className={styles.matchBet__team}>
          <p
            data-tooltip-id="homeTeamName"
            data-tooltip-content={eventInfo?.homeTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.homeTeamName || "unknown"}
          </p>
          <NameTooltip id="homeTeamName" />
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.homeTeamId}.png`} />
          </div>
        </div>
        <div className={styles.matchBet__center}>
          <div className={styles.dualResult}>
            <p>vs</p>
            <span>{formatMatchDate(matchInfo.matchTime)}</span>
          </div>
        </div>
        <div className={styles.matchBet__team}>
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.awayTeamId}.png`} />
          </div>
          <p
            data-tooltip-id="awayTeamName"
            data-tooltip-content={eventInfo?.awayTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.awayTeamName || "unknown"}
          </p>
          <NameTooltip id="awayTeamName" />
        </div>
      </div>
      {weekStatus !== WeekStatus.upcoming && (
        <>
          {cancelledOrPostponed ? (
            <div className={styles.matchBet__picker}>
              <div className={styles.postponedWrapper}>
                <div className={styles.postponedBtn}>
                  {MatchStatus.postponed === eventInfo.status
                    ? t("matchBet.postponed")
                    : t("matchBet.cancelled")}
                </div>
              </div>
            </div>
          ) : isHistory && userPick && Object.keys(userPick).length > 0 ? (
            <div className={styles.matchBet__picker}>
              <div className={styles.historyOptions}>
                <div className={`${styles.historyBtn} ${getOptionClassName(chosenPlayer)}`}>
                  <img src={UserIcon} alt="" />
                  <p>{translatedChosenPlayer}</p>
                  {renderOptionIcon(chosenPlayer)}
                </div>
              </div>
            </div>
          ) : !isHistory ? (
            <div className={styles.matchBet__picker}>
              <div className={styles.matchBet__options}>
                <div
                  className={`${styles.chosenPlayerState} ${
                    chosenPlayer?.name ? styles.playerChoosen : ""
                  }`}
                >
                  {chosenPlayer?.name ? (
                    <SelectedPlayer className={styles.userSelectedIcon} />
                  ) : (
                    <img src={UserIcon} />
                  )}

                  <p>{chosenPlayer?.name ? translatedChosenPlayer : t("goalscorer.notChosen")}</p>
                </div>
                <div
                  className={`${styles.choosePlayerAction} ${
                    chosenPlayer?.name ? styles.choosenPlayerAction : ""
                  }`}
                  onClick={openPopup}
                >
                  <p>{!chosenPlayer?.name ? t("goalscorer.choose") : t("goalscorer.edit")}</p>
                  <img src={ArrowRight} />
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
      {width >= 940 ? (
        <>
          <Popup
            isOpen={isPopupOpen}
            onClose={closePopup}
            title={
              type === MatchPredictionTemplate.PLAYER_TO_SCORE
                ? t("goalscorer.popupTitle")
                : t("goalscorer.popupFirstHalf")
            }
          >
            <ChoosePlayer
              matchInfo={matchInfo}
              close={closePopup}
              chosenPlayer={chosenPlayer}
              choosePlayer={(player) => setChosenPlayer(player)}
              teams={teams}
            />
          </Popup>
        </>
      ) : (
        <MobileSlider
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={
            type === MatchPredictionTemplate.PLAYER_TO_SCORE
              ? t("goalscorer.popupTitle")
              : t("goalscorer.popupFirstHalf")
          }
        >
          <ChoosePlayer
            matchInfo={matchInfo}
            close={closePopup}
            chosenPlayer={chosenPlayer}
            choosePlayer={(player) => setChosenPlayer(player)}
            teams={teams}
          />
        </MobileSlider>
      )}
    </WidgetCard>
  );
};

export default GoalScorers;
