import React, { useRef } from "react";
import styles from "./MissionsSubFilter.module.scss";
import menu from "../../assets/img/menu.svg";
import fire from "../../assets/img/fire.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";

type Props = {
  filters: { label: string; value: string; type?: "SPECIAL_OFFER" | "CUSTOM" }[];
  activeFilter: string;
  onSelectFilter: (filter: string) => void;
};

const MissionsSubFilter: React.FC<Props> = ({ filters, activeFilter, onSelectFilter }) => {
  const swiperRef = useRef<SwiperType | null>(null);

  return (
    <div className={styles.wrapper}>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerView="auto"
        spaceBetween={12}
        freeMode={{
          enabled: true,
          momentum: true,
          momentumRatio: 0.8,
          momentumVelocityRatio: 0.8,
        }}
        modules={[FreeMode]}
        className={styles.swiper}
      >
        {filters.map((filter, index) => (
          <SwiperSlide key={index} className={styles.swiperSlide}>
            <div
              className={`${styles.filterButton} ${
                activeFilter === filter.value ? styles.active : ""
              } ${filter?.type === "SPECIAL_OFFER" ? styles.special : ""}`}
              onClick={() => onSelectFilter(filter.value)}
            >
              {filter.value === "all" && <img src={menu} alt="Menu" className={styles.icon} />}
              {filter?.type === "SPECIAL_OFFER" && (
                <img src={fire} alt="Fire" className={styles.icon} />
              )}
              {filter.label}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MissionsSubFilter;
