import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider.tsx";
import { Points } from "@/assets/svg";
import styles from "../playerPropsCard/PlayerPropsCard.module.scss";
import { ConfigContext } from "@/providers/ConfigProvider";

type PlayerPropsHeaderProps = {
  info?: {
    userPick?: any;
    point?: number;
    isHistory: boolean;
    isMatchFinished: boolean;
  };
};

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

const PlayerPropsPoints: React.FC<PlayerPropsHeaderProps> = ({ info }) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const promoConfig = useContext<any>(ConfigContext);
  const { t } = useTranslation();

  const showPoints = useMemo(
    () =>
      authContext.isLoggedIn &&
      info?.userPick &&
      info?.isHistory &&
      info?.isMatchFinished &&
      Object.keys(info?.userPick).length > 0 &&
      promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS,
    [authContext.isLoggedIn, info?.userPick]
  );

  const showInProgress = useMemo(
    () => info?.isHistory && !info?.isMatchFinished,
    [info?.isHistory, info?.isMatchFinished]
  );

  return (
    <div className={styles.widget__header}>
      {showPoints ? (
        <p className={`${info?.userPick.correct ? styles.correct : styles.incorrect}`}>
          {t("historyWidget.weekStatus.points", {
            pointsAmount: info?.point,
          })}
        </p>
      ) : showInProgress ? (
        <p className={styles.inProgress}>
          {info?.point}&nbsp;
          {t("historyWidget.weekStatus.pointsShort")}
        </p>
      ) : info?.isHistory ? (
        ""
      ) : (
        <>
          {promoConfig.rewarding !== RewardTemplateEnum.EXACT_GUESS ? (
            <div className={styles.widgetPoints}>
              <Points className={styles.pointsIcon} />
              <h2 className={styles.points}>
                {info?.point}&nbsp;
                {t("historyWidget.weekStatus.pointsShort")}
              </h2>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default PlayerPropsPoints;
