import URLParamService from "./URLParamService";

const queryParams = URLParamService.getQueryParams();
const apiKey = queryParams.apiKey;
const hash = queryParams.hash;
const ts = queryParams.ts;

const getApiKey = (): string => {
  if (queryParams.hasOwnProperty("apiKey") && apiKey?.length > 0) {
    return apiKey;
  }
  return "";
};

const getHash = (): string => {
  if (queryParams.hasOwnProperty("hash") && hash?.length > 0) {
    return hash;
  }
  return "";
};

const getTs = (): number => {
  if (queryParams.hasOwnProperty("ts") && ts) {
    return ts;
  }
  return Math.floor(new Date().getTime() / 1000);
};

const AuthService = {
  getApiKey,
  getHash,
  getTs,
};

export default AuthService;
