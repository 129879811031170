import { useContext, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext, AuthContextProps } from "@/providers/AuthProvider.tsx";

import SubmitProgress from "../../submitProgress/SubmitProgress.tsx";
import EmptyState from "@/components/emptyState/EmptyState.tsx";
import TieBreaker from "../tieBreaker/TieBreaker.tsx";
import Timer from "@/components/timer/Timer.tsx";
import { ConfigContext } from "@/providers/ConfigProvider";

import {
  findPicksByPropId,
  getNumberOfPicks,
  PickemProp,
  Picks,
  QuestionType,
  UserPicks,
  WeekPool,
  WeekStatus,
} from "../../../pickem.types.ts";

import styles from "./WidgetSelector.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";
import ExactMatch from "../exactMatch/ExactMatch.tsx";
import FT1x2Match from "../FT1x2Match/FT1x2Match.tsx";

import { MatchPredictionTemplate } from "../MatchStatuses.ts";
import DualChoice from "../dualChoice/DualChoice.tsx";
import GoalScorers from "../goalScorers/GoalScorers.tsx";
import { PostUserPickMsg } from "@/services/PostMessageService.ts";
import PlayerProps from "../playerProps/PlayerProps.tsx";
import BoosterBanner from "@/components/boosterBanner/BoosterBanner.tsx";

type Props = {
  weekPool: WeekPool;
  userPicks: UserPicks;
  toggleLogInModal: VoidFunction;
  promoRef?: string;
  isBooster: boolean;
};

function defineRoundEditable(
  authContext: AuthContextProps,
  weekPool: WeekPool,
  userPicks: UserPicks
) {
  const isLoggedIn = authContext.isLoggedIn;
  if (!isLoggedIn) {
    return true;
  }
  if (weekPool.status !== WeekStatus.unlocked) {
    return false;
  }
  if (!userPicks) {
    return true;
  }
  // If user has no picks yet, he should be able to make picks
  const picks = userPicks?.picksByGameRound?.[weekPool?.uuid];
  if (!picks) {
    return true;
  }
  // If the user already has picks, he should press the edit button
  if (picks.length > 0) {
    return false;
  }
  return false;
}

const safeJsonParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

const WidgetSelector = ({ weekPool, userPicks, toggleLogInModal, promoRef, isBooster }: Props) => {
  const weekId = weekPool.uuid;

  const normalizedUserPicks = useMemo(() => {
    if (userPicks && userPicks.picksByGameRound && userPicks.picksByGameRound[weekId]) {
      return userPicks.picksByGameRound[weekId].reduce((acc: any, item: any) => {
        acc[item.propId] = { value: item.value, booster: item?.booster };
        return acc;
      }, {});
    }
    return {};
  }, [userPicks, weekId]);

  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<any>(normalizedUserPicks || {});
  const [loadedLocalStorage, setLoadedLocalStorage] = useState(false);
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { sponsor } = useContext<any>(ConfigContext);
  const parsedSponsor = safeJsonParse(sponsor);

  const sponsorName = parsedSponsor?.name ?? "";
  const sponsorImageUri = parsedSponsor?.image?.[0]?.uri ?? "";

  const [isRoundEditable, setIsRoundEditable] = useState(
    defineRoundEditable(authContext, weekPool, userPicks)
  );

  const boostedPropId = useMemo(() => {
    const boostedProp = Object.entries(selectedOptions).find(
      ([_, value]: [string, any]) => value.booster
    );
    return boostedProp ? Number(boostedProp[0]) : null;
  }, [selectedOptions]);

  const addOptionToArray = (propId: number, option: object) => {
    setSelectedOptions((prevState: any) => {
      const newState = { ...prevState, [propId]: { ...prevState?.[propId], ...option } };
      if (Object.keys(normalizedUserPicks).length === 0) {
        localStorage.setItem(weekId, JSON.stringify(newState));
      }
      return newState;
    });
  };

  const toggleBooster = (propId: number) => {
    setSelectedOptions((prevOptions: any) => {
      const newOptions = { ...prevOptions };
      const isBoosted = newOptions[propId]?.booster;

      Object.keys(newOptions).forEach((key) => {
        if (newOptions[key].booster) {
          newOptions[key] = { ...newOptions[key], booster: false };
        }
      });

      if (!isBoosted) {
        newOptions[propId] = { ...newOptions[propId], booster: true };
      }

      localStorage.setItem(weekId, JSON.stringify(newOptions));
      return newOptions;
    });
  };

  const roundData = weekPool?.props?.sort((a: PickemProp, b: PickemProp) => {
    const dateA = a.matchTime ? new Date(a.matchTime).getTime() : Infinity;
    const dateB = b.matchTime ? new Date(b.matchTime).getTime() : Infinity;
    if (dateA === Infinity && dateB === Infinity) {
      return 0;
    } else if (dateA === Infinity) {
      return 1;
    } else if (dateB === Infinity) {
      return -1;
    } else {
      return dateA - dateB;
    }
  });

  const progress = useMemo(() => {
    return Object.values(selectedOptions).filter(
      (option: any) => option?.value !== undefined && option?.value !== ""
    ).length;
  }, [selectedOptions]);

  useEffect(() => {
    const storedOptions = localStorage.getItem(weekId);
    if (storedOptions && Object.keys(normalizedUserPicks).length === 0) {
      setSelectedOptions(JSON.parse(storedOptions));
      setLoadedLocalStorage(true);
    } else if (Object.keys(normalizedUserPicks).length > 0) {
      setSelectedOptions(normalizedUserPicks);
    }
  }, [weekId, normalizedUserPicks]);

  switch (weekPool.status) {
    case WeekStatus.upcoming:
    case WeekStatus.unlocked:
      let upcomingWeek = weekPool.status === WeekStatus.upcoming;
      if (!Array.isArray(roundData) || !roundData.length) {
        return (
          <div className={styles.emptyState}>
            <EmptyState img={emptyStateImg} title={""} text={t("emptyState.liveMatches")} />
          </div>
        );
      }
      return (
        <>
          <Timer
            titleBox={upcomingWeek ? t("timer.upcomingTitle") : t("timer.activeTitle")}
            endDate={upcomingWeek ? weekPool.unlockDate : weekPool.startDate}
          />
          {(sponsorName || sponsorImageUri) && (
            <BoosterBanner sponsorName={sponsorName} sponsorImageUri={sponsorImageUri} />
          )}
          <div className={`${styles.matchBets}`}>
            {roundData.map((e: PickemProp) => {
              switch (e.questionType) {
                case QuestionType.team_vs_team:
                  switch (e.questionTemplate) {
                    case MatchPredictionTemplate.EXACT_SCORE:
                    case MatchPredictionTemplate.HT_EXACT_SCORE:
                    case MatchPredictionTemplate.HT_PERFECT_SCORE:
                    case MatchPredictionTemplate.PERFECT_SCORE:
                      return (
                        <ExactMatch
                          key={e.propId}
                          type={e.questionTemplate}
                          matchInfo={e}
                          editable={isRoundEditable}
                          weekStatus={weekPool.status}
                          addOptionToArray={addOptionToArray}
                          userPick={findPicksByPropId(
                            userPicks?.picksByGameRound?.[e?.game_week_id] ?? [],
                            e.propId
                          )}
                          selectedOption={selectedOptions?.[e.propId]?.value}
                          loadedLocalStorage={loadedLocalStorage}
                          isBooster={isBooster}
                          isBoosted={boostedPropId === e.propId}
                          toggleBooster={() => toggleBooster(e.propId)}
                        />
                      );
                    case MatchPredictionTemplate.FT_1X2:
                    case MatchPredictionTemplate.DOUBLE_CHANCE:
                    case MatchPredictionTemplate.MORE_CORNERS:
                    case MatchPredictionTemplate.MORE_OFFSIDES:
                      return (
                        <FT1x2Match
                          key={e.propId}
                          type={e.questionTemplate}
                          matchInfo={e}
                          editable={isRoundEditable}
                          weekStatus={weekPool.status}
                          addOptionToArray={addOptionToArray}
                          selectedOption={selectedOptions?.[e.propId]?.value}
                          isBooster={isBooster}
                          isBoosted={boostedPropId === e.propId}
                          toggleBooster={() => toggleBooster(e.propId)}
                        />
                      );
                    case MatchPredictionTemplate.YELLOW_CARD_IN_MATCH:
                    // case MatchPredictionTemplate.RED_CARD_IN_MATCH:
                    case MatchPredictionTemplate.BOTH_TEAMS_SCORE:
                    case MatchPredictionTemplate.OVER_FOULS_10_5:
                    case MatchPredictionTemplate.OVER_FOULS_11_5:
                    case MatchPredictionTemplate.OVER_FOULS_12_5:
                    case MatchPredictionTemplate.OVER_FOULS_13_5:
                    case MatchPredictionTemplate.OVER_FOULS_14_5:
                    case MatchPredictionTemplate.OVER_FOULS_15_5:
                    case MatchPredictionTemplate.OVER_FOULS_16_5:
                    case MatchPredictionTemplate.OVER_FOULS_17_5:
                    case MatchPredictionTemplate.OVER_FOULS_18_5:
                    case MatchPredictionTemplate.OVER_FOULS_19_5:
                    case MatchPredictionTemplate.OVER_FOULS_20_5:
                    case MatchPredictionTemplate.OVER_FOULS_21_5:
                    case MatchPredictionTemplate.OVER_FOULS_22_5:
                    case MatchPredictionTemplate.OVER_FOULS_23_5:
                    case MatchPredictionTemplate.OVER_FOULS_24_5:
                    case MatchPredictionTemplate.OVER_FOULS_25_5:
                    case MatchPredictionTemplate.OVER_FOULS_26_5:
                    case MatchPredictionTemplate.OVER_FOULS_27_5:
                    case MatchPredictionTemplate.OVER_FOULS_28_5:
                    case MatchPredictionTemplate.OVER_FOULS_29_5:
                    case MatchPredictionTemplate.OVER_FOULS_30_5:
                    case MatchPredictionTemplate.OVER_GOALS_0_5:
                    case MatchPredictionTemplate.OVER_GOALS_1_5:
                    case MatchPredictionTemplate.OVER_GOALS_2_5:
                    case MatchPredictionTemplate.OVER_GOALS_3_5:
                    case MatchPredictionTemplate.OVER_GOALS_4_5:
                    case MatchPredictionTemplate.OVER_GOALS_5_5:
                    case MatchPredictionTemplate.OVER_GOALS_6_5:
                    case MatchPredictionTemplate.OVER_CORNERS_6_5:
                    case MatchPredictionTemplate.OVER_CORNERS_7_5:
                    case MatchPredictionTemplate.OVER_CORNERS_8_5:
                    case MatchPredictionTemplate.OVER_CORNERS_9_5:
                    case MatchPredictionTemplate.OVER_CORNERS_10_5:
                    case MatchPredictionTemplate.OVER_CORNERS_11_5:
                    case MatchPredictionTemplate.OVER_CORNERS_12_5:
                    case MatchPredictionTemplate.OVER_CORNERS_13_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_0_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_1_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_2_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_3_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_4_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_5_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_6_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_7_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_8_5:
                    case MatchPredictionTemplate.OVER_YELLOW_CARDS_9_5:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_0_5:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_1_5:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_2_5:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_3_5:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_OVER_4_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_5_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_6_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_7_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_8_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_9_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_10_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_11_5:
                    case MatchPredictionTemplate.TOTAL_SAVES_OVER_12_5:
                    case MatchPredictionTemplate.TOTAL_GOALS_EVEN_OR_ODD:
                    case MatchPredictionTemplate.TOTAL_OFFSIDES_EVEN_OR_ODD:
                    case MatchPredictionTemplate.TOTAL_CORNERS_EVEN_OR_ODD:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_5_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_6_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_7_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_8_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_9_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_10_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_11_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_12_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_13_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_14_5:
                    case MatchPredictionTemplate.TOTAL_SHOTS_ON_GOAL_OVER_15_5:
                      return (
                        <DualChoice
                          key={e.propId}
                          type={e.questionTemplate}
                          matchInfo={e}
                          editable={isRoundEditable}
                          weekStatus={weekPool.status}
                          addOptionToArray={addOptionToArray}
                          userPick={findPicksByPropId(
                            userPicks?.picksByGameRound?.[e?.game_week_id] || [],
                            e.propId
                          )}
                          selectedOption={selectedOptions?.[e.propId]?.value}
                          isBooster={isBooster}
                          isBoosted={boostedPropId === e.propId}
                          toggleBooster={() => toggleBooster(e.propId)}
                        />
                      );
                    case MatchPredictionTemplate.PLAYER_TO_SCORE:
                    case MatchPredictionTemplate.PLAYER_TO_SCORE_FIRST:
                      return (
                        <GoalScorers
                          key={e.propId}
                          type={e.questionTemplate}
                          matchInfo={e}
                          editable={isRoundEditable}
                          weekStatus={weekPool.status}
                          addOptionToArray={addOptionToArray}
                          userPick={findPicksByPropId(
                            userPicks?.picksByGameRound?.[e?.game_week_id] ?? [],
                            e.propId
                          )}
                          selectedOption={selectedOptions?.[e.propId]?.value}
                          loadedLocalStorage={loadedLocalStorage}
                          isBooster={isBooster}
                          isBoosted={boostedPropId === e.propId}
                          toggleBooster={() => toggleBooster(e.propId)}
                        />
                      );
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_5_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_6_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_7_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_8_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_9_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_TOTAL_OVER_10_5:
                    case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_ASSISTS_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_COMMITTED_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_FOULS_DRAWN_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_5_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_6_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_7_5:
                    case MatchPredictionTemplate.PLAYER_DRIBBLE_SUCCESS_OVER_8_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_SHOTS_ON_GOAL_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_GOALS_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_GOALS_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_GOALS_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_GOALS_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_GOALS_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_0_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_1_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_2_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_3_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_4_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_5_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_6_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_7_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_8_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_9_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_10_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_11_5:
                    case MatchPredictionTemplate.PLAYER_SAVES_OVER_12_5:
                    case MatchPredictionTemplate.PLAYER_GOALSCORE:
                    case MatchPredictionTemplate.PLAYER_GOAL_OR_ASSIST:
                      return (
                        <PlayerProps
                          key={e.propId}
                          type={e.questionTemplate}
                          matchInfo={e}
                          editable={isRoundEditable}
                          weekStatus={weekPool.status}
                          addOptionToArray={addOptionToArray}
                          userPick={findPicksByPropId(
                            userPicks?.picksByGameRound?.[e?.game_week_id] || [],
                            e.propId
                          )}
                          selectedOption={selectedOptions?.[e.propId]?.value}
                          isBooster={isBooster}
                          isBoosted={boostedPropId === e.propId}
                          toggleBooster={() => toggleBooster(e.propId)}
                        />
                      );
                  }
                  return;
                case !upcomingWeek && QuestionType.tie_breaker:
                  return (
                    <TieBreaker
                      key={e.propId}
                      matchInfo={e}
                      weekStatus={weekPool.status}
                      isLocked={false}
                      isEditable={isRoundEditable}
                      addOptionToArray={addOptionToArray}
                      sliderValue={selectedOptions?.[e.propId]?.value}
                    />
                  );
              }
            })}
          </div>
          {!upcomingWeek && (
            <SubmitProgress
              progress={progress}
              maxProgress={roundData.length}
              toggleLogInModal={toggleLogInModal}
              selectedOptions={selectedOptions}
              hasUserPicks={getNumberOfPicks(weekPool.uuid, userPicks) > 0}
              setIsRoundEditable={setIsRoundEditable}
              weekId={weekId}
              boostedPropId={boostedPropId}
              isBooster={isBooster}
            />
          )}
        </>
      );
    default:
      return <div>Unknown Round</div>;
  }
};

export default WidgetSelector;
