import React from "react";
import styles from "./LeaderboardGameTable.module.scss";
import { Circles, PrizeBox, Trophy } from "../../assets/svg";

import firstPlace from "../../assets/img/Frame.png";
import secondPlace from "../../assets/img/Frame-1.png";
import thirdPlace from "../../assets/img/Frame-2.png";
import SlidingNavigation from "@/components/slidingNavigation/SlidingNavigation";
import useViewportSize from "@/hooks/useViewportSize";
import { useTranslation } from "react-i18next";
import EmptyState from "@/components/emptyState/EmptyState";
import emptyStateImg from "@/assets/img/newEmpty.svg";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import { mergeArrays } from "@/widgets/pickemWidget/leaderboard/utils";

interface UserData {
  rank: number;
  username: string;
  score: string;
}

interface LeaderboardEntry {
  rank: number;
  username: string;
  score: number;
  prize: string;
  playerId: string;
}

interface LeaderboardGameTableProps {
  title: string;
  userData: UserData;
  entries: LeaderboardEntry[];
  mode: "daily" | "overall";
  onDateChange?: (date: any) => void;
  periods: any[];
  isLoading: boolean;
  isError: boolean;
  rewardData: any[];
}

const LeaderboardGameTable: React.FC<LeaderboardGameTableProps> = ({
  title,
  userData,
  entries,
  mode,
  onDateChange,
  periods,
  isLoading,
  isError,
  rewardData,
}) => {
  const { width } = useViewportSize();
  const { t } = useTranslation();

  const combinedArray = mergeArrays(entries ?? [], rewardData);

  return (
    <div className={styles.leaderboardContainer}>
      <div className={styles.topLeaderboardContent}>
        <h2 className={styles.title}>{title}</h2>
        {mode === "daily" ? (
          <div className={styles.dateSelector}>
            <SlidingNavigation
              navItems={periods}
              slidesPerView={width < 600 ? "auto" : 3}
              onChange={onDateChange}
              className={styles.dateNavigation}
              containerClassName={styles.dateNavigationContainer}
            />
          </div>
        ) : (
          <div className={styles.overallDate}>{periods?.[0]?.label}</div>
        )}
        <div className={styles.userPrizeBox}>
          <div className={styles.prizeColumn}>
            <span className={styles.prizeLabel}>
              <Trophy />
            </span>
            <p className={styles.prizeValue}>{userData?.rank > 0 ? userData?.rank : "-"}</p>
          </div>
          <div className={styles.prizeColumn}>
            <p className={styles.prizeLabel}>{t("prizeTable.header.user")}</p>
            <p className={styles.prizeValue}>{userData?.username || "-"}</p>
          </div>
          <div className={styles.prizeColumn}>
            <p className={styles.prizeLabel}>
              <Circles /> {t("prizeTable.header.points")}
            </p>
            <p className={styles.prizeValue}>{userData?.score?.toLocaleString() || "-"}</p>
          </div>
          <div className={styles.prizeColumn}>
            <p className={styles.prizeLabel}>
              <PrizeBox /> {t("prizeTable.header.prize")}
            </p>
            <p className={styles.prizeValue}>
              {rewardData?.find((e) => e.rank == userData?.rank)?.name || "-"}
            </p>
          </div>
        </div>
      </div>
      <table className={styles.leaderboardTable}>
        <thead>
          <tr>
            <th className={styles.rankColumn}>
              <Trophy />
            </th>
            <th className={styles.userColumn}>
              <p>{t("prizeTable.header.user")}</p>
            </th>
            <th className={styles.pointsColumn}>
              <Circles />
              <p>{t("prizeTable.header.points")}</p>
            </th>
            <th className={styles.tablePrizeColumn}>
              <PrizeBox />
              <p>{t("prizeTable.header.prize")}</p>
            </th>
          </tr>
        </thead>
      </table>
      <div className={styles.tableWrapper}>
        {isLoading ? (
          <LoadingPage className={styles.loading} />
        ) : !combinedArray || combinedArray?.length === 0 ? (
          <EmptyState
            img={emptyStateImg}
            title={t("emptyState.history")}
            className={styles.empty}
          />
        ) : (
          <table className={styles.leaderboardTable}>
            <tbody>
              {combinedArray?.map((entry, index) => (
                <tr key={entry.rank} className={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                  <td className={styles.rankColumn}>
                    <span className={styles.medal}>
                      {entry.rank}
                      {entry.rank <= 3 && (
                        <>
                          {
                            [
                              <img src={firstPlace} alt="" />,
                              <img src={secondPlace} alt="" />,
                              <img src={thirdPlace} alt="" />,
                            ][entry.rank - 1]
                          }
                        </>
                      )}
                    </span>
                  </td>
                  <td className={styles.userColumn}>{entry?.username || "-"}</td>
                  <td className={styles.pointsColumn}>{entry?.score?.toLocaleString() || "-"}</td>
                  <td className={styles.tablePrizeColumn}>{entry?.name || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default LeaderboardGameTable;
