import { useMemo } from "react";

import WidgetCard from "@/components/widgetCard/WidgetCard";
import ConfigService from "@/services/ConfigService.ts";
import { Picks, WeekStatus } from "@/widgets/pickemWidget/pickem.types";

import styles from "./DualChoice.module.scss";

import { MatchPredictionTemplate, MatchStatus } from "../MatchStatuses";

import { useTranslation } from "react-i18next";

import CorrectIcon from "../assets/img/Correct.svg";
import IncorrectIcon from "../assets/img/Incorrect.svg";
import InProgressIcon from "../assets/img/InProgress.svg";
import { formatMatchDate } from "@/components/utils/formatMatchDate";
import NameTooltip from "@/components/tooltip/nameTooltip/NameTooltip";

interface Props {
  matchInfo: any;
  editable: boolean;
  addOptionToArray?: any;
  locked?: boolean;
  weekStatus: WeekStatus;
  userPick?: Picks;
  type: MatchPredictionTemplate | null | undefined;
  selectedOption?: any;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
}

const DualChoice = ({
  matchInfo,
  editable,
  locked = false,
  userPick,
  weekStatus,
  addOptionToArray,
  type,
  selectedOption,
  isBooster,
  isBoosted,
  toggleBooster,
}: Props) => {
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const { t } = useTranslation();

  function handleOptionSelect(option: string) {
    addOptionToArray(matchInfo.propId, { value: option });
  }

  const isHistory = useMemo(
    () => [WeekStatus.live, WeekStatus.completed].includes(weekStatus),
    [weekStatus]
  );

  const [negativeOption, negativeTranslation, positiveOption, positiveTranslation, headerQuestion] =
    useMemo(() => {
      switch (type) {
        case MatchPredictionTemplate.TOTAL_GOALS_EVEN_OR_ODD:
          return [
            "odd",
            t("dualChoice.odd"),
            "even",
            t("dualChoice.even"),
            t("dualChoice.TotalGoals"),
          ];
        case MatchPredictionTemplate.TOTAL_OFFSIDES_EVEN_OR_ODD:
          return [
            "odd",
            t("dualChoice.odd"),
            "even",
            t("dualChoice.even"),
            t("dualChoice.TotalOffsides"),
          ];
        case MatchPredictionTemplate.TOTAL_CORNERS_EVEN_OR_ODD:
          return [
            "odd",
            t("dualChoice.odd"),
            "even",
            t("dualChoice.even"),
            t("dualChoice.TotalOuts"),
          ];
        case MatchPredictionTemplate.YELLOW_CARD_IN_MATCH:
          return [
            "No",
            t("dualChoice.no"),
            "Yes",
            t("dualChoice.yes"),
            t("dualChoice.yellowCards"),
          ];
        case MatchPredictionTemplate.RED_CARD_IN_MATCH:
          return ["No", t("dualChoice.no"), "Yes", t("dualChoice.yes"), t("dualChoice.redCards")];
        case MatchPredictionTemplate.BOTH_TEAMS_SCORE:
          return [
            "No",
            t("dualChoice.no"),
            "Yes",
            t("dualChoice.yes"),
            t("dualChoice.bothTeamsScore"),
          ];
        default:
          const parts = type?.split("_");
          const lastTwoParts = parts?.slice(-2);
          if (lastTwoParts && !isNaN(Number(lastTwoParts[0])) && !isNaN(Number(lastTwoParts[1]))) {
            const label = parts?.slice(0, -2).join("_");
            const number = `${lastTwoParts[0]},${lastTwoParts[1]}`;
            let returnLabel = "";
            switch (label) {
              case "OVER_FOULS":
                returnLabel = t("dualChoice.fouls", { number });
                break;
              case "OVER_GOALS":
                returnLabel = t("dualChoice.goals", { number });
                break;
              case "OVER_CORNERS":
                returnLabel = t("dualChoice.corners", { number });
                break;
              case "OVER_YELLOW_CARDS":
                returnLabel = t("dualChoice.aboveYellowCards", { number });
                break;
              case "TOTAL_SAVES_OVER":
                returnLabel = t("dualChoice.totalSaves", { number });
                break;
              case "TOTAL_OFFSIDES_OVER":
                returnLabel = t("dualChoice.totalOffsides", { number });
                break;
              case "TOTAL_SHOTS_ON_GOAL_OVER":
                returnLabel = t("dualChoice.TotalShotsOnGoalOver", { number });
                break;
            }
            return ["No", t("dualChoice.no"), "Yes", t("dualChoice.yes"), returnLabel];
          }
          return ["No", t("dualChoice.no"), "Yes", t("dualChoice.yes")];
      }
    }, [type, weekStatus]);

  function getOptionClassName(option: string) {
    return `${styles.matchBet__option} ${!locked && selectedOption === option ? styles.active : ""} 
    ${isHistory && isMatchFinished && matchInfo.eventValue === option && userPick?.correct !== null ? styles.correct : ""} ${
      isHistory && isMatchFinished && userPick?.value === option && userPick?.correct === false
        ? styles.incorrect
        : ""
    } ${isHistory && userPick?.value === option ? styles.chosenBorder : ""}
    ${isHistory && (!isMatchFinished || userPick?.correct === null) && userPick?.value === option ? styles.inProgress : ""}`;
  }

  const isMatchFinished = useMemo(
    () =>
      eventInfo.homeTeamPoints !== null &&
      !isNaN(eventInfo.homeTeamPoints) &&
      eventInfo.awayTeamPoints !== null &&
      !isNaN(eventInfo.awayTeamPoints),
    [eventInfo]
  );

  const showOptions = useMemo(() => {
    if (isHistory) {
      if (userPick && Object.keys(userPick).length > 0) {
        return true;
      }
      return false;
    }
    return weekStatus !== WeekStatus.upcoming;
  }, [userPick, weekStatus]);

  const cancelledOrPostponed = useMemo(() => {
    return [MatchStatus.postponed, MatchStatus.cancelled].includes(eventInfo.status);
  }, [eventInfo.status]);

  const matchStatus = useMemo(() => {
    if (!isHistory || !userPick) return null;
    if (!isMatchFinished || userPick.correct === null) return "inProgress";
    return userPick.correct ? "correct" : "incorrect";
  }, [isHistory, isMatchFinished, userPick]);

  function renderOptionIcon(option: string) {
    if (!isHistory || !userPick || userPick.value !== option) return null;

    switch (matchStatus) {
      case "inProgress":
        return <img src={InProgressIcon} className={styles.optionIcon} alt="" />;
      case "correct":
        return <img src={CorrectIcon} className={styles.optionIcon} alt="" />;
      case "incorrect":
        return <img src={IncorrectIcon} className={styles.optionIcon} alt="" />;
      default:
        return null;
    }
  }
  function getTranslationsByEventValue(eventValue: string) {
    switch (eventValue) {
      case "odd":
        return t("dualChoice.odd");
      case "even":
        return t("dualChoice.even");
      case "Yes":
        return t("dualChoice.yes");
      case "No":
        return t("dualChoice.no");
      default:
        return null;
    }
  }

  return (
    <WidgetCard
      booster={false}
      headerInfo={{
        matchDate: matchInfo.matchTime,
        userPick,
        question: headerQuestion,
        point:
          (isHistory && userPick?.booster) || isBoosted ? matchInfo?.point * 2 : matchInfo?.point,
        isHistory,
        isMatchFinished,
      }}
      editable={editable}
      disabled={cancelledOrPostponed}
      isBooster={isBooster}
      isBoosted={isHistory && userPick?.booster ? true : isBoosted}
      toggleBooster={toggleBooster}
      matchStatus={matchStatus}
    >
      <div className={styles.matchBet__teams}>
        <div className={styles.matchBet__team}>
          <p
            data-tooltip-id="homeTeamName"
            data-tooltip-content={eventInfo?.homeTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.homeTeamName || "unknown"}
          </p>
          <NameTooltip id="homeTeamName" />
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.homeTeamId}.png`} />
          </div>
        </div>

        {!isHistory ? (
          <div className={styles.matchBet__center}>
            <div className={styles.dualResult}>
              <p>vs</p>
              <span>{formatMatchDate(matchInfo.matchTime)}</span>
            </div>
          </div>
        ) : isHistory && matchInfo?.eventValue && isMatchFinished ? (
          <div className={styles.matchBet__center}>
            <div className={styles.dualResult}>
              <p className={styles.result}>{t("dualChoice.result")}</p>
              <span>{getTranslationsByEventValue(matchInfo.eventValue)}</span>
            </div>
          </div>
        ) : (
          <div className={styles.matchBet__center}>
            <p>vs</p>
          </div>
        )}

        <div className={styles.matchBet__team}>
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.awayTeamId}.png`} />
          </div>
          <p
            data-tooltip-id="awayTeamName"
            data-tooltip-content={eventInfo?.awayTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.awayTeamName || "unknown"}
          </p>
          <NameTooltip id="awayTeamName" />
        </div>
      </div>
      {showOptions && (
        <div className={styles.matchBet__picker}>
          {cancelledOrPostponed ? (
            <div className={styles.postponedWrapper}>
              <div className={styles.postponedBtn}>
                {MatchStatus.postponed === eventInfo.status
                  ? t("matchBet.postponed")
                  : t("matchBet.cancelled")}
              </div>
            </div>
          ) : (
            <>
              <div
                className={getOptionClassName(positiveOption)}
                onClick={!locked ? () => handleOptionSelect(positiveOption) : undefined}
              >
                <p>{positiveTranslation}</p>
                {renderOptionIcon(positiveOption)}
              </div>
              <div
                className={getOptionClassName(negativeOption)}
                onClick={!locked ? () => handleOptionSelect(negativeOption) : undefined}
              >
                <p>{negativeTranslation}</p>
                {renderOptionIcon(negativeOption)}
              </div>
            </>
          )}
        </div>
      )}
    </WidgetCard>
  );
};

export default DualChoice;
