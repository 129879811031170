declare global {
  interface Window {
    dataLayer: any[];
  }
}

export {};

const trackTabChange = (tabName: string) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promofy_tab_change",
    promofy: {
      type: "f2p",
      sub_type: "soccer",
      action: `tab_${tabName}`,
    },
  });
};

const trackSubmitPickem = (isSuccess: boolean) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: isSuccess ? "promofy_pickem_submit_success" : "promofy_pickem_submit_failure",
    promofy: {
      type: "f2p",
      sub_type: "soccer",
      action: isSuccess ? "submit_pick_success" : "submit_pick_failure",
    },
  });
};

const trackLobbyTabChange = (tabName: string) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "promofy_lobby_tab_change",
    promofy: {
      type: "f2p",
      sub_type: "soccer",
      action: `tab_${tabName}`,
    },
  });
};
export { trackTabChange, trackSubmitPickem, trackLobbyTabChange };
