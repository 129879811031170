import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Navbar.module.scss";

import { useConfig } from "@/providers/ConfigProvider";

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

const Navbar = () => {
  const { t } = useTranslation();
  const config: any = useConfig();
  const isTopNavbarEnabled = import.meta.env.VITE_WIDGET_TOP_NAVBAR_ENABLED;

  return (
    <div
      className={styles.nav}
      style={
        isTopNavbarEnabled === "true"
          ? {
              position: "static",
              margin: "0 auto",
            }
          : {}
      }
    >
      <div className={styles.nav__wrapper}>
        <NavLink
          to={`pickem${location.search}`}
          className={({ isActive }) => (isActive ? styles.active : "") + " " + styles.nav__item}
        >
          <div className={`${styles.navbarIcon} ${styles.pickem}`}></div>
          <p>{t("navbar.nav1")}</p>
        </NavLink>
        <NavLink
          to={`leaderboard${location.search}`}
          className={({ isActive }) => (isActive ? styles.active : "") + " " + styles.nav__item}
        >
          <div className={`${styles.navbarIcon} ${styles.leaderboard}`}></div>
          <p>
            {config.rewarding === RewardTemplateEnum.EXACT_GUESS
              ? t("navbar.nav5")
              : t("navbar.nav2")}
          </p>
        </NavLink>
        <NavLink
          to={`history${location.search}`}
          className={({ isActive }) => (isActive ? styles.active : "") + " " + styles.nav__item}
        >
          <div className={`${styles.navbarIcon} ${styles.history}`}></div>
          <p>{t("navbar.nav3")}</p>
        </NavLink>
        <NavLink
          to={`faq${location.search}`}
          className={({ isActive }) => (isActive ? styles.active : "") + " " + styles.nav__item}
        >
          <div className={`${styles.navbarIcon} ${styles.faq}`}></div>
          <p>{t("navbar.nav4")}</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
