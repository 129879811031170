import styles from "./EmptyState.module.scss";

type Props = {
  img?: any;
  title?: string;
  text?: string;
  className?: string;
  backgroundColor?: string;
};

const EmptyState = ({ img, title, text, className = "", backgroundColor }: Props) => {
  return (
    <div className={`${styles.emptyState} ${className} `} style={{ background: backgroundColor }}>
      <img src={img} />
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};

export default EmptyState;
