import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";

import styles from "./SlidingNavigation.module.scss";

import arrow from "@/assets/img/arrow-icon-right.svg";

type NavigationItem = {
  value: any;
  label: string | React.ReactNode;
  [key: string]: any;
};

type Props<T = any> = {
  navItems?: T[];
  className?: string;
  onChange?: (value: any) => void;
  onNextClick?: Function;
  onPrevClick?: Function;
  [key: string]: any;
};

const SlidingNavigation = ({
  navItems,
  className = "",
  onNextClick,
  onPrevClick,
  onChange,
  containerClassName = "",
  slidesPerView = 3,
  ...sliderProps
}: Props) => {
  const [navigationSwiper, setNavigationSwiper] = useState<SwiperType | null>(null);
  const [activeSlideValue, setActiveSlideValue] = useState(navItems?.[0].value || {});

  const handleNextClick = () => {
    onNextClick && onNextClick();
    navigationSwiper && navigationSwiper.slideNext();
  };

  const handlePrevClick = () => {
    onPrevClick && onPrevClick();
    navigationSwiper && navigationSwiper.slidePrev();
  };

  const handleChange = (item: NavigationItem) => {
    onChange && onChange(item);
    setActiveSlideValue(item.value);
  };

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {Array.isArray(navItems) &&
        (navItems.length > 1 ? (
          <>
            <div className={styles.prevButton} onClick={handlePrevClick}>
              <img src={arrow} alt="left" />
            </div>
            <Swiper
              className={`${styles.slider} ${className}`}
              slidesPerView={slidesPerView}
              speed={100}
              spaceBetween={4}
              onSwiper={setNavigationSwiper}
              {...sliderProps}
            >
              {navItems.map((element, index) => {
                return (
                  <SwiperSlide
                    onClick={() => handleChange(element)}
                    className={`${element.value === activeSlideValue ? "active" : ""}`}
                    key={index}
                  >
                    {element.label}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className={styles.nextButton} onClick={handleNextClick}>
              <img src={arrow} alt="right" />
            </div>
          </>
        ) : (
          <div className={styles.singleEntry}>{navItems[0]?.label}</div>
        ))}
    </div>
  );
};

export default SlidingNavigation;
