import { useState, useEffect, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import PlusIcon from "../../../../../assets/img/plus-icon.svg";
import MinusIcon from "../../../../../assets/img/minus-icon.svg";
import { Picks, WeekStatus } from "@/widgets/pickemWidget/pickem.types";
import WidgetCard from "@/components/widgetCard/WidgetCard";
import ConfigService from "@/services/ConfigService.ts";

import { calculateExactScorePoint } from "@/components/utils/exactScoreCalcs";

import styles from "./ExactMatch.module.scss";
import { formatMatchDate } from "@/components/utils/formatMatchDate";
import { MatchPredictionTemplate } from "../MatchStatuses";
import NameTooltip from "@/components/tooltip/nameTooltip/NameTooltip";

interface Props {
  matchInfo: any;
  editable?: boolean;
  addOptionToArray?: any;
  weekStatus?: WeekStatus;
  userPick?: Picks;
  selectedOption?: any;
  type: any;
  loadedLocalStorage?: boolean;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
}

interface scores {
  team1Score: number | string;
  team2Score: number | string;
}

const ExactMatch = ({
  matchInfo,
  editable = true,
  weekStatus,
  userPick,
  addOptionToArray,
  selectedOption,
  type,
  loadedLocalStorage,
  isBooster,
  isBoosted,
  toggleBooster,
}: Props) => {
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const [scores, setScores] = useState<scores>({ team1Score: "-", team2Score: "-" });
  const [choiceMade, setChoiceMade] = useState(false);
  const isFirstRender = useRef(true);

  const { t } = useTranslation();

  const updateScore = (team: string, increment: boolean) => {
    setScores((prevScores) => {
      const newScores: scores = { ...prevScores };

      const updateTeamScore = (teamScore: number | string) => {
        const MAX_SCORE = 100;
        if (teamScore === "-") {
          return increment ? 0 : "-";
        } else {
          let score = teamScore as number;
          if (increment) {
            return score < MAX_SCORE ? score + 1 : MAX_SCORE;
          } else {
            return score > 0 ? score - 1 : "-";
          }
        }
      };

      switch (team) {
        case "team1":
          newScores.team1Score = updateTeamScore(newScores.team1Score);
          break;
        case "team2":
          newScores.team2Score = updateTeamScore(newScores.team2Score);
          break;
        default:
          break;
      }

      if (newScores.team1Score !== "-" && newScores.team2Score !== "-") {
        setChoiceMade(true);
      }

      return newScores;
    });
  };

  function handleDefaultPick(value: string) {
    let extractedValues = value?.split("-");
    setScores({
      team1Score: Number(extractedValues?.[0] ?? 0),
      team2Score: Number(extractedValues?.[1] ?? 0),
    });
  }

  useEffect(() => {
    if (userPick?.value) {
      setChoiceMade(true);
      handleDefaultPick(userPick?.value);
    } else if (selectedOption) {
      setChoiceMade(true);
      handleDefaultPick(selectedOption);
    }
  }, [userPick, loadedLocalStorage]);

  useEffect(() => {
    if (scores.team1Score !== "-" && scores.team2Score !== "-") {
      if (!isFirstRender.current) {
        addOptionToArray &&
          addOptionToArray(matchInfo.propId, {
            value: `${scores.team1Score}-${scores.team2Score}`,
          });
      }
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [scores, eventInfo.matchId]);

  const isMatchFinished = useMemo(
    () =>
      eventInfo.homeTeamPoints !== null &&
      !isNaN(eventInfo.homeTeamPoints) &&
      eventInfo.awayTeamPoints !== null &&
      !isNaN(eventInfo.awayTeamPoints),
    [eventInfo]
  );

  const isHistory = useMemo(() => {
    if (!weekStatus) return false;
    return [WeekStatus.live, WeekStatus.completed].includes(weekStatus);
  }, [weekStatus]);

  const [eventValueScore1, eventValueScore2] = useMemo(() => {
    if (matchInfo?.eventValue) {
      const extractedValues = matchInfo.eventValue.split("-");
      return [Number(extractedValues[0] ?? 0), Number(extractedValues[1] ?? 0)];
    }
    return [0, 0];
  }, [matchInfo]);

  const exactScorePoints = useMemo(() => {
    if (
      (type !== MatchPredictionTemplate.HT_EXACT_SCORE &&
        type !== MatchPredictionTemplate.EXACT_SCORE) ||
      !isMatchFinished
    ) {
      return 0;
    }

    return calculateExactScorePoint(
      userPick,
      {
        homeTeamPoints: eventValueScore1,
        awayTeamPoints: eventValueScore2,
      },
      (isHistory && userPick?.booster) || isBoosted ? matchInfo?.point * 2 : matchInfo?.point
    );
  }, [isMatchFinished, userPick, eventInfo, type]);

  const [titleTranslation] = useMemo(() => {
    if (
      type === MatchPredictionTemplate.HT_EXACT_SCORE ||
      type === MatchPredictionTemplate.HT_PERFECT_SCORE
    ) {
      return [t("exactScore.halfTimeQuestion")];
    }
    if (
      type === MatchPredictionTemplate.EXACT_SCORE ||
      type === MatchPredictionTemplate.PERFECT_SCORE
    ) {
      return [t("exactScore.question")];
    }
    return [t("exactScore.question")];
  }, [eventInfo.status]);

  const matchStatus = useMemo(() => {
    if (!isHistory || !userPick) return null;
    if (!isMatchFinished || userPick.correct === null) return "inProgress";
    return userPick.correct ? "correct" : "incorrect";
  }, [isHistory, isMatchFinished, userPick]);

  return (
    <WidgetCard
      headerInfo={{
        matchDate: matchInfo.matchTime,
        userPick,
        point:
          (isHistory && userPick?.booster) || isBoosted ? matchInfo?.point * 2 : matchInfo?.point,
        question: titleTranslation,
        isHistory,
        isMatchFinished,
        exactScoreDisplay:
          type === MatchPredictionTemplate.EXACT_SCORE ||
          type === MatchPredictionTemplate.HT_EXACT_SCORE
            ? matchInfo?.propId
            : "",
        exactScorePoint:
          type === MatchPredictionTemplate.EXACT_SCORE ||
          type === MatchPredictionTemplate.HT_EXACT_SCORE
            ? exactScorePoints
            : undefined,
      }}
      editable={editable}
      isBooster={isBooster}
      isBoosted={isHistory && userPick?.booster ? true : isBoosted}
      toggleBooster={toggleBooster}
      matchStatus={matchStatus}
    >
      <div className={`${styles.matchBet} ${isHistory ? styles.historyWrap : ""}`}>
        <div className={`${styles.matchBet__team}`}>
          <p
            data-tooltip-id="homeTeamName"
            data-tooltip-content={eventInfo?.homeTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.homeTeamName || "unknown"}
          </p>
          <NameTooltip id="homeTeamName" />
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.homeTeamId}.png`} />
          </div>
        </div>
        <div className={styles.exactScoreCenter}>
          {(() => {
            switch (weekStatus) {
              case WeekStatus.completed:
              case WeekStatus.live:
                return (
                  <div className={styles.predictionsSection}>
                    {userPick && Object.keys(userPick).length > 0 && (
                      <div className={styles.prediction}>
                        <p className={styles.predictionTitle}>{t("exactScore.prediction")}</p>
                        <div
                          className={`${!isMatchFinished || userPick?.correct === null ? styles.inProgress : ""} 
                          ${isMatchFinished && userPick?.correct === true ? styles.correctPrediction : ""}
                          ${isMatchFinished && userPick?.correct === false ? styles.wrongPrediction : ""}`}
                        >
                          <p className={styles.scoreCounter__btn}>{scores.team1Score}</p>
                          <span>:</span>
                          <p className={styles.scoreCounter__btn}>{scores.team2Score}</p>
                        </div>
                      </div>
                    )}

                    <div className={styles.prediction}>
                      <p className={styles.predictionTitle}>{t("exactScore.finalScore")}</p>
                      <div>
                        <p className={styles.scoreCounter__btn}>{eventValueScore1 ?? "-"}</p>
                        <span>:</span>
                        <p className={styles.scoreCounter__btn}>{eventValueScore2 ?? "-"}</p>
                      </div>
                    </div>
                  </div>
                );
              case WeekStatus.upcoming:
                return (
                  <div className={styles.matchBet__center}>
                    <div className={styles.dualResult}>
                      <p>vs</p>
                      <span>{formatMatchDate(matchInfo.matchTime)}</span>
                    </div>
                  </div>
                );
              default:
                return (
                  <>
                    <div
                      className={`${styles.scoreCounter} ${choiceMade ? styles.choiceMadeCounter : ""}`}
                    >
                      <span
                        className={styles.scoreCounter__btn}
                        onClick={() => updateScore("team1", true)}
                      >
                        <img src={PlusIcon} />
                      </span>
                      <p>{scores.team1Score}</p>
                      <span
                        className={`${styles.scoreCounter__btn} ${[0, "-"].includes(scores.team1Score) ? styles.disabled : ""} `}
                        onClick={() => updateScore("team1", false)}
                      >
                        <img src={MinusIcon} />
                      </span>
                    </div>
                    <span className={styles.centerDivider}>:</span>
                    <div
                      className={`${styles.scoreCounter} ${choiceMade ? styles.choiceMadeCounter : ""}`}
                    >
                      <span
                        className={styles.scoreCounter__btn}
                        onClick={() => updateScore("team2", true)}
                      >
                        <img src={PlusIcon} />
                      </span>
                      <p>{scores.team2Score}</p>
                      <span
                        className={`${styles.scoreCounter__btn} ${[0, "-"].includes(scores.team2Score) ? styles.disabled : ""} `}
                        onClick={() => updateScore("team2", false)}
                      >
                        <img src={MinusIcon} />
                      </span>
                    </div>
                  </>
                );
            }
          })()}
        </div>
        <div className={`${styles.matchBet__team}`}>
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.awayTeamId}.png`} />
          </div>
          <p
            data-tooltip-id="awayTeamName"
            data-tooltip-content={eventInfo?.awayTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.awayTeamName || "unknown"}
          </p>
          <NameTooltip id="awayTeamName" />
        </div>
      </div>
      {!isHistory && weekStatus !== WeekStatus.upcoming && (
        <div className={styles.matchTime}>
          <div>{formatMatchDate(matchInfo.matchTime)}</div>
        </div>
      )}
    </WidgetCard>
  );
};

export default ExactMatch;
