import { Tooltip } from "react-tooltip";
import styles from "./NameTooltip.module.scss";

type Props = {
  id: string;
};

const NameTooltip = ({ id }: Props) => {
  return <Tooltip className={styles.tooltip} id={id} />;
};

export default NameTooltip;
