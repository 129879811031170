import MainOverview from "../../../components/mainOverview/MainOverview.tsx";

import styles from "./SidebarWidget.module.scss";

type Props = {
  promoData: { startDate: string; endDate: string };
};
const SidebarWidget = ({ promoData }: Props) => {
  return (
    <div className={styles.sidebar}>
      <MainOverview promoData={promoData} />
      {/* <SidebarBanner /> */}
    </div>
  );
};

export default SidebarWidget;
