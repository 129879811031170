type Point = {
  step: number;
  top: string;
  left: string;
};

const points_5_steps: Point[] = [
  { step: 1, top: "25%", left: "40%" },
  { step: 2, top: "39%", left: "80%" },
  { step: 3, top: "54%", left: "32%" },
  { step: 4, top: "76%", left: "50%" },
  { step: 5, top: "92%", left: "46%" },
];

const points_10_steps: Point[] = [
  { step: 1, top: "25%", left: "40%" },
  { step: 2, top: "37%", left: "83%" },
  { step: 3, top: "39%", left: "44%" },
  { step: 4, top: "46%", left: "18%" },
  { step: 5, top: "52%", left: "62%" },
  { step: 6, top: "65%", left: "62%" },
  { step: 7, top: "70%", left: "16%" },
  { step: 8, top: "77%", left: "80%" },
  { step: 9, top: "85%", left: "61%" },
  { step: 10, top: "92%", left: "46%" },
];

const points_15_steps: Point[] = [
  { step: 1, top: "25%", left: "40%" },
  { step: 2, top: "30%", left: "66%" },
  { step: 3, top: "37%", left: "84%" },
  { step: 4, top: "39%", left: "42%" },
  { step: 5, top: "47%", left: "17%" },
  { step: 6, top: "55%", left: "34%" },
  { step: 7, top: "53%", left: "64%" },
  { step: 8, top: "59%", left: "84%" },
  { step: 9, top: "66%", left: "64%" },
  { step: 10, top: "62%", left: "41%" },
  { step: 11, top: "71%", left: "16%" },
  { step: 12, top: "76%", left: "48%" },
  { step: 13, top: "80%", left: "84%" },
  { step: 14, top: "85%", left: "61%" },
  { step: 15, top: "92%", left: "46%" },
];

const points_20_steps: Point[] = [
  { step: 1, top: "16%", left: "37%" },
  { step: 2, top: "17%", left: "64%" },
  { step: 3, top: "22%", left: "83%" },
  { step: 4, top: "24%", left: "42%" },
  { step: 5, top: "28%", left: "15%" },
  { step: 6, top: "31%", left: "64%" },
  { step: 7, top: "38%", left: "43%" },
  { step: 8, top: "42%", left: "15%" },
  { step: 9, top: "49%", left: "85%" },
  { step: 10, top: "51%", left: "59%" },
  { step: 11, top: "55%", left: "16%" },
  { step: 12, top: "62%", left: "84%" },
  { step: 13, top: "63%", left: "48%" },
  { step: 14, top: "70%", left: "63%" },
  { step: 15, top: "75%", left: "83%" },
  { step: 16, top: "78%", left: "63%" },
  { step: 17, top: "82%", left: "16%" },
  { step: 18, top: "85%", left: "61%" },
  { step: 19, top: "88%", left: "84%" },
  { step: 20, top: "94%", left: "38%" },
];

const points_25_steps: Point[] = [
  { step: 1, top: "16%", left: "37%" },
  { step: 2, top: "17%", left: "64%" },
  { step: 3, top: "22%", left: "83%" },
  { step: 4, top: "24%", left: "42%" },
  { step: 5, top: "28%", left: "15%" },
  { step: 6, top: "31%", left: "64%" },
  { step: 7, top: "35%", left: "83%" },
  { step: 8, top: "38%", left: "43%" },
  { step: 9, top: "42%", left: "15%" },
  { step: 10, top: "46%", left: "48%" },
  { step: 11, top: "49%", left: "85%" },
  { step: 12, top: "51%", left: "59%" },
  { step: 13, top: "55%", left: "16%" },
  { step: 14, top: "58%", left: "62%" },
  { step: 15, top: "62%", left: "84%" },
  { step: 16, top: "63%", left: "48%" },
  { step: 17, top: "68%", left: "16%" },
  { step: 18, top: "70%", left: "63%" },
  { step: 19, top: "75%", left: "83%" },
  { step: 20, top: "78%", left: "63%" },
  { step: 21, top: "82%", left: "16%" },
  { step: 22, top: "86%", left: "30%" },
  { step: 23, top: "85%", left: "61%" },
  { step: 24, top: "88%", left: "84%" },
  { step: 25, top: "94%", left: "38%" },
];

const points_30_steps: Point[] = [
  { step: 1, top: "16%", left: "37%" },
  { step: 2, top: "17%", left: "64%" },
  { step: 3, top: "22%", left: "83%" },
  { step: 4, top: "24%", left: "42%" },
  { step: 5, top: "28%", left: "15%" },
  { step: 6, top: "33%", left: "33%" },
  { step: 7, top: "31%", left: "64%" },
  { step: 8, top: "35%", left: "83%" },
  { step: 9, top: "39%", left: "63%" },
  { step: 10, top: "38%", left: "43%" },
  { step: 11, top: "42%", left: "15%" },
  { step: 12, top: "46%", left: "48%" },
  { step: 13, top: "49%", left: "85%" },
  { step: 14, top: "51%", left: "59%" },
  { step: 15, top: "55%", left: "16%" },
  { step: 16, top: "58%", left: "62%" },
  { step: 17, top: "62%", left: "84%" },
  { step: 18, top: "63%", left: "48%" },
  { step: 19, top: "68%", left: "16%" },
  { step: 20, top: "72%", left: "42%" },
  { step: 21, top: "70%", left: "63%" },
  { step: 22, top: "75%", left: "83%" },
  { step: 23, top: "78%", left: "63%" },
  { step: 24, top: "77%", left: "33%" },
  { step: 25, top: "82%", left: "16%" },
  { step: 26, top: "86%", left: "30%" },
  { step: 27, top: "85%", left: "61%" },
  { step: 28, top: "88%", left: "84%" },
  { step: 29, top: "92%", left: "64%" },
  { step: 30, top: "94%", left: "38%" },
];

export function generateMapPoints(stepCount: number): Point[] {
  switch (stepCount) {
    case 5:
      return points_5_steps;
    case 10:
      return points_10_steps;
    case 15:
      return points_15_steps;
    case 20:
      return points_20_steps;
    case 25:
      return points_25_steps;
    case 30:
      return points_30_steps;
    default:
      return points_5_steps;
  }
}
