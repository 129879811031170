import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "@/providers/ConfigProvider";

import { CasinoGameType, CasinoPromoConfig, ProductCategoryEnum } from "../../casino.types";
import { getCasinoGameType } from "../../utils";

import CasinoTimer from "../casinoTimer/CasinoTimer";
import UserAuthPrompt from "../userAuthPrompt/UserAuthPrompt";
import BannerSlider from "@/components/bannerSlider/BannerSlider";

import styles from "./CasinoHeader.module.scss";

import tempLeaderboardBanner from "../../assets/img/tempLeaderboardBannerNew.png";
import lbDarkBanner from "../../assets/img/lbDarkBanner.png";
import tempWheelBanner from "../../assets/img/temp-header-banner.png";

type Props = {
  casinoData: CasinoPromoConfig;
  hasBanner?: boolean;
};

const CasinoHeader = ({ casinoData, hasBanner = false }: Props) => {
  const promoConfig = useContext<any>(ConfigContext);
  const { i18n, t } = useTranslation();
  if (!casinoData) return null;

  const mockBanner = useMemo(() => {
    if (casinoData.category.includes("WHEEL")) {
      return [
        {
          uri: tempWheelBanner,
        },
      ];
    } else {
      return promoConfig?.components?.banner?.[i18n.language] ?? [];
    }
  }, []);

  const getCasinoTimerTitleText = (casinoGameType: CasinoGameType | null): string => {
    switch (casinoGameType) {
      case CasinoGameType.AdventureMap:
        return t("adventureMap.timerEnd");
      case CasinoGameType.Matrix:
        return t("matrix.timerEnd");

      default:
        return "";
    }
  };

  return (
    <div className={styles.casinoHeader}>
      {hasBanner && (
        <div className={styles.banner}>
          <BannerSlider banners={mockBanner} />
        </div>
      )}
      <h2 className={styles.title}>{t("title")}</h2>
      <h6 className={styles.description}>{t("description")}</h6>
      <CasinoTimer
        endDate={casinoData.endDate}
        className={styles.timer}
        title={getCasinoTimerTitleText(getCasinoGameType(casinoData.category))}
      />
      <div className={styles.authPrompt}>
        <UserAuthPrompt hasOptin={casinoData.optIn} status={casinoData.status} />
      </div>
    </div>
  );
};

export default CasinoHeader;
