import httpClient, { PromofyAuthHeaders } from "@/api/httpClient";
import { AuthContextProps } from "@/providers/AuthProvider";
import { useQuery } from "@tanstack/react-query";

export const loadLobby = (context: AuthContextProps) => {
  return useQuery({
    queryKey: ["lobbyData"],
    queryFn: () => httpClient.get(`/lobby`, { headers: { ...PromofyAuthHeaders(context) } }),
    select: (res) => res.data,
  });
};

export const loadLobbyWithUser = (context: AuthContextProps) => {
  return useQuery({
    queryKey: ["lobbyUserData"],
    queryFn: () =>
      httpClient.get(`/lobby/user/enrollment`, { headers: { ...PromofyAuthHeaders(context) } }),
    select: (res) => res.data,
    enabled: context.isLoggedIn,
  });
};
