export enum MatchStatus {
  pending = "pending",
  postponed = "postponed",
  cancelled = "cancelled",
}

export enum MatchPredictionTemplate {
  MIXED = "MIXED",
  FT_1X2 = "FT_1X2",
  HT_1X2 = "HT_1X2",
  EXACT_SCORE = "EXACT_SCORE",
  HT_EXACT_SCORE = "HT_EXACT_SCORE",
  PERFECT_SCORE = "PERFECT_SCORE",
  HT_PERFECT_SCORE = "HT_PERFECT_SCORE",
  DOUBLE_CHANCE = "DOUBLE_CHANCE",
  BOTH_TEAMS_SCORE = "BOTH_TEAMS_SCORE",
  YELLOW_CARD_IN_MATCH = "YELLOW_CARD_IN_MATCH",
  RED_CARD_IN_MATCH = "RED_CARD_IN_MATCH",
  OVER_FOULS_10_5 = "OVER_FOULS_10_5",
  OVER_FOULS_11_5 = "OVER_FOULS_11_5",
  OVER_FOULS_12_5 = "OVER_FOULS_12_5",
  OVER_FOULS_13_5 = "OVER_FOULS_13_5",
  OVER_FOULS_14_5 = "OVER_FOULS_14_5",
  OVER_FOULS_15_5 = "OVER_FOULS_15_5",
  OVER_FOULS_16_5 = "OVER_FOULS_16_5",
  OVER_FOULS_17_5 = "OVER_FOULS_17_5",
  OVER_FOULS_18_5 = "OVER_FOULS_18_5",
  OVER_FOULS_19_5 = "OVER_FOULS_19_5",
  OVER_FOULS_20_5 = "OVER_FOULS_20_5",
  OVER_FOULS_21_5 = "OVER_FOULS_21_5",
  OVER_FOULS_22_5 = "OVER_FOULS_22_5",
  OVER_FOULS_23_5 = "OVER_FOULS_23_5",
  OVER_FOULS_24_5 = "OVER_FOULS_24_5",
  OVER_FOULS_25_5 = "OVER_FOULS_25_5",
  OVER_FOULS_26_5 = "OVER_FOULS_26_5",
  OVER_FOULS_27_5 = "OVER_FOULS_27_5",
  OVER_FOULS_28_5 = "OVER_FOULS_28_5",
  OVER_FOULS_29_5 = "OVER_FOULS_29_5",
  OVER_FOULS_30_5 = "OVER_FOULS_30_5",
  OVER_GOALS_0_5 = "OVER_GOALS_0_5",
  OVER_GOALS_1_5 = "OVER_GOALS_1_5",
  OVER_GOALS_2_5 = "OVER_GOALS_2_5",
  OVER_GOALS_3_5 = "OVER_GOALS_3_5",
  OVER_GOALS_4_5 = "OVER_GOALS_4_5",
  OVER_GOALS_5_5 = "OVER_GOALS_5_5",
  OVER_GOALS_6_5 = "OVER_GOALS_6_5",
  OVER_CORNERS_6_5 = "OVER_CORNERS_6_5",
  OVER_CORNERS_7_5 = "OVER_CORNERS_7_5",
  OVER_CORNERS_8_5 = "OVER_CORNERS_8_5",
  OVER_CORNERS_9_5 = "OVER_CORNERS_9_5",
  OVER_CORNERS_10_5 = "OVER_CORNERS_10_5",
  OVER_CORNERS_11_5 = "OVER_CORNERS_11_5",
  OVER_CORNERS_12_5 = "OVER_CORNERS_12_5",
  OVER_CORNERS_13_5 = "OVER_CORNERS_13_5",
  OVER_YELLOW_CARDS_0_5 = "OVER_YELLOW_CARDS_0_5",
  OVER_YELLOW_CARDS_1_5 = "OVER_YELLOW_CARDS_1_5",
  OVER_YELLOW_CARDS_2_5 = "OVER_YELLOW_CARDS_2_5",
  OVER_YELLOW_CARDS_3_5 = "OVER_YELLOW_CARDS_3_5",
  OVER_YELLOW_CARDS_4_5 = "OVER_YELLOW_CARDS_4_5",
  OVER_YELLOW_CARDS_5_5 = "OVER_YELLOW_CARDS_5_5",
  OVER_YELLOW_CARDS_6_5 = "OVER_YELLOW_CARDS_6_5",
  OVER_YELLOW_CARDS_7_5 = "OVER_YELLOW_CARDS_7_5",
  OVER_YELLOW_CARDS_8_5 = "OVER_YELLOW_CARDS_8_5",
  OVER_YELLOW_CARDS_9_5 = "OVER_YELLOW_CARDS_9_5",
  TOTAL_OFFSIDES = "TOTAL_OFFSIDES",
  TOTAL_SAVES = "TOTAL_SAVES",
  PLAYER_TO_SCORE = "PLAYER_TO_SCORE",
  PLAYER_TO_SCORE_FIRST = "PLAYER_TO_SCORE_FIRST",
  PLAYER_SHOTS_TOTAL = "PLAYER_SHOTS_TOTAL",
  PLAYER_ASSISTS = "PLAYER_ASSISTS",
  PLAYER_FOULS_COMMITTED = "PLAYER_FOULS_COMMITTED",
  PLAYER_FOULS_DRAWN = "PLAYER_FOULS_DRAWN",
  PLAYER_DRIBBLE_SUCCESS = "PLAYER_DRIBBLE_SUCCESS",
  PLAYER_SHOTS_ON_GOAL = "PLAYER_SHOTS_ON_GOAL",
  TOTAL_OFFSIDES_OVER_0_5 = "TOTAL_OFFSIDES_OVER_0_5",
  TOTAL_OFFSIDES_OVER_1_5 = "TOTAL_OFFSIDES_OVER_1_5",
  TOTAL_OFFSIDES_OVER_2_5 = "TOTAL_OFFSIDES_OVER_2_5",
  TOTAL_OFFSIDES_OVER_3_5 = "TOTAL_OFFSIDES_OVER_3_5",
  TOTAL_OFFSIDES_OVER_4_5 = "TOTAL_OFFSIDES_OVER_4_5",
  TOTAL_SAVES_OVER_5_5 = "TOTAL_SAVES_OVER_5_5",
  TOTAL_SAVES_OVER_6_5 = "TOTAL_SAVES_OVER_6_5",
  TOTAL_SAVES_OVER_7_5 = "TOTAL_SAVES_OVER_7_5",
  TOTAL_SAVES_OVER_8_5 = "TOTAL_SAVES_OVER_8_5",
  TOTAL_SAVES_OVER_9_5 = "TOTAL_SAVES_OVER_9_5",
  TOTAL_SAVES_OVER_10_5 = "TOTAL_SAVES_OVER_10_5",
  TOTAL_SAVES_OVER_11_5 = "TOTAL_SAVES_OVER_11_5",
  TOTAL_SAVES_OVER_12_5 = "TOTAL_SAVES_OVER_12_5",
  TOTAL_SHOTS_ON_GOAL_OVER_5_5 = "TOTAL_SHOTS_ON_GOAL_OVER_5_5",
  TOTAL_SHOTS_ON_GOAL_OVER_6_5 = "TOTAL_SHOTS_ON_GOAL_OVER_6_5",
  TOTAL_SHOTS_ON_GOAL_OVER_7_5 = "TOTAL_SHOTS_ON_GOAL_OVER_7_5",
  TOTAL_SHOTS_ON_GOAL_OVER_8_5 = "TOTAL_SHOTS_ON_GOAL_OVER_8_5",
  TOTAL_SHOTS_ON_GOAL_OVER_9_5 = "TOTAL_SHOTS_ON_GOAL_OVER_9_5",
  TOTAL_SHOTS_ON_GOAL_OVER_10_5 = "TOTAL_SHOTS_ON_GOAL_OVER_10_5",
  TOTAL_SHOTS_ON_GOAL_OVER_11_5 = "TOTAL_SHOTS_ON_GOAL_OVER_11_5",
  TOTAL_SHOTS_ON_GOAL_OVER_12_5 = "TOTAL_SHOTS_ON_GOAL_OVER_12_5",
  TOTAL_SHOTS_ON_GOAL_OVER_13_5 = "TOTAL_SHOTS_ON_GOAL_OVER_13_5",
  TOTAL_SHOTS_ON_GOAL_OVER_14_5 = "TOTAL_SHOTS_ON_GOAL_OVER_14_5",
  TOTAL_SHOTS_ON_GOAL_OVER_15_5 = "TOTAL_SHOTS_ON_GOAL_OVER_15_5",
  PLAYER_SHOTS_TOTAL_OVER_1_5 = "PLAYER_SHOTS_TOTAL_OVER_1_5",
  PLAYER_SHOTS_TOTAL_OVER_2_5 = "PLAYER_SHOTS_TOTAL_OVER_2_5",
  PLAYER_SHOTS_TOTAL_OVER_3_5 = "PLAYER_SHOTS_TOTAL_OVER_3_5",
  PLAYER_SHOTS_TOTAL_OVER_4_5 = "PLAYER_SHOTS_TOTAL_OVER_4_5",
  PLAYER_SHOTS_TOTAL_OVER_5_5 = "PLAYER_SHOTS_TOTAL_OVER_5_5",
  PLAYER_SHOTS_TOTAL_OVER_6_5 = "PLAYER_SHOTS_TOTAL_OVER_6_5",
  PLAYER_SHOTS_TOTAL_OVER_7_5 = "PLAYER_SHOTS_TOTAL_OVER_7_5",
  PLAYER_SHOTS_TOTAL_OVER_8_5 = "PLAYER_SHOTS_TOTAL_OVER_8_5",
  PLAYER_SHOTS_TOTAL_OVER_9_5 = "PLAYER_SHOTS_TOTAL_OVER_9_5",
  PLAYER_SHOTS_TOTAL_OVER_10_5 = "PLAYER_SHOTS_TOTAL_OVER_10_5",
  PLAYER_ASSISTS_OVER_0_5 = "PLAYER_ASSISTS_OVER_0_5",
  PLAYER_ASSISTS_OVER_1_5 = "PLAYER_ASSISTS_OVER_1_5",
  PLAYER_ASSISTS_OVER_2_5 = "PLAYER_ASSISTS_OVER_2_5",
  PLAYER_ASSISTS_OVER_3_5 = "PLAYER_ASSISTS_OVER_3_5",
  PLAYER_FOULS_COMMITTED_OVER_0_5 = "PLAYER_FOULS_COMMITTED_OVER_0_5",
  PLAYER_FOULS_COMMITTED_OVER_1_5 = "PLAYER_FOULS_COMMITTED_OVER_1_5",
  PLAYER_FOULS_COMMITTED_OVER_2_5 = "PLAYER_FOULS_COMMITTED_OVER_2_5",
  PLAYER_FOULS_COMMITTED_OVER_3_5 = "PLAYER_FOULS_COMMITTED_OVER_3_5",
  PLAYER_FOULS_COMMITTED_OVER_4_5 = "PLAYER_FOULS_COMMITTED_OVER_4_5",
  PLAYER_FOULS_DRAWN_OVER_0_5 = "PLAYER_FOULS_DRAWN_OVER_0_5",
  PLAYER_FOULS_DRAWN_OVER_1_5 = "PLAYER_FOULS_DRAWN_OVER_1_5",
  PLAYER_FOULS_DRAWN_OVER_2_5 = "PLAYER_FOULS_DRAWN_OVER_2_5",
  PLAYER_FOULS_DRAWN_OVER_3_5 = "PLAYER_FOULS_DRAWN_OVER_3_5",
  PLAYER_FOULS_DRAWN_OVER_4_5 = "PLAYER_FOULS_DRAWN_OVER_4_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_0_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_0_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_1_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_1_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_2_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_2_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_3_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_3_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_4_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_4_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_5_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_5_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_6_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_6_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_7_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_7_5",
  PLAYER_DRIBBLE_SUCCESS_OVER_8_5 = "PLAYER_DRIBBLE_SUCCESS_OVER_8_5",
  PLAYER_SHOTS_ON_GOAL_OVER_0_5 = "PLAYER_SHOTS_ON_GOAL_OVER_0_5",
  PLAYER_SHOTS_ON_GOAL_OVER_1_5 = "PLAYER_SHOTS_ON_GOAL_OVER_1_5",
  PLAYER_SHOTS_ON_GOAL_OVER_2_5 = "PLAYER_SHOTS_ON_GOAL_OVER_2_5",
  PLAYER_SHOTS_ON_GOAL_OVER_3_5 = "PLAYER_SHOTS_ON_GOAL_OVER_3_5",
  PLAYER_GOALS_OVER_0_5 = "PLAYER_GOALS_OVER_0_5",
  PLAYER_GOALS_OVER_1_5 = "PLAYER_GOALS_OVER_1_5",
  PLAYER_GOALS_OVER_2_5 = "PLAYER_GOALS_OVER_2_5",
  PLAYER_GOALS_OVER_3_5 = "PLAYER_GOALS_OVER_3_5",
  PLAYER_GOALS_OVER_4_5 = "PLAYER_GOALS_OVER_4_5",
  PLAYER_SAVES_OVER_0_5 = "PLAYER_SAVES_OVER_0_5",
  PLAYER_SAVES_OVER_1_5 = "PLAYER_SAVES_OVER_1_5",
  PLAYER_SAVES_OVER_2_5 = "PLAYER_SAVES_OVER_2_5",
  PLAYER_SAVES_OVER_3_5 = "PLAYER_SAVES_OVER_3_5",
  PLAYER_SAVES_OVER_4_5 = "PLAYER_SAVES_OVER_4_5",
  PLAYER_SAVES_OVER_5_5 = "PLAYER_SAVES_OVER_5_5",
  PLAYER_SAVES_OVER_6_5 = "PLAYER_SAVES_OVER_6_5",
  PLAYER_SAVES_OVER_7_5 = "PLAYER_SAVES_OVER_7_5",
  PLAYER_SAVES_OVER_8_5 = "PLAYER_SAVES_OVER_8_5",
  PLAYER_SAVES_OVER_9_5 = "PLAYER_SAVES_OVER_9_5",
  PLAYER_SAVES_OVER_10_5 = "PLAYER_SAVES_OVER_10_5",
  PLAYER_SAVES_OVER_11_5 = "PLAYER_SAVES_OVER_11_5",
  PLAYER_SAVES_OVER_12_5 = "PLAYER_SAVES_OVER_12_5",
  PLAYER_GOALSCORE = "PLAYER_GOALSCORE",
  PLAYER_GOAL_OR_ASSIST = "PLAYER_GOAL_OR_ASSIST",

  MORE_CORNERS = "MORE_CORNERS",
  MORE_OFFSIDES = "MORE_OFFSIDES",
  TOTAL_GOALS_EVEN_OR_ODD = "TOTAL_GOALS_EVEN_OR_ODD",
  TOTAL_OFFSIDES_EVEN_OR_ODD = "TOTAL_OFFSIDES_EVEN_OR_ODD",
  TOTAL_CORNERS_EVEN_OR_ODD = "TOTAL_CORNERS_EVEN_OR_ODD",
}
