import httpClient, { PromofyAuthHeaders } from "@/api/httpClient.ts";
import ConfigService from "@/services/ConfigService.ts";
import { getSoccerTeamNames } from "@/services/TeamLangService.ts";
import { useQuery } from "@tanstack/react-query";
import { LeaderBoardType } from "./pickem.types.ts";
import { AuthContextProps } from "@/providers/AuthProvider.tsx";

export const loadPickem = () => {
  const promoId = ConfigService.getPromoId();
  return useQuery({
    queryKey: ["pickemData"],
    queryFn: () => httpClient.get(`/promos/${promoId}/pickems`),
    select: (pickemData) => pickemData.data,
  });
};

export const loadSoccerTeamNames = () => {
  return useQuery({
    queryKey: [`teamNames`],
    queryFn: () => getSoccerTeamNames(),
    select: (teamNames) => teamNames.data,
  });
};

export const loadUserPicks = (context: AuthContextProps) => {
  const promoId = ConfigService.getPromoId();
  return useQuery({
    queryKey: [`userPickData-${context.getCurrentUser?.id}`],
    queryFn: () =>
      httpClient.get(`promos/${promoId}/pickems/users/picks`, {
        headers: {
          ...PromofyAuthHeaders(context),
        },
      }),
    enabled: context.isLoggedIn,
    select: (response) => response.data,
  });
};

export const picksByRoundId = (context: AuthContextProps, roundId: string) => {
  const promoId = ConfigService.getPromoId();
  return useQuery({
    queryKey: [`picksByRound-${promoId}-${roundId}`],
    queryFn: () => httpClient.get(`promos/${promoId}/pickems/rounds/${roundId}`),
    enabled: context.isLoggedIn,
    select: (response) => response.data,
  });
};

export const loadPropsByGameWeekId = (gameWeekId: string) => {
  const promoId = ConfigService.getPromoId();
  return useQuery({
    queryKey: [`roundData-${promoId}-${gameWeekId}`],
    queryFn: () => httpClient.get(`promos/${promoId}/pickems/rounds/${gameWeekId}`),
    select: (response) => response.data,
  });
};

export const saveUserPicks = async (params: {
  promoId: string;
  selectedOptions: {};
  context: AuthContextProps;
}) => {
  return await httpClient.post(
    `/promos/${params.promoId}/pickems/users/picks`,
    {
      selected: params.selectedOptions,
    },
    {
      headers: {
        ...PromofyAuthHeaders(params.context),
      },
    }
  );
};

export const loadLeaderboard = (props: {
  limit: number;
  type: LeaderBoardType;
  partitionKey?: string;
  enabled?: boolean;
}) => {
  const promoId = ConfigService.getPromoId();
  let url = ``;
  switch (props.type) {
    case LeaderBoardType.OVERALL:
    case LeaderBoardType.TIMEBOUND:
      url = `/promos/${promoId}/leaderboards/${props.type}/top/${props.limit}`;
      break;
    default:
      url = `/promos/${promoId}/leaderboards/${props.type}/${props.partitionKey}/top/${props.limit}`;
      break;
  }
  return useQuery({
    queryKey: [`leaderboardData-${promoId}-${props.partitionKey}`],
    queryFn: () => httpClient.get(`${url}`),
    select: (leaderboardData) => leaderboardData.data,
    enabled: props.enabled !== false,
  });
};

export const loadLeaderboardUser = (props: {
  type: LeaderBoardType;
  partitionKey?: string;
  playerId?: string;
  context: AuthContextProps;
  enabled: boolean;
}) => {
  const promoId = ConfigService.getPromoId();

  let url = ``;
  switch (props.type) {
    case LeaderBoardType.OVERALL:
      url = `/promos/${promoId}/leaderboards/${props.type}/players/${props.playerId}`;
      break;
    default:
      url = `/promos/${promoId}/leaderboards/${props.type}/${props.partitionKey}/players/${props.playerId}`;
  }
  return useQuery({
    queryKey: [`leaderboardData-${promoId}-${props.partitionKey}-${props.playerId}`],
    queryFn: () => httpClient.get(`${url}`),
    select: (leaderboardData) => leaderboardData.data,
    enabled: props.enabled && props.context.isLoggedIn,
  });
};

export const loadExactGuessScores = (props: {
  type: LeaderBoardType;
  partitionKey?: string;
  minimumScore?: string;
}) => {
  const promoId = ConfigService.getPromoId();

  let url = `/promos/${promoId}/pickems/exact-guess/info`;

  return useQuery({
    queryKey: [`exactGuessScores-${promoId}-${props.partitionKey}-${props.minimumScore}`],
    queryFn: () =>
      httpClient.post(url, {
        week: props.partitionKey,
        guess: props.minimumScore,
      }),
    select: (response) => response.data,
  });
};
