import styles from "./ModalPassthrough.module.scss";

type Props = {
  children: React.ReactNode;
  modalClass?: string;
};

const ModalPassthrough = (props: Props) => {
  return (
    <div className={styles.blinds}>
      <div className={`${styles.modal} ${props.modalClass || ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export default ModalPassthrough;
