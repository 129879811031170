export enum LeaderBoardType {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
  ROUND = "ROUND",
  TIMEBOUND = "TIMEBOUND",
  OVERALL = "OVERALL",
}

export enum PickemPeriodType {
  DAILY = "DAILY",
  ROUND = "ROUND",
  WEEKLY = "WEEKLY",
  CUSTOM = "CUSTOM",
  OVERALL = "OVERALL",
  TIMEBOUND = "TIMEBOUND",
}

export enum WeekStatus {
  none = "none",
  live = "live",
  completed = "completed",
  unlocked = "unlocked",
  upcoming = "upcoming",
}

export enum PromotionStatusEnum {
  Active = "ACTIVE",
  Completed = "COMPLETED",
  Draft = "DRAFT",
}

export enum QuestionType {
  team_vs_team = "team_vs_team",
  team_vs_fixture = "team_vs_fixture",
  tie_breaker = "tie_breaker",
}

export enum AditionalQuestionEnum {
  HOW_MANY_GOALS = "how_many_goals",
  HOW_MANY_YELLOW = "how_many_yellow",
  NONE = "none",
}

export interface WeekPool {
  uuid: string;
  week: string;
  endDate: string;
  startDate: string;
  unlockDate: string;
  status: WeekStatus;
  pickemId: number;
  props?: PickemProp[];
}

export interface Pickem {
  booster: boolean;
  id: number;
  name: string;
  slug: string;
  leagueId: string;
  promoRef: string;
  category: string;
  baseQuestionTemplate: string;
  additionalQuestionTemplate: string;
  startDate: string;
  endDate: string;
  status: PromotionStatusEnum;
  periodType: any;
  periodValue: any;
  weekPools: WeekPool[];
  error?: any;
}

export interface GameOption {
  id: number;
  label: string;
  pickPercentage: string;
  object: any; // You might want to replace 'any' with a more specific type based on your actual object structure
}

export enum MatchStatus {
  pending = "pending",
}

export interface EventDTO {
  matchId: string;
  leagueId: string;
  stageId: string;
  awayTeamName: string;
  awayTeamId: string;
  awayTeamPoints: number | null;
  homeTeamPoints: number | null;
  homeTeamName: string;
  homeTeamId: string;
  status: MatchStatus;
  startsAt: Date | string;
  updatedAt?: Date | string;
}

export interface PickemProp {
  correctOptionId: number | null;
  createdAt: string;
  disabled: boolean;
  game_week_id: string;
  locked: boolean;
  locksAt: string | null;
  locksManually: boolean;
  matchTime: string | null;
  options?: GameOption[];
  propId: number;
  questionTemplate: string;
  questionType: QuestionType;
  revealsAt: string | null;
  sequence: number;
  event: EventDTO | null;
}

export interface Picks {
  propId: number | null;
  optionId: number | null;
  value: string | null;
  correct: boolean | null | undefined;
  createdAt: string; // Assuming createdAt is a string representing a timestamp
  booster?: boolean;
}

export interface UserPicks {
  user?: {
    name: string;
  };
  picksByGameRound?: {
    [gameRoundId: string]: Picks[];
  };
}

export const getNumberOfPicks = (gameRoundId: string, userPicks?: UserPicks) => {
  if (!userPicks) {
    return 0;
  }
  const picksForGameRound = userPicks?.picksByGameRound?.[gameRoundId];
  if (picksForGameRound) {
    return picksForGameRound.length;
  }
  return 0;
};

export const findPicksByPropId = (userPicks: Picks[], propIdToFind: number): Picks | undefined => {
  if (!userPicks) {
    return undefined;
  }
  const foundPicks = userPicks.find((picks) => picks.propId === propIdToFind);
  if (foundPicks) {
    return foundPicks;
  }

  return undefined;
};
