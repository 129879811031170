import { useTranslation } from "react-i18next";

import styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.error}>
      <h1>{t("pageNotFound.h1")}</h1>
      <h6>{t("pageNotFound.h6")}</h6>
    </div>
  );
};

export default PageNotFound;
