import { PromotionStatusEnum } from "@/widgets/pickemWidget/pickem.types";
import { useTranslation } from "react-i18next";
import { PickemEvent } from "../../types";

import Timer from "@/components/timer/Timer";

import styles from "./LobbyCard.module.scss";

import defaultBannerImage from "../../assets/img/defaulBannerImg.svg";
import basketBall from "../../assets/img/basketballIcon.svg";
import soccerBall from "../../assets/img/soccerIcon.svg";
import clockIcon from "../../assets/img/clockIcon.svg";

type Props = {
  promoData: PickemEvent;
  userJoined: string | undefined;
};

const sportsImages: any = {
  BASKETBALL: basketBall,
  SOCCER: soccerBall,
};

const modifyUrl = (
  originalUrl: string,
  newBase: string,
  searchParamName: string,
  searchParamValue: string
) => {
  const url = new URL(originalUrl, window.location.origin);
  url.pathname = newBase;
  url.searchParams.append(searchParamName, searchParamValue);
  return url.toString();
};

const LobbyCard = ({ promoData, userJoined }: Props) => {
  const { t, i18n } = useTranslation();
  const isPromoCompleted =
    PromotionStatusEnum.Completed === promoData.status || promoData.weekPool === null;
  const pickemURL = modifyUrl(location.href, "/f2p/game/pickem", "promoId", promoData.promoRef);

  const JoinBefore = ({ text }: { text: string }) => {
    return (
      <div>
        <p>{text}</p>
      </div>
    );
  };

  const translatedCategory = () => {
    if (promoData.category) {
      return `${t("lobby.categorySoccer")}`;
    }
  };

  return (
    <div
      className={styles.card}
      onClick={() => {
        window.location.href = pickemURL;
      }}
    >
      <div className={styles.card__top}>
        <div className={styles.banner}>
          <img
            src={promoData.translations[i18n.language]?.banner || defaultBannerImage}
            // className={styles.banner}
          />
          {/* <div className={`${styles.tag} ${styles.absolute}`}>
            <img src={sportsImages[promoData.category]} />
            <p>{translatedCategory()}</p>
          </div> */}
          {/* <div className={`${styles.users} ${userJoined ? styles.joined : ""}`}>
            <img src={peopleIcon} />
            <p>
              {userJoined && `${t("lobby.joined")} `}
              {promoData.participants}
            </p>
          </div> */}
        </div>
        <div className={styles.countDown}>
          {promoData.weekPool && promoData.weekPool.status !== "completed" ? (
            promoData.weekPool.status === "unlocked" ? (
              <Timer
                titleBox={<JoinBefore text={t("lobby.joinBefore")} />}
                endDate={promoData.weekPool.startDate}
                className={styles.timer}
              />
            ) : (
              <Timer
                titleBox={<JoinBefore text={t("lobby.unlocksAt")} />}
                endDate={promoData.weekPool.unlockDate}
                className={styles.timer}
              />
            )
          ) : (
            <div className={styles.finished}>
              <img src={clockIcon} />
              <p>{t("lobby.finished")}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.card__bottom}>
        <div className={styles.content}>
          <h2>{promoData.translations[i18n.language]?.title}</h2>
        </div>
        {/* <div className={styles.footer}>
          <div className={`${styles.tag}`}>
            <img src={sportsImages[promoData.category]} />
            <p>{translatedCategory()}</p>
          </div>
          <div className={styles.playNow}>
            <button
              onClick={() => {
                window.location.href = pickemURL;
              }}
            >
              {isPromoCompleted ? t("lobby.buttons.results") : t("lobby.buttons.playNow")}
              <img src={arrowRight} />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LobbyCard;
