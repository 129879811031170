import { splitMonthAndNumber } from "@/components/utils/translateWeeks";
import { Pickem, PickemPeriodType } from "@/widgets/pickemWidget/pickem.types";
import { useTranslation } from "react-i18next";

function useWeekTranslations() {
  const { t } = useTranslation();

  function translateDaily(week: string) {
    const result = splitMonthAndNumber(week);
    if (result !== null) {
      const [month, number] = result;
      return t(`pickemWidget.months.${month}`) + ` ${number}`;
    }
    return week;
  }

  function translateWeekly(week: string) {
    let [firstDay, lastDay] = week.split("-");

    const firstDayResult = splitMonthAndNumber(firstDay);
    const lastDayResult = splitMonthAndNumber(lastDay);
    if (firstDayResult !== null && lastDayResult !== null) {
      const [firstMonth, firstNumber] = firstDayResult;
      const [lastMonth, lastNumber] = lastDayResult;
      return (
        t(`pickemWidget.months.${firstMonth}`) +
        ` ${firstNumber}  -  ` +
        t(`pickemWidget.months.${lastMonth}`) +
        ` ${lastNumber}`
      );
    }
    return week;
  }

  function weekLabelsTransformer(pickemData: Pickem) {
    switch (pickemData?.periodType) {
      case PickemPeriodType.ROUND:
        return {
          ...pickemData,
          weekPools: pickemData.weekPools.map((weekPool: any, index: number) => ({
            ...weekPool,
            week: t("pickemWidget.round") + ` ${index + 1}`,
          })),
        };
      case PickemPeriodType.DAILY:
        return {
          ...pickemData,
          weekPools: pickemData.weekPools.map((weekPool: any) => ({
            ...weekPool,
            week: translateDaily(weekPool.week),
          })),
        };
      case PickemPeriodType.WEEKLY:
      case PickemPeriodType.OVERALL:
      case PickemPeriodType.TIMEBOUND:
        return {
          ...pickemData,
          weekPools: pickemData.weekPools.map((weekPool: any) => ({
            ...weekPool,
            week: translateWeekly(weekPool.week),
          })),
        };
      default:
        return pickemData;
    }
  }

  return { translateDaily, translateWeekly, weekLabelsTransformer };
}

export default useWeekTranslations;
