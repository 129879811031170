import React from "react";

interface ArrowProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  direction?: "left" | "right" | "top" | "down";
  className?: string;
}

const Arrow: React.FC<ArrowProps> = ({
  color = "white",
  direction = "down",
  strokeWidth = 2,
  className = "",
  ...props
}) => {
  let rotationClass = "";

  switch (direction) {
    case "left":
      rotationClass = "rotate-90";
      break;
    case "right":
      rotationClass = "rotate-neg-90";
      break;
    case "top":
      rotationClass = "rotate-180";
      break;
    default:
      rotationClass = "";
  }

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rotationClass} ${className}`}
      {...props}
    >
      <g id="chevron-down">
        <path
          id="Icon"
          d="M6 9L12 15L18 9"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Arrow;
