import { useTranslation } from "react-i18next";

import styles from "./Auth.module.scss";

import warning from "./assets/img/warning.svg";
import { PostUserSignInMsg } from "@/services/PostMessageService.ts";

const handleSignIn = () => {
  window.parent.postMessage(PostUserSignInMsg(), "*");
};

const Auth = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.auth}>
      <div className={styles.auth__content}>
        <div className={styles.auth__message}>
          <img src={warning} alt="" />
          <p>{t("auth.main")}</p>
        </div>
        <button onClick={handleSignIn}>{t("auth.button")}</button>
      </div>
    </div>
  );
};

export default Auth;
