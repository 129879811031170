import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./GoalScorers.module.scss";
import ConfigService from "@/services/ConfigService";

import { useTranslation } from "react-i18next";

interface Player {
  id: string;
  name: string;
  position: string;
  teamId: string;
  teamName: string;
}

interface Team {
  players: Player[];
}

interface ChoosePlayerProps {
  matchInfo: any;
  close: () => void;
  chosenPlayer: { name: string; id: string };
  choosePlayer: (player: { name: string; id: string }) => void;
  teams: Team[];
}

const ChoosePlayer = ({
  matchInfo,
  close,
  chosenPlayer,
  choosePlayer,
  teams,
}: ChoosePlayerProps) => {
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const [selectedPlayer, setSelectedPlayer] = useState<{ name: string; id: string }>(chosenPlayer);
  const [selectedTeam, setSelectedTeam] = useState<number>(eventInfo?.homeTeamId);
  const { t } = useTranslation();

  const handlePlayerChange = (playerName: string, playerId: string) => {
    setSelectedPlayer({ name: playerName, id: playerId });
  };

  const handleTeamChange = (teamId: number) => {
    setSelectedTeam(teamId);
  };

  const currentTeam = selectedTeam === eventInfo?.homeTeamId ? teams[0] : teams[1];

  const isPlayerSelected = (playerId: string) => {
    return selectedPlayer.id === playerId;
  };
  return (
    <div className={styles.body}>
      <div className={styles.scrollableContent}>
        <div className={styles.globalOptions}>
          <label>
            <input
              type="radio"
              checked={isPlayerSelected("none")}
              onChange={() => handlePlayerChange("No Goal", "none")}
            />
            {t("goalscorer.noGoal")}
          </label>
          <label>
            <input
              type="radio"
              checked={isPlayerSelected("owngoal")}
              onChange={() => handlePlayerChange("Own goal", "owngoal")}
            />
            {t("goalscorer.ownGoal")}
          </label>
        </div>
        <div className={styles.teams}>
          <div
            className={styles.teamHighlight}
            style={{
              transform: `translateX(${selectedTeam === eventInfo?.awayTeamId ? "100%" : "0%"})`,
            }}
          ></div>
          <button
            className={styles.teamButton}
            onClick={() => handleTeamChange(eventInfo?.homeTeamId)}
          >
            <img src={`${cdnBaseURL}${eventInfo?.homeTeamId}.png`} alt="" />
            <p>{eventInfo?.homeTeamName || "unknown"}</p>
          </button>
          <button
            className={styles.teamButton}
            onClick={() => handleTeamChange(eventInfo?.awayTeamId)}
          >
            <img src={`${cdnBaseURL}${eventInfo?.awayTeamId}.png`} alt="" />
            <p>{eventInfo?.awayTeamName || "unknown"}</p>
          </button>
        </div>
        {currentTeam && (
          <div className={styles.players}>
            {["attacker", "midfielder", "defender", "goalkeeper"].map((position) => (
              <div key={position} className={styles.playerBlock}>
                <h3>{t(`goalscorer.${position}`)}</h3>
                {currentTeam.players
                  .filter((player) => player.position === position)
                  .map((player) => (
                    <label className={styles.playerRow} key={player.id}>
                      <input
                        type="radio"
                        checked={isPlayerSelected(player.id)}
                        onChange={() => handlePlayerChange(player.name, player.id)}
                      />
                      {player.name}
                    </label>
                  ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.buttonsPlace}>
        <div className={styles.buttons}>
          <button onClick={close}>{t("goalscorer.cancel")}</button>
          <button
            onClick={() => {
              choosePlayer(selectedPlayer);
              close();
            }}
            className={styles.confirm}
          >
            {t("goalscorer.confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlayer;
