import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useOptInStatus } from "../casino.api";
import { CasinoPromoConfig } from "../casino.types";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { getHeaderBannerStatus } from "../utils";

import CasinoHeader from "../components/casinoHeader/CasinoHeader";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import GameSwitchRenderer from "../components/gameSwitchRenderer/GameSwitchRenderer";

import styles from "./CasinoLayout.module.scss";

type Props = {
  casinoData: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: Function;
  isOptedIn: boolean;
};

const CasinoLayoutA = ({ casinoData, userProgress, setUserProgress, isOptedIn }: Props) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { data: optInStatus, isLoading: isOptInStatusLoading } = useOptInStatus(
    authContext,
    casinoData?.optIn && authContext.isLoggedIn
  );

  const isLoggedIn = authContext.isLoggedIn;
  const hasBanner = getHeaderBannerStatus(casinoData.category);

  if (isOptInStatusLoading) {
    return <LoadingPage />;
  }
  console.log("rame");

  return (
    <div className={styles.casinoLayoutA}>
      <div className={styles.container}>
        <div className={styles.header}>
          <CasinoHeader casinoData={casinoData} hasBanner={hasBanner} />
        </div>
        <div className={`${styles.game} ${!isOptedIn || !isLoggedIn ? styles.unavailable : ""}`}>
          <GameSwitchRenderer
            casinoData={casinoData}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
            isOptedIn={isOptedIn}
          />
        </div>
      </div>
    </div>
  );
};

export default CasinoLayoutA;
