import React from "react";
import { useTranslation } from "react-i18next";
import { ExactScoreType, calculatePoint } from "../utils/exactScoreCalcs";
import styles from "./WidgetCard.module.scss";
import Tooltip from "../tooltip/Tooltip";

interface ExactScoreTooltipProps {
  point: number;
  exactScoreDisplay?: number;
  pointsDisplay?: any;
}

const ExactScoreTooltip: React.FC<ExactScoreTooltipProps> = ({
  point,
  exactScoreDisplay,
  pointsDisplay,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={`${styles.pointer} exact-score-target-${exactScoreDisplay}`}>
        {pointsDisplay}
      </span>
      <Tooltip targetClassName={`exact-score-target-${exactScoreDisplay}`}>
        <div className={styles.exactScorePoints}>
          <h3>{t("exactScore.tooltip.title")}</h3>
          <p>
            {t("exactScore.tooltip.matchScore")} -{" "}
            <span>
              {calculatePoint(point, ExactScoreType.BOTH_TEAMS_SCORE)}
              {t("historyWidget.weekStatus.pointsShort")}
            </span>
          </p>
          <p>
            {t("exactScore.tooltip.matchResult")} -{" "}
            <span>
              {calculatePoint(point, ExactScoreType.RESULT_AND_ONE_TEAM_SCORE)}
              {t("historyWidget.weekStatus.pointsShort")}
            </span>
          </p>
          <p>
            {t("exactScore.tooltip.onlyResult")} -{" "}
            <span>
              {calculatePoint(point, ExactScoreType.RESULT)}
              {t("historyWidget.weekStatus.pointsShort")}
            </span>
          </p>
          <p>
            {t("exactScore.tooltip.onlyGoals")} -{" "}
            <span>
              {calculatePoint(point, ExactScoreType.ONE_TEAM_SCORE)}
              {t("historyWidget.weekStatus.pointsShort")}
            </span>
          </p>
        </div>
      </Tooltip>
    </>
  );
};

export default ExactScoreTooltip;
