import { CasinoQuestProgress } from "@/widgets/casinoWidget/casino.types";

import styles from "./DailyOfferCalendarCard.module.scss";

import lock from "@/assets/img/lock-fill.svg";

type OfferStatus = "EXPIRED" | "UNCLAIMED" | "CLAIMED" | "TODAY" | null;

type Props = {
  cardFaceImages: {
    locked: string;
    unlocked: string;
  };
  questData: any;
  progress: CasinoQuestProgress | null;
  onClick: Function;
};

const getCalendarLockState = (startDate: string, endDate: string): "PAST" | "TODAY" | "FUTURE" => {
  const now = new Date(); // Current date and time
  const start = new Date(startDate); // Parse startDate
  const end = new Date(endDate); // Parse endDate

  // Reset time for comparison (we only care about the date, not the time)
  // now.setHours(0, 0, 0, 0);
  // start.setHours(0, 0, 0, 0);
  // end.setHours(0, 0, 0, 0);

  if (now < start) {
    return "FUTURE";
  } else if (now > end) {
    return "PAST";
  } else {
    return "TODAY";
  }
};

const DailyOfferCalendarStatusMark = ({ status }: { status: OfferStatus }) => {
  switch (status) {
    case "EXPIRED":
      return <div className={styles.status_box}>Expired</div>;
    case "UNCLAIMED":
      return <div className={styles.status_box}>Unclaimed</div>;
    case "CLAIMED":
      return <div className={styles.status_box}>Recieved ✓</div>;
    case "TODAY":
      return <div className={styles.status_box}>Today</div>;
    case null:
      return <></>;

    default:
      return <></>;
  }
};

const getDayStatus = (
  status: "INPROGRESS" | "COMPLETED" | "NOPROGRESS" | undefined,
  needsClaiming: boolean
) => {
  switch (status) {
    case "COMPLETED":
      if (needsClaiming) {
        return "UNCLAIMED";
      } else {
        return "CLAIMED";
      }

    default:
      break;
  }

  return null;
};

const ProgressBar = ({
  currentProgress,
  maxProgress,
}: {
  currentProgress: number | null;
  maxProgress: number;
}) => {
  const progressItems: any[] = [];

  for (let index = 0; index < maxProgress; index++) {
    progressItems.push(
      <div
        className={`${styles.progress__item} ${currentProgress && index < currentProgress ? styles.completed : ""}`}
        key={index}
      ></div>
    );
  }

  return <div className={styles.progress}>{progressItems}</div>;
};

const DailyOfferCalendarCard = ({ cardFaceImages, questData, progress, onClick }: Props) => {
  const questLockState = getCalendarLockState(questData.startDate, questData.endDate);
  const questStatus = progress ? getDayStatus(progress.status, progress.needsClaiming) : null;
  const formattedStartDate = new Date(questData.startDate).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });

  return (
    <div
      className={`${styles.dailyOfferCard} ${questLockState === "TODAY" ? styles.today : ""}`}
      onClick={() => onClick(questData, questLockState === "FUTURE")}
    >
      <div className={styles.dailyOfferCard__top}>
        <div className={styles.dailyOfferCard__status}>
          <DailyOfferCalendarStatusMark
            status={questStatus || (questLockState === "TODAY" ? questLockState : null)}
          />
        </div>
        <img
          className={`${styles.dailyOfferCard__img}`}
          src={
            questStatus === "CLAIMED" || questStatus === "UNCLAIMED"
              ? cardFaceImages.unlocked
              : cardFaceImages.locked
          }
          alt=""
        />
        <div className={styles.dailyOfferCard__date}>{formattedStartDate}</div>
      </div>
      <div className={styles.dailyOfferCard__bottom}>
        {progress && (
          <ProgressBar
            currentProgress={progress.currentProgress}
            maxProgress={questData?.task?.length || 0}
          />
        )}
        {/* <h6 className={styles.prize}>25 % Bonus + 140 freespin</h6>
        <p className={styles.provider}>
          AT <span>Pragmatic Play</span>
        </p> */}
      </div>
      {questLockState === "FUTURE" && (
        <div className={styles.dailyOfferCard__vail}>
          <img src={lock} alt="" />
        </div>
      )}
    </div>
  );
};

export default DailyOfferCalendarCard;
