import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/dist/locale/ka";
import "moment/dist/locale/ru";
import "moment/dist/locale/es-us";

const formatMatchDate = (matchDate: string | undefined): string => {
  const { t } = useTranslation();

  if (!matchDate) return t("pickemWidget.unknownDate");
  return moment(matchDate).locale(t("dateTime")).format("MMM D, HH:mm ");
};

export { formatMatchDate };
