import React, { useContext, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { useLobbyConfig } from "@/providers/LobbyConfigProvider";
import BannerSlider from "@/components/bannerSlider/BannerSlider";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import styles from "./MissionsWidget.module.scss";
import MissionsFilter from "./components/missionsFilter/MissionsFilter";
import MissionsSubFilter from "./components/missionsSubFilter/MissionsSubFilter";
import MissionCards from "./components/missionCards/MissionCards";
import { loadMissions } from "./mission.api";
import { isArray } from "lodash";

const MissionsWidget: React.FC = () => {
  const authContext = useContext<AuthContextProps>(AuthContext);

  const {
    data: missionsData,
    isLoading: isMissionsDataLoading,
    isError: isMissionsDataError,
  } = loadMissions(authContext);

  const lobbyConfig = useLobbyConfig();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const mainFilters = [
    { label: t("lobby.running"), value: "running" },
    { label: t("lobby.completed"), value: "completed" },
    { label: t("lobby.myPromotions"), value: "myPromotions" },
  ];

  const [activeFilter, setActiveFilter] = useState<string>(mainFilters[0].value);
  const [activeSubFilter, setActiveSubFilter] = useState<string>("all");

  const filteredMissionCategories = useMemo(() => {
    if (!isArray(missionsData)) return [];
    return missionsData?.filter(
      (category: any) => category.missions && category.missions.length > 0
    );
  }, [missionsData]);

  const subFilters = useMemo(() => {
    const categoryFilters = filteredMissionCategories.map((category: any) => ({
      label: category.translations[i18n.language]?.title || category.translations.en?.title,
      value: category.id,
      type: category.type,
    }));
    return [{ label: t("missions.all"), value: "all" }, ...categoryFilters];
  }, [filteredMissionCategories, i18n.language, t]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterFromQuery = params.get("filter");
    const subFilterFromQuery = params.get("subFilter");

    if (filterFromQuery && mainFilters.some((f) => f.value === filterFromQuery)) {
      setActiveFilter(filterFromQuery);
    }
    if (subFilterFromQuery && subFilters.some((f) => f.value === subFilterFromQuery)) {
      setActiveSubFilter(subFilterFromQuery);
    }
  }, [location.search, subFilters]);

  const updateQueryString = (filter: string, subFilter: string) => {
    const params = new URLSearchParams(location.search);
    params.set("filter", filter);
    params.set("subFilter", subFilter);
    navigate(`?${params.toString()}`, { replace: true });
  };

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
    updateQueryString(filter, activeSubFilter);
  };

  const handleSubFilterChange = (subFilter: string) => {
    setActiveSubFilter(subFilter);
    updateQueryString(activeFilter, subFilter);
  };

  const banners = lobbyConfig.banner ? JSON.parse(lobbyConfig.banner)[i18n.language] : [];

  if (isMissionsDataLoading) return <LoadingPage />;

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <BannerSlider banners={banners} />
        </div>
        <div className={styles.lobby}>
          <MissionsFilter
            filters={mainFilters}
            activeFilter={activeFilter}
            onSelectFilter={handleFilterChange}
          />
          <MissionsSubFilter
            filters={subFilters}
            activeFilter={activeSubFilter}
            onSelectFilter={handleSubFilterChange}
          />
          <MissionCards
            missionCategories={filteredMissionCategories}
            activeFilter={activeFilter}
            activeSubFilter={activeSubFilter}
            onSubFilterChange={handleSubFilterChange}
          />
        </div>
        <div className={styles.background__img}></div>
      </div>
    </div>
  );
};

export default MissionsWidget;
