import { useTranslation } from "react-i18next";

import styles from "./PrizeTable.module.scss";

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

type Props = { leaderboardData: any[]; rewardType: RewardTemplateEnum };

const PrizeTable = ({ leaderboardData, rewardType }: Props) => {
  const { t } = useTranslation();

  const styleMechanicHandler = () => {
    if (rewardType === RewardTemplateEnum.EXACT_GUESS) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.prizeTable}>
      <div className={styles.prizeTable__head}>
        <div
          className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
        >
          <p>
            {rewardType !== RewardTemplateEnum.EXACT_GUESS ? t("prizeTable.header.position") : ""}
          </p>
        </div>
        <div
          className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
        >
          <p>{t("prizeTable.header.user")}</p>
        </div>
        <div
          className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
        >
          {rewardType !== RewardTemplateEnum.EXACT_GUESS ? (
            <p>{t("prizeTable.header.points")}</p>
          ) : (
            <p>{t("prizeTable.header.correctAnswer")}</p>
          )}
        </div>
        <div
          className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
        >
          <p>{t("prizeTable.header.prize")}</p>
        </div>
      </div>
      <ul className={styles.prizeTable__body}>
        {leaderboardData.map((e, index) => {
          return (
            <li className={styles.prizeTable__item} key={index}>
              <div
                className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""} `}
              >
                <p>{rewardType !== RewardTemplateEnum.EXACT_GUESS ? e.rank || "-" : ""}</p>
              </div>
              <div
                className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
              >
                <p>{e.username || "-"}</p>
              </div>
              <div
                className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
              >
                <p>{Math.floor(e.score) || "-"}</p>
              </div>
              <div
                className={`${styles.prizeTable__column} ${styleMechanicHandler() ? styles.exact : ""}`}
              >
                <p>{e.name || "-"}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PrizeTable;
