import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CasinoTimer.module.scss";

interface CasinoTimerProps {
  endDate: string | null;
  className?: string;
  title?: string; // New prop for the title
}

const calculateTimeRemaining = (endDate: number) => {
  const now = new Date().getTime();
  const difference = endDate - now;
  if (difference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

const TimerDigit: React.FC<{ value: string }> = ({ value }) => (
  <div className={styles.digitContainer}>
    <span className={styles.digit}>{value}</span>
  </div>
);

const CasinoTimer: React.FC<CasinoTimerProps> = ({ endDate, className = "", title }) => {
  const endDateObject = endDate ? new Date(endDate).getTime() : null;
  const [timeRemaining, setTimeRemaining] = useState(
    endDateObject
      ? calculateTimeRemaining(endDateObject)
      : { days: 0, hours: 0, minutes: 0, seconds: 0 }
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!endDateObject) return;
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(endDateObject));
    }, 1000);
    return () => clearInterval(timer);
  }, [endDateObject]);

  const formatTime = (value: number) => value.toString().padStart(2, "0");

  return (
    <div className={`${styles.casinoTimer} ${className}`}>
      {title && <div className={styles.timerTitle}>{title}</div>}
      <div className={styles.timerContent}>
        <div className={styles.timerBlock}>
          <div className={styles.digitGroup}>
            {formatTime(timeRemaining.days)
              .split("")
              .map((digit, index) => (
                <TimerDigit key={`days-${index}`} value={digit} />
              ))}
          </div>
          <span className={styles.timerLabel}>{t("timer.days")}</span>
        </div>
        <div className={styles.timerBlock}>
          <div className={styles.digitGroup}>
            {formatTime(timeRemaining.hours)
              .split("")
              .map((digit, index) => (
                <TimerDigit key={`hours-${index}`} value={digit} />
              ))}
          </div>
          <span className={styles.timerLabel}>{t("timer.hoursShort")}</span>
        </div>
        <div className={styles.timerBlock}>
          <div className={styles.digitGroup}>
            {formatTime(timeRemaining.minutes)
              .split("")
              .map((digit, index) => (
                <TimerDigit key={`minutes-${index}`} value={digit} />
              ))}
          </div>
          <span className={styles.timerLabel}>{t("timer.minutesShort")}</span>
        </div>
        <div className={styles.timerBlock}>
          <div className={styles.digitGroup}>
            {formatTime(timeRemaining.seconds)
              .split("")
              .map((digit, index) => (
                <TimerDigit key={`seconds-${index}`} value={digit} />
              ))}
          </div>
          <span className={styles.timerLabel}>{t("timer.secondsShort")}</span>
        </div>
      </div>
    </div>
  );
};

export default CasinoTimer;
