import React, { ReactNode, useState } from "react";

import { useTranslation } from "react-i18next";

import boosterBrand from "../../assets/img/coca-cola.svg";

import styles from "./WidgetCard.module.scss";
import WidgetCardHeader from "./WidgetCardHeader";
import Booster from "../booster/Booster";

interface WidgetCardProps {
  className?: string;
  children?: ReactNode;
  editable?: boolean;
  booster?: string | boolean;
  headerElement?: ReactNode;
  headerInfo?: {
    matchDate?: string;
    userPick?: any;
    question?: string;
    point?: number;
    isHistory: boolean;
    isMatchFinished: boolean;
    exactScoreDisplay?: number;
    exactScorePoint?: number;
  };
  disabled?: boolean;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
  matchStatus?: string | null;
}

const WidgetCard: React.FC<WidgetCardProps> = ({
  className,
  editable,
  disabled,
  headerElement,
  headerInfo,
  children,
  isBooster,
  isBoosted,
  toggleBooster,
  matchStatus,
}) => {
  return (
    <div
      className={`${styles.widget} ${className ? className : ""} ${disabled ? styles.disabledBg : ""} ${isBoosted ? (matchStatus ? styles[matchStatus] : styles.isBoosted) : ""}`}
    >
      {headerElement ? headerElement : <WidgetCardHeader headerInfo={headerInfo} />}
      <div className={`${styles.widget__body} ${!editable ? styles.noEvents : ""} `}>
        {children}
        {isBooster && (
          <Booster isBoosted={isBoosted} toggleBooster={toggleBooster} matchStatus={matchStatus} />
        )}
      </div>
    </div>
  );
};

export default WidgetCard;
