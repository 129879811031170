import httpClient, { PromofyAuthHeaders } from "@/api/httpClient.ts";
import { AuthContextProps } from "@/providers/AuthProvider";
import ConfigService from "@/services/ConfigService.ts";
import { useQuery, useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query";

export const useCasinoData = () => {
  return useQuery({
    queryKey: ["casinoData", ConfigService.getPromoId()],
    queryFn: () => httpClient.get(`/casino/${ConfigService.getPromoId()}`),
    select: (response) => response.data,
  });
};

export const useOptInStatus = (context: AuthContextProps, condition: boolean) => {
  let exactCondition = condition === true;
  return useQuery({
    queryKey: ["optInStatus", ConfigService.getPromoId()],
    queryFn: () =>
      httpClient.get(`/opt-in/${ConfigService.getPromoId()}`, {
        headers: {
          ...PromofyAuthHeaders(context),
        },
      }),
    select: (response) => response.data,
    enabled: exactCondition,
  });
};

export const useUserProgress = (
  context: AuthContextProps,
  isLoggedIn: boolean
): UseQueryResult<any, Error> & { enabled: boolean } => {
  const enabled = isLoggedIn === true;

  return {
    ...useQuery({
      queryKey: ["userProgress", ConfigService.getPromoId()],
      queryFn: () =>
        httpClient.get(`/casino/${ConfigService.getPromoId()}/user/progress`, {
          headers: {
            ...PromofyAuthHeaders(context),
          },
        }),
      select: (response) => response.data,
      enabled,
    }),
    enabled,
  };
};

export const useOptIn = (context: AuthContextProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      httpClient.post(
        `/opt-in/${ConfigService.getPromoId()}`,
        {},
        {
          headers: {
            ...PromofyAuthHeaders(context),
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["optInStatus", ConfigService.getPromoId()] });
      queryClient.invalidateQueries({ queryKey: ["userProgress", ConfigService.getPromoId()] });
    },
  });
};
