import React, { useState, useRef, useContext, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AztecClusters from "./assets/img/AztecClusters.webp";
import BitstarzBillion from "./assets/img/BitstarzBillion.webp";
import CatsOfOlympuss from "./assets/img/CatsOfOlympuss.webp";
import EvilEyes96 from "./assets/img/EvilEyes96.webp";
import GatesofOlympus1000 from "./assets/img/GatesofOlympus1000.webp";
import juicywheel from "./assets/img/juicywheel.webp";
import LeBandit from "./assets/img/LeBandit.webp";
import MGS_amazingLinkAthenaDesktop from "./assets/img/MGS_amazingLinkAthenaDesktop.webp";
import RazorReturns from "./assets/img/RazorReturns.webp";
import SaintBananas from "./assets/img/SaintBananas.webp";
import SixSixSix96 from "./assets/img/SixSixSix96.webp";
import Slurpy from "./assets/img/Slurpy.webp";
import SugarRush1 from "./assets/img/SugarRush1.webp";
import wildspin from "./assets/img/wildspin.webp";

import styles from "./LeaderboardGame.module.scss";
import { Arrow } from "@/assets/svg";
import LeaderboardGameTable from "./components/leaderboardGameTable/LeaderboardGameTable";
import { useTranslation } from "react-i18next";
import { LeaderBoardType } from "@/widgets/pickemWidget/pickem.types";
import { ConfigContext } from "@/providers/ConfigProvider";
import { CasinoPromoConfig } from "../../casino.types";
import { getPeriodsFromCasinoData } from "../../utils";
import { loadLeaderboard, loadLeaderboardUser } from "@/widgets/pickemWidget/pickem.api";
import {
  expandArrayByRank,
  filterRewardsByPeriodType,
} from "@/widgets/pickemWidget/leaderboard/utils";
import { getConditionTitle } from "./utils";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";

type Props = {
  data: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: any;
  isOptedIn: boolean;
};

const LeaderboardGame: React.FC<Props> = ({ data, userProgress, setUserProgress, isOptedIn }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);
  const { t } = useTranslation();
  const { reward } = useContext<any>(ConfigContext);

  const authContext = useContext<AuthContextProps>(AuthContext);

  const periodOptions = useMemo(() => {
    return getPeriodsFromCasinoData(data, reward, t);
  }, [data, reward, t]);

  const timeboundPeriod = periodOptions.find(
    (period: any) => period.periodType === LeaderBoardType.TIMEBOUND
  );
  const otherPeriods = periodOptions.filter(
    (period: any) => period.periodType !== LeaderBoardType.TIMEBOUND
  );

  const [selectedPeriod, setSelectedPeriod] = useState(otherPeriods[0]?.id || timeboundPeriod?.id);

  const hasOverallReward =
    data.periodType !== LeaderBoardType.TIMEBOUND &&
    reward.find((obj: any) => obj.period === LeaderBoardType.OVERALL);

  const {
    data: primaryLeaderboardData,
    isLoading: isPrimaryLoading,
    isError: isPrimaryError,
  } = loadLeaderboard({
    limit: 100,
    type: data.periodType as LeaderBoardType,
    partitionKey: selectedPeriod?.toString(),
  });

  const {
    data: overallLeaderboardData,
    isLoading: isOverallLoading,
    isError: isOverallError,
  } = loadLeaderboard({
    limit: 100,
    type: LeaderBoardType.OVERALL,
    partitionKey: data.id.toString(),
    enabled: hasOverallReward,
  });

  const { data: primaryMyPositionData } = loadLeaderboardUser({
    type: data.periodType as LeaderBoardType,
    partitionKey: selectedPeriod?.toString(),
    playerId: authContext.getCurrentUser?.id,
    context: authContext,
    enabled: true,
  });

  const { data: overallMyPositionData } = loadLeaderboardUser({
    type: LeaderBoardType.OVERALL,
    partitionKey: data.id.toString(),
    playerId: authContext.getCurrentUser?.id,
    context: authContext,
    enabled: hasOverallReward,
  });

  const primaryLeaderboardRewards = useMemo(() => {
    return expandArrayByRank(
      filterRewardsByPeriodType(
        reward,
        data?.periodType === LeaderBoardType.TIMEBOUND ? LeaderBoardType.OVERALL : data.periodType
      )
    );
  }, [reward, data.periodType]);

  const overallLeaderboardRewards = useMemo(() => {
    if (!hasOverallReward) return [];
    return expandArrayByRank(filterRewardsByPeriodType(reward, LeaderBoardType.OVERALL));
  }, [reward, hasOverallReward]);

  const images = [
    AztecClusters,
    BitstarzBillion,
    CatsOfOlympuss,
    EvilEyes96,
    GatesofOlympus1000,
    juicywheel,
    LeBandit,
    MGS_amazingLinkAthenaDesktop,
    RazorReturns,
    SaintBananas,
    SixSixSix96,
    Slurpy,
    SugarRush1,
    wildspin,
  ];

  const handleSlideChange = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handleDateChange = (newDate: { id: string }) => {
    setSelectedPeriod(newDate.id);
  };

  return (
    <div className={styles.leaderboardGame}>
      {data.quest[0]?.task.map((task: any, index: number) => (
        <div key={index} className={styles.condition}>
          <div className={styles.condition__title}>{getConditionTitle(task, data.category, t)}</div>
        </div>
      ))}
      <div className={styles.leaderboards}>
        {otherPeriods.length > 0 && (
          <LeaderboardGameTable
            title={t("leaderboard.leaderboard")}
            userData={primaryMyPositionData}
            entries={primaryLeaderboardData?.items}
            mode="daily"
            onDateChange={handleDateChange}
            periods={otherPeriods}
            isLoading={isPrimaryLoading}
            isError={isPrimaryError}
            rewardData={primaryLeaderboardRewards}
          />
        )}
        {(timeboundPeriod || hasOverallReward) && (
          <LeaderboardGameTable
            title={t("leaderboard.overallLeaderboard")}
            userData={hasOverallReward ? overallMyPositionData : primaryMyPositionData}
            entries={
              timeboundPeriod ? primaryLeaderboardData?.items : overallLeaderboardData?.items
            }
            mode="overall"
            periods={timeboundPeriod ? [timeboundPeriod] : []}
            isLoading={hasOverallReward ? isOverallLoading : isPrimaryLoading}
            isError={timeboundPeriod ? isPrimaryError : isOverallError}
            rewardData={hasOverallReward ? overallLeaderboardRewards : primaryLeaderboardRewards}
          />
        )}
      </div>
      <div className={styles.games}>
        <div className={styles.games__titles}>
          <p>{t("casino.eligibleGames")}</p>
          <div className={styles.seeAll}>{t("casino.seeAll")}</div>
        </div>
        <div className={styles.sliderContainer}>
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            slidesPerView="auto"
            spaceBetween={12}
            modules={[Navigation, Pagination]}
            onSlideChange={handleSlideChange}
            className={styles.swiper}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <img src={image} alt="" className={styles.sliderImg} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles.swiperPagination}></div>
          {!isBeginning && (
            <div
              className={`${styles.navigationButton} ${styles.navigationButtonLeft}`}
              onClick={handlePrev}
            >
              <Arrow direction="left" />
            </div>
          )}
          {!isEnd && (
            <div
              className={`${styles.navigationButton} ${styles.navigationButtonRight}`}
              onClick={handleNext}
            >
              <Arrow direction="right" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardGame;
