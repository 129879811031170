import { useMemo } from "react";

import WidgetCard from "@/components/widgetCard/WidgetCard";
import ConfigService from "@/services/ConfigService.ts";
import { Picks, WeekStatus } from "@/widgets/pickemWidget/pickem.types";

import styles from "./FT1x2Match.module.scss";

import { MatchStatus, MatchPredictionTemplate } from "../MatchStatuses";

import CorrectIcon from "../assets/img/Correct.svg";
import IncorrectIcon from "../assets/img/Incorrect.svg";
import InProgressIcon from "../assets/img/InProgress.svg";

import { useTranslation } from "react-i18next";
import { formatMatchDate } from "@/components/utils/formatMatchDate";
import NameTooltip from "@/components/tooltip/nameTooltip/NameTooltip";

interface Props {
  matchInfo: any;
  editable: boolean;
  addOptionToArray?: any;
  locked?: boolean;
  weekStatus: WeekStatus;
  userPick?: Picks;
  type: MatchPredictionTemplate;
  selectedOption?: string | null;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
}

const FT1x2Match = ({
  matchInfo,
  editable,
  locked = false,
  userPick,
  weekStatus,
  addOptionToArray,
  type,
  selectedOption,
  isBooster,
  isBoosted,
  toggleBooster,
}: Props) => {
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const { t } = useTranslation();

  function handleOptionSelect(option: string) {
    addOptionToArray(matchInfo.propId, { value: option });
  }

  const isHistory = useMemo(
    () => [WeekStatus.live, WeekStatus.completed].includes(weekStatus),
    [weekStatus]
  );

  function getOptionClassName(option: string) {
    const eventValueArray = matchInfo?.eventValue
      ? matchInfo.eventValue.split("|").map((value: string) => value.trim())
      : [];
    return `${styles.matchBet__option} ${!locked && selectedOption === option ? styles.active : ""} 
    ${isHistory && isMatchFinished && eventValueArray.includes(option) && userPick?.correct !== null ? styles.correct : ""} ${
      isHistory && isMatchFinished && userPick?.value === option && userPick?.correct === false
        ? styles.incorrect
        : ""
    } ${isHistory && userPick?.value === option ? styles.chosenBorder : ""} 
      ${isHistory && (!isMatchFinished || userPick?.correct === null) && userPick?.value === option ? styles.inProgress : ""}`;
  }

  const isMatchFinished = useMemo(
    () =>
      eventInfo.homeTeamPoints !== null &&
      !isNaN(eventInfo.homeTeamPoints) &&
      eventInfo.awayTeamPoints !== null &&
      !isNaN(eventInfo.awayTeamPoints),
    [eventInfo]
  );

  const showOptions = useMemo(() => {
    if (isHistory) {
      if (userPick && Object.keys(userPick).length > 0) {
        return true;
      }
      return false;
    }
    return weekStatus !== WeekStatus.upcoming;
  }, [userPick, weekStatus]);

  const cancelledOrPostponed = useMemo(() => {
    return [MatchStatus.postponed, MatchStatus.cancelled].includes(eventInfo.status);
  }, [eventInfo.status]);

  const [
    option1,
    option1Translate,
    option2,
    option2Translate,
    option3,
    option3Translate,
    headerQuestion,
  ] = useMemo(() => {
    if (type === MatchPredictionTemplate.DOUBLE_CHANCE) {
      return ["1X", "1X", "12", "12", "X2", "X2", t("matchBet.doubleChance")];
    }
    if (type === MatchPredictionTemplate.MORE_CORNERS) {
      return [
        "h1",
        t("matchBet.moreCornersAnswer1"),
        "x",
        t("matchBet.moreCornersAnswerX"),
        "h2",
        t("matchBet.moreCornersAnswer2"),
        t("matchBet.moreCorners"),
      ];
    }
    if (type === MatchPredictionTemplate.MORE_OFFSIDES) {
      return [
        "h1",
        t("matchBet.moreCornersAnswer1"),
        "x",
        t("matchBet.moreCornersAnswerX"),
        "h2",
        t("matchBet.moreCornersAnswer2"),
        t("matchBet.moreOffsides"),
      ];
    }
    return ["1", "1", "X", "X", "2", "2", t("matchBet.question")];
  }, [eventInfo.status]);

  const eventValueArray = useMemo(() => {
    return matchInfo?.eventValue
      ? matchInfo.eventValue.split("|").map((value: string) => value.trim())
      : [];
  }, [matchInfo?.eventValue]);

  const matchStatus = useMemo(() => {
    if (!isHistory || !userPick) return null;
    if (!isMatchFinished || userPick.correct === null) return "inProgress";
    return userPick.correct ? "correct" : "incorrect";
  }, [isHistory, isMatchFinished, userPick]);

  function renderOptionIcon(option: string) {
    if (!isHistory || !userPick || userPick.value !== option) return null;

    switch (matchStatus) {
      case "inProgress":
        return <img src={InProgressIcon} className={styles.optionIcon} alt="" />;
      case "correct":
        if (eventValueArray.includes(option)) {
          return <img src={CorrectIcon} className={styles.optionIcon} alt="" />;
        }
        return null;
      case "incorrect":
        return <img src={IncorrectIcon} className={styles.optionIcon} alt="" />;
      default:
        return null;
    }
  }

  function getTranslationsByEventValue(eventValue: string) {
    switch (eventValue) {
      case "h1":
        return t("matchBet.moreCornersAnswer1");
      case "x":
        return t("matchBet.moreCornersAnswerX");
      case "h2":
        return t("matchBet.moreCornersAnswer2");
      default:
        return null;
    }
  }

  function renderScore() {
    switch (type) {
      case MatchPredictionTemplate.DOUBLE_CHANCE:
      case MatchPredictionTemplate.FT_1X2:
        return `${eventInfo.homeTeamPoints} : ${eventInfo.awayTeamPoints}`;

      case MatchPredictionTemplate.MORE_CORNERS:
      case MatchPredictionTemplate.MORE_OFFSIDES:
        return (
          <div className={styles.dualResult}>
            <p className={styles.result}>{t("matchBet.result")}</p>
            <span>{getTranslationsByEventValue(matchInfo.eventValue)}</span>
          </div>
        );

      default:
        return "";
    }
  }

  return (
    <WidgetCard
      headerInfo={{
        matchDate: matchInfo.matchTime,
        userPick,
        point:
          (isHistory && userPick?.booster) || isBoosted ? matchInfo?.point * 2 : matchInfo?.point,
        question: headerQuestion,
        isHistory,
        isMatchFinished,
      }}
      editable={editable}
      disabled={cancelledOrPostponed}
      isBooster={isBooster}
      isBoosted={isHistory && userPick?.booster ? true : isBoosted}
      toggleBooster={toggleBooster}
      matchStatus={matchStatus}
    >
      <div className={styles.matchBet__teams}>
        <div className={styles.matchBet__team}>
          <p
            data-tooltip-id="homeTeamName"
            data-tooltip-content={eventInfo?.homeTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.homeTeamName || "unknown"}
          </p>
          <NameTooltip id="homeTeamName" />
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.homeTeamId}.png`} />
          </div>
        </div>
        <div className={styles.matchBet__center}>
          {!isHistory ? (
            <div className={styles.dualResult}>
              <p>vs</p>
              <span>{formatMatchDate(matchInfo.matchTime)}</span>
            </div>
          ) : isHistory && isMatchFinished ? (
            <p>{renderScore()}</p>
          ) : (
            <p>vs</p>
          )}
        </div>
        <div className={styles.matchBet__team}>
          <div className={styles.matchBet__img}>
            <img src={`${cdnBaseURL}${eventInfo?.awayTeamId}.png`} />
          </div>
          <p
            data-tooltip-id="awayTeamName"
            data-tooltip-content={eventInfo?.awayTeamName || "unknown"}
            data-tooltip-place="bottom"
          >
            {eventInfo?.awayTeamName || "unknown"}
          </p>
          <NameTooltip id="awayTeamName" />
        </div>
      </div>
      {showOptions && (
        <div className={styles.matchBet__picker}>
          {cancelledOrPostponed ? (
            <div className={styles.postponedWrapper}>
              <div className={styles.postponedBtn}>
                {MatchStatus.postponed === eventInfo.status
                  ? t("matchBet.postponed")
                  : t("matchBet.cancelled")}
              </div>
            </div>
          ) : (
            <>
              <div
                className={getOptionClassName(option1)}
                onClick={!locked ? () => handleOptionSelect(option1) : undefined}
              >
                <p>{option1Translate}</p>
                {renderOptionIcon(option1)}
              </div>
              <div
                className={getOptionClassName(option2)}
                onClick={!locked ? () => handleOptionSelect(option2) : undefined}
              >
                <p>{option2Translate}</p>
                {renderOptionIcon(option2)}
              </div>
              <div
                className={getOptionClassName(option3)}
                onClick={!locked ? () => handleOptionSelect(option3) : undefined}
              >
                <p>{option3Translate}</p>
                {renderOptionIcon(option3)}
              </div>
            </>
          )}
        </div>
      )}
    </WidgetCard>
  );
};

export default FT1x2Match;
