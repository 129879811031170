import { useContext, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "@/providers/ConfigProvider.tsx";
import { LeaderBoardType } from "../pickem.types.ts";
import LeaderboardTable from "./components/leaderboardTable/LeaderboardTable.tsx";
import styles from "./LeaderboardWidget.module.scss";
import ExactGuessAwardsTable from "./components/exactGuessAwardsTable/ExactGuessAwardsTable.tsx";
import SlidingNavigation from "@/components/slidingNavigation/SlidingNavigation.tsx";

type Props = { pickemData: any };

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

type PeriodOption = {
  value: string;
  label: string;
  type: LeaderBoardType;
  status?: string;
};

const getPeriodsFromPickemData = (
  pickemData: any,
  periodType: LeaderBoardType,
  rewards: any[],
  t: any,
  rewarding: RewardTemplateEnum
): PeriodOption[] => {
  let periodArray: PeriodOption[] = [];
  const allWeeks = pickemData.weekPools.map((e: any) => ({
    value: e.uuid,
    label: e.week,
    type: periodType,
    status: e.status,
  }));

  if (periodType !== LeaderBoardType.OVERALL || rewarding === RewardTemplateEnum.EXACT_GUESS) {
    periodArray = allWeeks;
  }

  const overallReward = rewards.find((obj) => obj.period === LeaderBoardType.OVERALL);
  if (overallReward && rewarding !== RewardTemplateEnum.EXACT_GUESS) {
    const overallPeriod = {
      value: pickemData.promoRef,
      label: t("leaderboardWidget.overall"),
      type: LeaderBoardType.OVERALL,
    };

    periodArray.push(overallPeriod);
  }

  if (periodArray.length === 0) {
    periodArray.push({
      value: pickemData.promoRef,
      label: t("leaderboardWidget.overall"),
      type: LeaderBoardType.OVERALL,
    });
  }

  return periodArray;
};

const LeaderboardWidget = ({ pickemData }: Props) => {
  const { t } = useTranslation();
  const { reward, periodType, rewarding } = useContext<any>(ConfigContext);

  const periods = useMemo(
    () => getPeriodsFromPickemData(pickemData, periodType, reward, t, rewarding),
    [pickemData, periodType, reward, t, rewarding]
  );

  const getDefaultPeriod = (): PeriodOption => {
    const completedWeeks = periods.filter((period) => period.status === "completed");
    if (periods[0]?.type === LeaderBoardType.OVERALL) {
      return periods[0];
    }
    return (
      completedWeeks[0] || periods[0] || { value: "", label: "", type: LeaderBoardType.OVERALL }
    );
  };

  const [selectedFilterValue, setSelectedFilterValue] = useState<PeriodOption>(getDefaultPeriod());

  const handleRoundSelect = (option: PeriodOption) => {
    setSelectedFilterValue(option);
  };

  const switchPrizeTables = (rewarding: string) => {
    switch (rewarding) {
      case RewardTemplateEnum.EXACT_GUESS:
        return <ExactGuessAwardsTable selectedFilter={selectedFilterValue} />;
      case RewardTemplateEnum.RANKING:
        return <LeaderboardTable selectedFilter={selectedFilterValue} />;
      default:
        return <LeaderboardTable selectedFilter={selectedFilterValue} />;
    }
  };

  return (
    <div className={styles.leaderboard}>
      <SlidingNavigation navItems={periods} slidesPerView={3} onChange={handleRoundSelect} />
      {switchPrizeTables(rewarding)}
    </div>
  );
};

export default LeaderboardWidget;
