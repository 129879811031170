import { Scrollbars, ScrollbarProps } from "react-custom-scrollbars-2";
import styles from "./DailyOfferCalendarScrollbar.module.scss";

interface Props extends ScrollbarProps {
  children: React.ReactNode;
  viewClassName?: string;
}

const DailyOfferCalendarScrollbar = ({ children, viewClassName, ...props }: Props) => {
  return (
    <Scrollbars
      {...props}
      renderView={({ style, ...viewProps }) => (
        <div
          {...viewProps}
          className={viewClassName} // Apply the custom className here
          style={{ ...style, marginBottom: "-22px" }} // Example custom styles for the view
        />
      )}
      renderThumbHorizontal={({ style, ...thumbProps }) => (
        <div
          {...thumbProps}
          style={{
            ...style,
            background: "#5481FF",
            borderRadius: "24px",
            height: "100%",
          }}
        />
      )}
      renderTrackHorizontal={({ style, ...trackProps }) => (
        <div
          {...trackProps}
          style={{
            ...style,
            background: "#5F498F",
            borderRadius: "24px",
            height: "16px",
            width: "70%",
            bottom: "0",
            transform: "translateX(25%)",
            padding: "3px",
          }}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
};

export default DailyOfferCalendarScrollbar;
