import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const setGoogleTagManager = (gtagId: string) => {
  const head = document.getElementsByTagName("head")[0];

  if (
    !Array.from(document.getElementsByTagName("noscript")).some((ns) =>
      ns.innerHTML.includes(`https://www.googletagmanager.com/ns.html?id=${gtagId}`)
    )
  ) {
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtagId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    head.appendChild(noscript);
  }

  if (!document.querySelector('script[src^="https://www.googletagmanager.com/gtm.js"]')) {
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtagId}');
    `;
    head.appendChild(script);
  }
};

export const useGTagConfig = (orgUuid: string | undefined) => {
  return useQuery({
    queryKey: ["gtagConfig", orgUuid],
    queryFn: () =>
      axios.get(
        `${import.meta.env.VITE_WIDGET_CDN_BASE_URL}/organization/${orgUuid}/gTag/config.json`
      ),
    select: (response) => response.data,
    enabled: !!orgUuid,
  });
};
