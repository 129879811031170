import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { CasinoPromoConfig } from "../../casino.types";
import { ConfigContext } from "@/providers/ConfigProvider";
import { getUserProgressForQuest } from "../../utils";
import useViewportSize from "@/hooks/useViewportSize";

import DailyOfferCalendarCard from "./components/dailyOfferCalendarCard/DailyOfferCalendarCard";
import DailyOfferCalendarScrollbar from "./components/dailyOfferCalendarScrollbar/DailyOfferCalendarScrollbar";
import StepMissions from "../../components/stepMissions/StepMissions";
import MobileSlider from "@/components/mobileSlider/MobileSlider";
import Popup from "@/components/popup/Popup";

import styles from "./DailyOfferCalendarWidget.module.scss";

import { BullseyeArrow } from "@/assets/svg";

type Day = {
  casinoReward: any[];
  startDate: string | null;
  endDate: string | null;
  id: number;
  stepId: number;
  task: any[];
  unlocks: any[];
};

type Props = {
  casinoData: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: Function;
  isOptedIn: boolean;
};

function getRandomBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const DailyOfferCalendarWidget = ({
  casinoData,
  userProgress,
  setUserProgress,
  isOptedIn,
}: Props) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const [isPopupOpen, setIsPopupOpen] = useState<any>(null);
  const [chosenData, setChosenData] = useState<any>(null);
  const [isPopupVisibility, setIsPopupVisibility] = useState<any>(false);
  casinoData.quest.sort((a, b) => a.stepId - b.stepId);
  const {
    components: { banner },
  } = useContext<any>(ConfigContext);

  const closePopup = () => {
    setIsPopupOpen(null);
    setTimeout(() => setChosenData(null), 1);
  };

  const handleDayClick = (day: Day, locked: boolean) => {
    if (!isOptedIn || locked) return;
    setIsPopupOpen(day);
    setChosenData(day);
  };

  return (
    <div className={styles.dailyOfferGame}>
      <div className={styles.dailyOfferGame__wrapper}>
        <DailyOfferCalendarScrollbar autoHide={false}>
          {casinoData.quest.map((element, index) => {
            const currentQuestProgress = getUserProgressForQuest(userProgress?.quests, element.id);

            return (
              <div
                className={styles.dailyOfferGame__card}
                key={index}
                style={{
                  left: `${index * 200 + 70}px`,
                  // left: `${index * 170 + 30}px`,
                  // [verticalPositionKey]: `${getRandomBetween(3, 10)}%`,
                  animationDelay: `${index * 0.3}s`,
                }}
              >
                <DailyOfferCalendarCard
                  questData={element}
                  progress={currentQuestProgress}
                  cardFaceImages={{
                    locked: banner?.locked?.[0]?.uri,
                    unlocked: banner?.unlocked?.[0]?.uri,
                  }}
                  onClick={handleDayClick}
                />
              </div>
            );
          })}
          <div
            className={styles.dailyOfferGame__track}
            style={{ width: `${casinoData.quest.length * 200 + 170}px` }}
          ></div>
        </DailyOfferCalendarScrollbar>
      </div>
      {width >= 940 ? (
        <Popup
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={
            <p className={styles.title}>
              <BullseyeArrow className={styles.bullsEye} />
              {t("casino.popupTitle")}
            </p>
          }
          className={`${styles.popup} ${isPopupVisibility ? styles.hidden : ""} `}
          headerClassName={styles.popupHeader}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
            setIsPopupVisibility={setIsPopupVisibility}
          />
        </Popup>
      ) : (
        <MobileSlider
          isOpen={isPopupOpen}
          onClose={closePopup}
          title={t("casino.popupTitle")}
          className={styles.mobileSlider}
        >
          <StepMissions
            tasks={chosenData}
            userSegment={userProgress?.segmentId ?? null}
            userProgress={userProgress}
            setUserProgress={setUserProgress}
          />
        </MobileSlider>
      )}
    </div>
  );
};

export default DailyOfferCalendarWidget;
