import i18n from "i18next";
import axios from "axios";

import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en.json";
import translationKA from "./locales/ka.json";
import translationRU from "./locales/ru.json";
import ConfigService from "./services/ConfigService";

const fetchRemoteTranslationFiles = async (supportedLngs: string[]) => {
  supportedLngs.forEach((e) => {
    axios
      .get(`${cdnBaseURL}/promo/${ConfigService.getPromoId()}/${e}.json`)
      .then((res) => {
        i18n.addResourceBundle(e, "translation", res.data);
      })
      .catch((err) => {
        console.log(`couldn't find resources for ${e} language`);
      });
  });
};

const cdnBaseURL = import.meta.env.VITE_WIDGET_CDN_BASE_URL;
const supportedLngs = ["en", "ka", "ru"];
const resources = {
  en: {
    translation: translationEN,
  },
  ka: {
    translation: translationKA,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    resources,
    supportedLngs: supportedLngs,
    fallbackLng: "en",
    detection: {
      order: ["querystring", "path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // debug: true,
  });
// console.log(i18n.options.resources);

fetchRemoteTranslationFiles(supportedLngs);

export default i18n;
