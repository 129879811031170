import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import ConfigService from "@/services/ConfigService.ts";

import { Picks, WeekStatus } from "@/widgets/pickemWidget/pickem.types";
import { MatchPredictionTemplate, MatchStatus } from "../MatchStatuses";

import PlayerPropsCard from "./playerPropsCard/PlayerPropsCard";
import PlayerPropsPoints from "./components/PlayerPropsPoints";
import NameTooltip from "@/components/tooltip/nameTooltip/NameTooltip";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import PageNotFound from "@/components/pageNotFound/PageNotFound";

import CorrectIcon from "./assets/imgs/correct.svg";
import IncorrectIcon from "./assets/imgs/incorrect.svg";
import InProgressIcon from "./assets/imgs/inprogress.svg";

import axios from "axios";
import moment from "moment";
import "moment/dist/locale/ka";
import "moment/dist/locale/ru";
import "moment/dist/locale/es-us";

import styles from "./PlayerProps.module.scss";
import PlayerNames from "@/components/playerNames/PlayerNames";
// import PlayerImages from "@/components/playerImages/PlayerImages";

type Props = {
  matchInfo: any;
  editable: boolean;
  addOptionToArray?: any;
  locked?: boolean;
  weekStatus: WeekStatus;
  userPick?: Picks;
  type: MatchPredictionTemplate | null | undefined;
  selectedOption?: any;

  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
};

type Player = {
  id: string;
  name: string;
  position: string;
  teamId: string;
  teamName: string;
};

// const truncateName = (name: string, maxLength: number): string => {
//   return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
// };

const getPlayersFromTeams = (teams: any): any => {
  if (!teams || teams.length === 0) {
    return [];
  }
  const teamPlayers = teams[0].players;
  if (!teamPlayers) {
    return [];
  }

  return teamPlayers;
};

const PlayerProps = ({
  matchInfo,
  editable,
  locked = false,
  userPick,
  weekStatus,
  addOptionToArray,
  type,
  selectedOption,
  isBooster,
  isBoosted,
  toggleBooster,
}: Props) => {
  const { t } = useTranslation();
  const cdnBaseURL = `${ConfigService.cdnBaseURL}/assets/sport/soccer/team/`;
  const eventInfo = matchInfo?.event;

  const {
    data: data,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["teams", eventInfo?.homeTeamId, eventInfo?.awayTeamId],
    queryFn: async () => {
      const teamIds = [eventInfo?.homeTeamId, eventInfo?.awayTeamId].filter(Boolean);
      const teamDataPromises = teamIds.map((teamId) =>
        axios.get(`${cdnBaseURL}info/${teamId}.json`)
      );
      const teamDataResponses = await Promise.all(teamDataPromises);
      const combinedPlayers = teamDataResponses.flatMap((response) => response.data);
      return [{ players: combinedPlayers }];
    },
    enabled: !!eventInfo?.homeTeamId && !!eventInfo?.awayTeamId,
  });

  const player = getPlayersFromTeams(data).find((p: Player) => p.id === matchInfo.playerId);

  function handleOptionSelect(option: string) {
    addOptionToArray(matchInfo.propId, { value: option });
  }

  const isHistory = useMemo(
    () => [WeekStatus.live, WeekStatus.completed].includes(weekStatus),
    [weekStatus]
  );

  const [negativepick, negativeTranslation, positivePick, positiveTranslation, question] =
    useMemo(() => {
      switch (type) {
        case MatchPredictionTemplate.PLAYER_GOALSCORE:
          return [
            "No",
            t("playerProps.no"),
            "Yes",
            t("playerProps.yes"),
            t("playerProps.goalscore"),
          ];
        case MatchPredictionTemplate.PLAYER_GOAL_OR_ASSIST:
          return [
            "No",
            t("playerProps.no"),
            "Yes",
            t("playerProps.yes"),
            t("playerProps.playerGoalOrAssist"),
          ];
        default:
          const parts = type?.split("_");
          const lastTwoParts = parts?.slice(-2);
          if (lastTwoParts && !isNaN(Number(lastTwoParts[0])) && !isNaN(Number(lastTwoParts[1]))) {
            const label = parts?.slice(0, -2).join("_");
            const number = `${lastTwoParts[0]},${lastTwoParts[1]}`;
            let returnLabel = "";
            switch (label) {
              case "PLAYER_SHOTS_TOTAL_OVER":
                returnLabel = t("playerProps.shotsTotalOver", { number });
                break;
              case "PLAYER_ASSISTS_OVER":
                returnLabel = t("playerProps.assistsOver", { number });
                break;
              case "PLAYER_FOULS_COMMITTED_OVER":
                returnLabel = t("playerProps.foulsCommittedOver", { number });
                break;
              case "PLAYER_FOULS_DRAWN_OVER":
                returnLabel = t("playerProps.faulsDrawnOver", { number });
                break;
              case "PLAYER_DRIBBLE_SUCCESS_OVER":
                returnLabel = t("playerProps.dribbleSuccessOver", { number });
                break;
              case "PLAYER_SHOTS_ON_GOAL_OVER":
                returnLabel = t("playerProps.shotsOnGoalOver", { number });
                break;

              case "PLAYER_GOALS_OVER":
                returnLabel = t("playerProps.goalsOver", { number });
                break;

              case "PLAYER_SAVES_OVER":
                returnLabel = t("playerProps.savesOver", { number });
                break;
            }
            return ["No", t("playerProps.no"), "Yes", t("playerProps.yes"), returnLabel];
          }
          return ["No", t("playerProps.no"), "Yes", t("playerProps.yes")];
      }
    }, [type, weekStatus]);

  function getOptionClassName(option: string) {
    return `${styles.matchBet__option} ${!locked && selectedOption === option ? styles.active : ""} 
    ${isHistory && isMatchFinished && matchInfo.eventValue === option ? styles.correct : ""} ${
      isHistory && isMatchFinished && userPick?.value === option && !userPick?.correct
        ? styles.incorrect
        : ""
    } ${isHistory && userPick?.value === option ? styles.chosenBorder : ""}
    ${isHistory && !isMatchFinished && userPick?.value === option ? styles.inProgress : ""}`;
  }

  const isMatchFinished = useMemo(
    () =>
      eventInfo.homeTeamPoints !== null &&
      !isNaN(eventInfo.homeTeamPoints) &&
      eventInfo.awayTeamPoints !== null &&
      !isNaN(eventInfo.awayTeamPoints),
    [eventInfo]
  );

  const cancelledOrPostponed = useMemo(() => {
    return [MatchStatus.postponed, MatchStatus.cancelled].includes(eventInfo.status);
  }, [eventInfo.status]);
  const showOptions = useMemo(() => {
    if (isHistory) {
      if (userPick && Object.keys(userPick).length > 0) {
        return true;
      }
      return false;
    }
    return weekStatus !== WeekStatus.upcoming;
  }, [userPick, weekStatus]);

  const matchStatus = useMemo(() => {
    if (!isHistory || !userPick) return null;
    if (!isMatchFinished || userPick.correct === null) return "inProgress";
    return userPick.correct ? "correct" : "incorrect";
  }, [isHistory, isMatchFinished, userPick]);

  function renderOptionIcon(option: string) {
    if (!isHistory || !userPick || userPick.value !== option) return null;

    switch (matchStatus) {
      case "inProgress":
        return <img src={InProgressIcon} className={styles.optionIcon} alt="" />;
      case "correct":
        return <img src={CorrectIcon} className={styles.optionIcon} alt="" />;
      case "incorrect":
        return <img src={IncorrectIcon} className={styles.optionIcon} alt="" />;
      default:
        return null;
    }
  }

  if (isError) {
    return <PageNotFound />;
  }
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <PlayerPropsCard
      editable={editable}
      disabled={cancelledOrPostponed}
      isBooster={isBooster}
      isBoosted={isHistory && userPick?.booster ? true : isBoosted}
      toggleBooster={toggleBooster}
      matchStatus={matchStatus}
    >
      <div className={styles.player}>
        {/* <div className={styles.player__img}>
          <PlayerImages player={player} />
        </div>{" "}
        while the player images are hidden */}
        <div className={styles.player__description}>
          <div className={styles.player__header}>
            <h1>
              {/* {player ? truncateName(player.name, 10) : "Unknown Player"} while the player images are hidden */}
              <PlayerNames player={player} language={t("playerProps.playerLanguage")} />
            </h1>
            <div>
              {matchInfo.eventValue == "dnp" ? (
                ""
              ) : (
                <PlayerPropsPoints
                  info={{
                    userPick,
                    point:
                      (isHistory && userPick?.booster) || isBoosted
                        ? matchInfo?.point * 2
                        : matchInfo?.point,
                    isHistory,
                    isMatchFinished,
                  }}
                />
              )}
            </div>
          </div>
          <p>
            {!matchInfo.matchTime
              ? t("playerProps.unknownDate")
              : moment(matchInfo.matchTime).locale(t("dateTime")).format("MMM D, HH:mm ")}
          </p>
          <p>{`${eventInfo?.homeTeamName || "unknown"} - ${eventInfo?.awayTeamName || "unknown"}`}</p>
        </div>
      </div>
      {showOptions && (
        <div className={styles.matchBet__picker}>
          {matchInfo.eventValue == "dnp" ? (
            <div className={styles.postponedWrapper}>
              <div className={styles.postponedBtn}>{t("playerProps.playerNotPlaying")}</div>
            </div>
          ) : cancelledOrPostponed ? (
            <div className={styles.postponedWrapper}>
              <div className={styles.postponedBtn}>
                {MatchStatus.postponed === eventInfo.status
                  ? t("playerProps.postponed")
                  : t("playerProps.cancelled")}
              </div>
            </div>
          ) : (
            <div className={styles.question}>
              <div className={styles.question__type}>
                <h2>{question}</h2>
              </div>
              <div className={styles.question__result}>
                <div
                  className={getOptionClassName(positivePick)}
                  onClick={!locked ? () => handleOptionSelect(positivePick) : undefined}
                >
                  <p>{positiveTranslation}</p>
                  {renderOptionIcon(positivePick)}
                </div>
                <div
                  className={getOptionClassName(negativepick)}
                  onClick={!locked ? () => handleOptionSelect(negativepick) : undefined}
                >
                  <p>{negativeTranslation}</p>
                  {renderOptionIcon(negativepick)}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </PlayerPropsCard>
  );
};

export default PlayerProps;
