import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./Popup.module.scss";
import Close1 from "../logInModal/assets/img/x-close.svg";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
}

const Popup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className = "",
  headerClassName = "",
  contentClassName = "",
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && !isMounted) {
      setIsMounted(true);
      setIsClosing(false);
      setContent(children);
      requestAnimationFrame(() => {
        setIsAnimating(true);
      });
    } else if (!isOpen && isMounted) {
      setIsClosing(true);
      setIsAnimating(false);
    }
  }, [isOpen, isMounted, children]);

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsMounted(false);
      setIsClosing(false);
      setContent(null);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setIsAnimating(false);
    onClose();
  };

  const popupContent = (
    <div className={styles.popupContainer}>
      <div
        className={`${styles.overlay} ${isAnimating && !isClosing ? styles.visible : ""}`}
        onClick={handleClose}
      ></div>
      <div
        ref={popupRef}
        className={`${styles.popup} ${className} ${
          isAnimating ? styles.animateIn : isClosing ? styles.animateOut : ""
        }`}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className={`${styles.header} ${headerClassName}`}>
          {typeof title === "string" ? <p className={styles.headerP}>{title}</p> : title}
          <img src={Close1} onClick={handleClose} alt="Close" />
        </div>
        <div className={`${styles.content} ${contentClassName}`}>{content}</div>
      </div>
    </div>
  );

  return isMounted
    ? createPortal(popupContent, document.getElementById("popup-root") || document.body)
    : null;
};

const createPopupRoot = () => {
  if (!document.getElementById("popup-root")) {
    const rootDiv = document.createElement("div");
    rootDiv.id = "popup-root";
    document.body.appendChild(rootDiv);
  }
};

createPopupRoot();

export default Popup;
