import { useTranslation } from "react-i18next";

import { loadLeaderboard } from "../../../pickem.api";
import { expandArrayByRank, filterRewardsByPeriodType, mergeArrays } from "../../utils";
import { useConfig } from "@/providers/ConfigProvider";

import LoadingPage from "@/components/loadingPage/LoadingPage";
import EmptyState from "@/components/emptyState/EmptyState";
import PrizeTable from "../prizeTable/PrizeTable";

import styles from "./LeaderboardTable.module.scss";

import emptyStateImg from "@/assets/img/newEmpty.svg";
import UserWinnings from "../userWinnings/UserWinnings";

type Props = {
  selectedFilter: any;
};

const LeaderboardTable = ({ selectedFilter }: Props) => {
  const { t } = useTranslation();
  const { reward, rewarding }: any = useConfig();
  const filteredAndExpandedRewards = expandArrayByRank(
    filterRewardsByPeriodType(reward, selectedFilter.type)
  );

  const {
    data: leaderboardData,
    isLoading,
    isError,
  } = loadLeaderboard({
    limit: filteredAndExpandedRewards.length + 100 || 100,
    type: selectedFilter.type,
    partitionKey: selectedFilter.value,
  });

  if (isError) {
    return (
      <EmptyState
        img={emptyStateImg}
        title={t("emptyState.oops")}
        text={t("emptyState.globalWrong")}
      />
    );
  }

  const combinedArray = mergeArrays(leaderboardData?.items ?? [], filteredAndExpandedRewards);

  return (
    <>
      <UserWinnings
        selectedFilter={selectedFilter}
        isManual={false}
        leaderboardData={combinedArray}
      />
      {isLoading ? (
        <LoadingPage />
      ) : (
        <PrizeTable rewardType={rewarding} leaderboardData={combinedArray} />
      )}
    </>
  );
};

export default LeaderboardTable;
