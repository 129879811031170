import { useTranslation } from "react-i18next";

import styles from "./Auth.module.scss";

import warning from "./assets/img/warning.svg";
import { PostUserSignInMsg } from "@/services/PostMessageService.ts";

const handleSignIn = () => {
  window.parent.postMessage(PostUserSignInMsg(), "*");
};

const AuthModalBody = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.authBody}>
      <p className={styles.info}>{t("auth.main")}</p>
      <button className={styles.signInBtn} onClick={handleSignIn}>
        {t("auth.button")}
      </button>
    </div>
  );
};

export default AuthModalBody;
