import { CasinoGameType, CasinoPromoConfig } from "../../casino.types";
import { getCasinoGameType } from "../../utils";

import MatrixWidget from "@/widgets/casinoWidget/subWidgets/matrixWidget/MatrixWidget";
import AdventureMap from "@/widgets/casinoWidget/subWidgets/adventureMapWidget/AdventureMap";
import WheelWidget from "@/widgets/casinoWidget/subWidgets/wheelWidget/WheelWidget";
import LeaderboardGame from "../../subWidgets/leaderboardGame/LeaderboardGame";
import MysteryBoxWidget from "../../subWidgets/mysteryBoxWidget/MysteryBoxWidget";

type Props = {
  casinoData: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: Function;
  isOptedIn: boolean;
};

const GameSwitchRenderer = ({ casinoData, userProgress, setUserProgress, isOptedIn }: Props) => {
  if (!casinoData) return null;

  switch (getCasinoGameType(casinoData.category)) {
    case CasinoGameType.Matrix:
      return (
        <MatrixWidget
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={isOptedIn}
        />
      );

    case CasinoGameType.AdventureMap:
      return (
        <AdventureMap
          data={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={isOptedIn}
        />
      );
    case CasinoGameType.Leaderboard:
      return (
        <LeaderboardGame
          data={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={isOptedIn}
        />
      );
    case CasinoGameType.Wheel:
      return (
        <WheelWidget
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
        />
      );
    case CasinoGameType.MysteryBox:
      return (
        <MysteryBoxWidget
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
        />
      );

    default:
      return null;
  }
};

export default GameSwitchRenderer;
