export function splitMonthAndNumber(monthString: string): [string, number] | null {
  const regex = /^([a-zA-Z]+)(\d+)$/;
  const match = monthString.match(regex);

  if (match) {
    const month: string = match[1];
    const number: number = parseInt(match[2]);
    return [month, number];
  } else {
    return null;
  }
}
