import styles from "./LoadingPage.module.scss";

import loading from "./assets/img/loading.svg";

const LoadingPage = ({ className = "" }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.loading}>
        <img src={loading} alt="loading" />
      </div>
    </div>
  );
};

export default LoadingPage;
