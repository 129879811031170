const Points = ({ color = "#7D52F3", ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.31116 9.81454L4.66671 14.6666L7.72564 12.8312C7.82541 12.7714 7.87529 12.7414 7.92854 12.7297C7.97565 12.7194 8.02443 12.7194 8.07154 12.7297C8.12479 12.7414 8.17467 12.7714 8.27444 12.8312L11.3334 14.6666L10.6896 9.81408M12.6667 5.99992C12.6667 8.57725 10.5774 10.6666 8.00004 10.6666C5.42271 10.6666 3.33337 8.57725 3.33337 5.99992C3.33337 3.42259 5.42271 1.33325 8.00004 1.33325C10.5774 1.33325 12.6667 3.42259 12.6667 5.99992Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Points;
