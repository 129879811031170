import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./MobileSlider.module.scss";
import { CloseIcon } from "@/assets/svg";

interface MobileSliderProps {
  isOpen: any;
  onClose: () => void;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  className?: string;
  hasGameNames?: boolean;
}

const MobileSlider: React.FC<MobileSliderProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className = "",
  hasGameNames = false,
}) => {
  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rootDiv = document.createElement("div");
    document.body.appendChild(rootDiv);
    setRootElement(rootDiv);

    return () => {
      document.body.removeChild(rootDiv);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = "hidden";
      setIsVisible(true);
      requestAnimationFrame(() => {
        setTimeout(() => {
          setIsTransitioning(true);
        }, 50);
      });
    } else {
      setIsTransitioning(false);
    }
    return () => {
      document.documentElement.style.overflowY = "scroll";
    };
  }, [isOpen]);

  const handleTransitionEnd = () => {
    if (!isOpen) {
      setIsVisible(false);
    }
  };

  if (!rootElement) {
    return null;
  }

  return createPortal(
    <>
      {isVisible && (
        <div
          className={`${styles.popupContainer} ${import.meta.env.VITE_WIDGET_TOP_NAVBAR_ENABLED === "true" ? "" : styles.overNav}`}
        >
          <div
            className={`${styles.overlay} ${isTransitioning ? styles.visible : ""}`}
            onClick={onClose}
          ></div>
          <div
            ref={sliderRef}
            className={`${styles.mobileSlider}  ${className} ${isTransitioning ? styles.open : ""}`}
            onTransitionEnd={handleTransitionEnd}
          >
            <div className={hasGameNames ? styles.gameNamesHeader : styles.header}>
              <p>{title}</p>
              <CloseIcon className={styles.closeIcon} onClick={onClose} />
            </div>
            {children}
          </div>
        </div>
      )}
    </>,
    rootElement
  );
};

export default MobileSlider;
