import styles from "./CircularProgressBar.module.scss";

interface CircularProgressBarProps {
  current: number;
  total: number;
  size?: number;
  strokeWidth?: number;
  strokeColor?: string;
  backgroundColor?: string;
  className?: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  current = 0,
  total = 0,
  size = 100,
  strokeWidth = 10,
  strokeColor = "green",
  backgroundColor = "gray",
  className = "",
}) => {
  const percentage = (current / total) * 100;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <div
      className={`${styles.circularProgressBar} ${className}`}
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className={styles.svgCircle}>
        <circle
          className={styles.backgroundCircle}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          stroke={backgroundColor}
          fill="#444"
        />
        <circle
          className={styles.foregroundCircle}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          stroke={strokeColor}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percentage / 100) * circumference}
        />
      </svg>
      <span className={styles.progressText}>
        {current}/{total}
      </span>
    </div>
  );
};

export default CircularProgressBar;
