import moment from "moment";
import { LeaderBoardType } from "../pickemWidget/pickem.types";
import { CasinoGameType, CasinoQuestProgress, ProductCategoryEnum } from "./casino.types";

export const getCasinoGameType = (
  casinoProductCategory: ProductCategoryEnum
): CasinoGameType | null => {
  switch (casinoProductCategory) {
    case ProductCategoryEnum.Matrix_2X_2:
    case ProductCategoryEnum.Matrix_3X_3:
    case ProductCategoryEnum.Matrix_4X_4:
      return CasinoGameType.Matrix;
    case ProductCategoryEnum.AdventureMapWith_5Quests:
    case ProductCategoryEnum.AdventureMapWith_10Quests:
    case ProductCategoryEnum.AdventureMapWith_15Quests:
    case ProductCategoryEnum.AdventureMapWith_20Quests:
    case ProductCategoryEnum.AdventureMapWith_25Quests:
    case ProductCategoryEnum.AdventureMapWith_30Quests:
      return CasinoGameType.AdventureMap;
    case ProductCategoryEnum.DailyCalendar:
      return CasinoGameType.DailyOfferCalendar;
    case ProductCategoryEnum.LeaderboardCumulative:
    case ProductCategoryEnum.LeaderboardHighestMultiplier:
      return CasinoGameType.Leaderboard;
    case ProductCategoryEnum.Wheel_4_Section:
    case ProductCategoryEnum.Wheel_6_Section:
    case ProductCategoryEnum.Wheel_8_Section:
    case ProductCategoryEnum.Wheel_10_Section:
    case ProductCategoryEnum.Wheel_12_Section:
      return CasinoGameType.Wheel;
    //@ts-ignore
    case "MYSTERY_BOX":
      return CasinoGameType.MysteryBox;
    default:
      return null;
  }
};

export const getHeaderBannerStatus = (productCategory: ProductCategoryEnum): boolean => {
  let hasBanner = true;
  switch (productCategory) {
    case ProductCategoryEnum.Matrix_2X_2:
    case ProductCategoryEnum.Matrix_3X_3:
    case ProductCategoryEnum.Matrix_4X_4:
    case ProductCategoryEnum.AdventureMapWith_5Quests:
    case ProductCategoryEnum.AdventureMapWith_10Quests:
    case ProductCategoryEnum.AdventureMapWith_15Quests:
    case ProductCategoryEnum.AdventureMapWith_20Quests:
    case ProductCategoryEnum.AdventureMapWith_25Quests:
    case ProductCategoryEnum.AdventureMapWith_30Quests:
    case ProductCategoryEnum.DailyCalendar:
      hasBanner = false;
      break;
    default:
      hasBanner = true;
      break;
  }
  return hasBanner;
};

export const splitRewards = (
  questsSet: any[]
): {
  rowRewards: any[];
  columnRewards: any[];
} => {
  const isSequential = (questIds: any[]) =>
    questIds.every((id, index) => index === 0 || id === questIds[index - 1] + 1);

  const rowRewards: any[] = [];
  const columnRewards: any[] = [];

  questsSet.forEach((quest) => {
    if (isSequential(quest.questIds)) {
      rowRewards.push(quest);
    } else {
      columnRewards.push(quest);
    }
  });

  rowRewards.sort((a, b) => a.questIds[0] - b.questIds[0]);
  columnRewards.sort((a, b) => a.questIds[0] - b.questIds[0]);

  return { rowRewards, columnRewards };
};

// This function returns a reward for that specific tile, if the user has any
export const getUserProgressForQuest = (
  userQuestProgress: any[],
  taskId: number
): CasinoQuestProgress | null => {
  //
  if (!userQuestProgress || !Array.isArray(userQuestProgress))
    return {
      status: "NOPROGRESS",
      currentProgress: null,
      maxProgress: null,
      needsClaiming: false,
    };

  const filteredUserProgress = userQuestProgress.filter((quest) => quest.questId === taskId)[0];

  if (!filteredUserProgress) {
    return {
      status: "NOPROGRESS",
      currentProgress: null,
      maxProgress: null,
      needsClaiming: false,
    };
  } else {
    const currProgress = filteredUserProgress.tasks.filter(
      (task: any) => task.status === "COMPLETED"
    ).length;
    const maxProgress = filteredUserProgress.tasks.length;
    const questRewardNeedsClaiming =
      !!filteredUserProgress?.award && !filteredUserProgress?.award?.claimed;

    let taskRewardNeedsClaiming = false;
    filteredUserProgress?.tasks?.forEach((task: any) => {
      if (task.award && !task.award.claimed) {
        taskRewardNeedsClaiming = true;
      }
    });
    if (currProgress === maxProgress) {
      return {
        status: "COMPLETED",
        currentProgress: currProgress,
        maxProgress: maxProgress,
        needsClaiming: questRewardNeedsClaiming || taskRewardNeedsClaiming,
      };
    } else {
      return {
        status: "INPROGRESS",
        currentProgress: currProgress,
        maxProgress: maxProgress,
        needsClaiming: questRewardNeedsClaiming || taskRewardNeedsClaiming,
      };
    }
  }
};

export const getUserProgressForQuestSets = (
  userQuestSetProgress: any[],
  taskId: number
): CasinoQuestProgress | null => {
  //
  if (!userQuestSetProgress || !Array.isArray(userQuestSetProgress))
    return {
      status: "NOPROGRESS",
      currentProgress: 0,
      maxProgress: null,
      needsClaiming: false,
    };

  const filteredUserProgress = userQuestSetProgress.filter((questSet) => questSet.id === taskId)[0];

  if (!filteredUserProgress) {
    return {
      status: "NOPROGRESS",
      currentProgress: null,
      maxProgress: null,
      needsClaiming: false,
    };
  } else {
    const currProgress = Object.values(filteredUserProgress.uncompletedQuests).filter(
      (quest: any) => quest
    ).length;
    const maxProgress = Object.values(filteredUserProgress.uncompletedQuests).length;

    const questSetRewardNeedsClaiming =
      !!filteredUserProgress?.award && !filteredUserProgress?.award?.claimed;

    if (currProgress === maxProgress) {
      return {
        status: "COMPLETED",
        currentProgress: currProgress,
        maxProgress: maxProgress,
        needsClaiming: questSetRewardNeedsClaiming,
      };
    } else {
      return {
        status: "INPROGRESS",
        currentProgress: currProgress,
        maxProgress: maxProgress,
        needsClaiming: questSetRewardNeedsClaiming,
      };
    }
  }
};

export const splitArrayIntoGrid = (array: any[], size: number): any[][] => {
  if (size <= 0) {
    throw new Error("Size must be a positive integer");
  }

  const grid: any[][] = [];

  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    grid.push(chunk);
  }

  return grid;
};

interface PeriodOption {
  value: string;
  label: string;
  periodType?: string;
  id?: number | string;
}

export const getPeriodsFromCasinoData = (data: any, rewards: any, t: any): PeriodOption[] => {
  let periodArray: PeriodOption[] = [];

  const sortedQuests = [...data.quest].sort((a, b) => a.stepId - b.stepId);

  const formatMonth = (date: moment.Moment): string => {
    let month = date.format("MMM").toUpperCase();
    if (month.endsWith(".")) {
      month = month.slice(0, -1);
    }
    return t(`pickemWidget.months.${month}`);
  };

  const formatSingleDate = (date: moment.Moment): string => {
    return `${formatMonth(date)} ${date.format("D")}`;
  };

  const formatDateRange = (startDate: moment.Moment, endDate: moment.Moment): string => {
    const firstMonth = formatMonth(startDate);
    const lastMonth = formatMonth(endDate);
    const firstNumber = startDate.format("D");
    const lastNumber = endDate.format("D");

    return `${firstMonth} ${firstNumber} - ${lastMonth} ${lastNumber}`;
  };

  switch (data.periodType) {
    case LeaderBoardType.TIMEBOUND:
    case LeaderBoardType.MONTHLY:
    case LeaderBoardType.WEEKLY:
      periodArray = sortedQuests.map((quest: any) => ({
        value: quest.id.toString(),
        label: formatDateRange(moment(quest.startDate), moment(quest.endDate)),
        periodType: data.periodType,
        id: quest.id,
      }));
      break;
    case LeaderBoardType.DAILY:
      periodArray = sortedQuests.map((quest: any) => ({
        value: quest.id.toString(),
        label: formatSingleDate(moment(quest.startDate)),
        periodType: data.periodType,
        id: quest.id,
      }));

      break;
    default:
      break;
  }

  const overallReward = rewards.find((obj: any) => obj.period === LeaderBoardType.OVERALL);

  if (
    data.periodType !== LeaderBoardType.TIMEBOUND &&
    (periodArray.length === 0 || overallReward)
  ) {
    periodArray.push({
      value: data.promoRef,
      label: t("leaderboardWidget.overall"),
      periodType: LeaderBoardType.TIMEBOUND,
    });
  }

  return periodArray;
};
