import { useContext, useEffect, useState, useMemo } from "react";
import ReactSlider from "react-slider";

import { WeekStatus } from "@/widgets/pickemWidget/pickem.types";

import styles from "./TieBreaker.module.scss";

import ball from "./assets/img/ball.svg";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { useTranslation } from "react-i18next";

import { AditionalQuestionEnum } from "@/widgets/pickemWidget/pickem.types";
import InfoCircle from "@/assets/svg/InfoCircle";
import Tooltip from "@/components/tooltip/Tooltip";

type Props = {
  weekStatus: WeekStatus;
  isLocked: boolean;
  isEditable: boolean;
  className?: string;
  addOptionToArray?: Function;
  matchInfo: any;
  defaultPick?: any;
  userPick?: any;
  sliderValue?: number | string;
};

const TieBreaker = ({
  weekStatus,
  isEditable,
  addOptionToArray,
  matchInfo,
  className = "",
  userPick,
  sliderValue = "- -",
}: Props) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { t } = useTranslation();
  const minSliderValue = 0;
  const maxSliderValue = 100;

  const [tiebreakerQuestion, answerSuffix] = useMemo(() => {
    switch (matchInfo?.questionTemplate) {
      case AditionalQuestionEnum.HOW_MANY_GOALS:
        return [t("tieBreaker.question"), t("tieBreaker.goals")];
      case AditionalQuestionEnum.HOW_MANY_YELLOW:
        return [t("tieBreaker.yellowCards"), t("tieBreaker.cards")];
      default:
        return [t("tieBreaker.question"), t("tieBreaker.goals")];
    }
  }, [matchInfo]);

  const changeSliderValue = (value: any) => {
    if (isNaN(value)) {
      addOptionToArray && addOptionToArray(matchInfo.propId, { value: "0" });
      return;
    }
    const numericValue = Number(value);

    if (numericValue < 0) {
      // setSliderValue(0);
      addOptionToArray && addOptionToArray(matchInfo.propId, { value: "0" });
      return;
    }

    if (numericValue < minSliderValue || numericValue > maxSliderValue) {
      return;
    }

    addOptionToArray && addOptionToArray(matchInfo.propId, { value: numericValue.toString() });
  };

  const headerContent = (userPick: any, weekStatus: WeekStatus) => {
    if (weekStatus === WeekStatus.completed || weekStatus === WeekStatus.live) {
      {
        authContext.isLoggedIn && userPick && Object.keys(userPick).length > 0 ? (
          <div className={styles.tieBreaker__header}>
            <p>
              {t("tieBreaker.prediction")}: {userPick.prediction || "None"}
            </p>
            <span className={`${styles.svgTarget} target`}>
              <InfoCircle />
            </span>
            <Tooltip targetClassName={`target`}>
              <div className={styles.tooltip}>
                <p>{t("tieBreaker.tooltip")}</p>
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className={styles.tieBreaker__header}>
            <p>{tiebreakerQuestion}</p>
            <span className={`${styles.svgTarget} target`}>
              <InfoCircle />
            </span>
            <Tooltip targetClassName={`target`}>
              <div className={styles.tooltip}>
                <p>{t("tieBreaker.tooltip")}</p>
              </div>
            </Tooltip>
          </div>
        );
      }
    }
    return (
      <div className={styles.tieBreaker__header}>
        <p> {tiebreakerQuestion}</p>
        <span className={`${styles.svgTarget} target`}>
          <InfoCircle />
        </span>
        <Tooltip targetClassName={`target`}>
          <div className={styles.tooltip}>
            <p>{t("tieBreaker.tooltip")}</p>
          </div>
        </Tooltip>
      </div>
    );
  };
  const midContent = (userPick: any, weekStatus: WeekStatus) => {
    if (weekStatus === WeekStatus.completed || weekStatus === WeekStatus.live) {
      return (
        <div className={styles.tieBreaker__result}>
          <div className={styles.tieBreaker__box}>
            <img src={ball} alt="point image" />
            {authContext.isLoggedIn && userPick && Object.keys(userPick).length > 0 ? (
              <h5>
                {userPick.value} {answerSuffix}
              </h5>
            ) : (
              <h5>No goals have been submitted</h5>
            )}
          </div>

          <div
            className={`${styles.tieBreaker__status} ${
              userPick?.correct ? styles.correct : styles.incorrect
            }`}
          >
            <h5>
              {userPick?.correct ? t("matchBetResult.correct") : t("matchBetResult.incorrect")}
            </h5>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.tieBreaker__value}>
        {/* <span className={styles.tieBreaker__value_amount}>{sliderValue}</span> */}
        <input
          type="text"
          className={styles.tieBreaker__value_amount}
          min={minSliderValue}
          max={maxSliderValue}
          value={sliderValue}
          onChange={(e) => changeSliderValue(e.target.value)}
        />

        <span className={styles.tieBreaker__value_text}>{answerSuffix}</span>
      </div>
    );
  };
  const matchResults = (weekStatus: WeekStatus) => {
    return (
      <div className={styles.matchPoints}>
        <div className={styles.matchPoints__result}>
          <p>{t("dualChoice.result")}</p>
          <div className={styles.goalResult}>
            <img src={ball} />
            <h3>
              {matchInfo.eventValue >= 0 ? (
                <>
                  {matchInfo.eventValue} <span className={styles.goalCount}> {answerSuffix}</span>
                </>
              ) : (
                "-"
              )}
            </h3>
          </div>
        </div>
        <div className={styles.matchPoints__center}>
          <p
            className={`${styles.mainStatus} ${weekStatus === WeekStatus.completed ? styles.completed : styles.inProgress}`}
          >
            {weekStatus === WeekStatus.completed
              ? t("historyWidget.weekStatus.completed")
              : t("historyWidget.weekStatus.inProgress")}
          </p>
          <div className={styles.matchPoints__tooltip}>
            <h3>{tiebreakerQuestion}</h3>
            <span className={`${styles.svgTarget} target`}>
              <InfoCircle />
            </span>
            <Tooltip targetClassName={`target`}>
              <div className={styles.tooltip}>
                <p>{t("tieBreaker.tooltip")}</p>
              </div>
            </Tooltip>
          </div>
        </div>
        <p
          className={`${styles.mobileStatus} ${weekStatus === WeekStatus.completed ? styles.completed : styles.inProgress}`}
        >
          {weekStatus === WeekStatus.completed
            ? t("historyWidget.weekStatus.completed")
            : t("historyWidget.weekStatus.inProgress")}
        </p>
        <div className={styles.matchPoints__result}>
          <p>{t("tieBreaker.prediction")}</p>
          <div className={styles.goalResult}>
            <img src={ball} />
            <h3>
              {userPick?.value} <span className={styles.goalCount}>{answerSuffix}</span>
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const sliderContent = (weekStatus: WeekStatus) => {
    if (weekStatus === WeekStatus.completed || weekStatus === WeekStatus.live) {
      return;
    }
    return (
      <ReactSlider
        className={styles.tieBreaker__slider}
        thumbClassName={styles.tieBreaker__slider_thumb}
        trackClassName={styles.tieBreaker__slider_track}
        value={Number(sliderValue) >= 0 ? Number(sliderValue) : 0}
        disabled={!isEditable}
        min={minSliderValue}
        max={maxSliderValue}
        renderThumb={(props) => (
          <div {...props}>
            <img src={ball} />
          </div>
        )}
        onChange={(value) => {
          changeSliderValue(value);
        }}
      />
    );
  };

  return (
    <div className={`${styles.tieBreaker} ${className} `}>
      {weekStatus === WeekStatus.completed || weekStatus === WeekStatus.live ? (
        matchResults(weekStatus)
      ) : (
        <>
          {headerContent(userPick, weekStatus)}
          <div className={`${styles.tieBreaker__wrapper} ${!isEditable ? styles.disabled : ""}`}>
            {midContent(userPick, weekStatus)}
            {sliderContent(weekStatus)}
          </div>
        </>
      )}
    </div>
  );
};

export default TieBreaker;
