import styles from "./Banner.module.scss";

type Props = {
  uri: string;
};

const Banner = ({ uri }: Props) => {
  return (
    <div className={styles.banner}>
      <img src={uri} alt="leaderboard banner" />
    </div>
  );
};

export default Banner;
