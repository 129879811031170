import { useMemo, useEffect, useContext, useLayoutEffect, useRef, ReactNode } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ConfigContext } from "@/providers/ConfigProvider.tsx";
import { loadPickem, loadSoccerTeamNames } from "../pickemWidget/pickem.api.ts";
import { PromotionStatusEnum, WeekStatus } from "../pickemWidget/pickem.types.ts";
import useWeekTranslations from "@/hooks/useWeekTranslations.ts";
import useViewportSize from "@/hooks/useViewportSize";

import LeaderboardWidget from "../pickemWidget/leaderboard/LeaderboardWidget";
import HistoryWidget from "../pickemWidget/history/HistoryWidget";
import PickemWidget from "../pickemWidget/pickem/PickemWidget.tsx";
import FaqWidget from "../pickemWidget/faq/FAQWidget";
import SidebarWidget from "../pickemWidget/sidebar/SidebarWidget";
import PageNotFound from "../../components/pageNotFound/PageNotFound";
import BannerSlider from "../../components/bannerSlider/BannerSlider";
import Navbar from "../../components/navbar/Navbar";
import LoadingPage from "../../components/loadingPage/LoadingPage";

import styles from "./WidgetApp.module.scss";
import { trackTabChange } from "@/services/TagManagerService.ts";

type WrapperProps = {
  children: ReactNode;
};

type ScrollPositions = {
  [key: string]: number;
};

const translatePickemDataObject = (originalObject: any, teamNames: any, lng: string) => {
  const transformedObject = { ...originalObject };

  transformedObject?.weekPools?.forEach((weekPool: any) => {
    weekPool.props.forEach((prop: any) => {
      if (prop.event) {
        const { awayTeamId, awayTeamName, homeTeamId, homeTeamName } = prop.event;
        prop.event.awayTeamName = teamNames?.[awayTeamId]?.[lng] || awayTeamName;
        prop.event.homeTeamName = teamNames?.[homeTeamId]?.[lng] || homeTeamName;
      }
    });
  });
  return transformedObject;
};

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const location = useLocation();
  const scrollPositions = useRef<ScrollPositions>({});

  useLayoutEffect(() => {
    const saveScrollPosition = () => {
      scrollPositions.current[location.pathname] = window.scrollY;
    };

    const restoreScrollPosition = () => {
      const savedPosition = scrollPositions.current[location.pathname];
      if (savedPosition !== undefined) {
        window.scrollTo(0, savedPosition);
      }
    };

    window.addEventListener("beforeunload", saveScrollPosition);
    restoreScrollPosition();

    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
      saveScrollPosition();
    };
  }, [location.pathname]);

  return <>{children}</>;
};

const WidgetApp = () => {
  const promoConfig = useContext<any>(ConfigContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const { weekLabelsTransformer } = useWeekTranslations();
  const { data: pickemData, isLoading: isPickemLoading, isError: isPickemError } = loadPickem();
  const {
    data: soccerTeamNames,
    isLoading: isSoccerTeamNameLoading,
    isError: isSoccerTeamNameError,
  } = loadSoccerTeamNames();
  const transformedPickemData = useMemo(() => weekLabelsTransformer(pickemData), [pickemData]);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("pickem")) {
      trackTabChange("pickem");
    } else if (currentPath.includes("leaderboard")) {
      trackTabChange("leaderboard");
    } else if (currentPath.includes("history")) {
      trackTabChange("history");
    } else if (currentPath.includes("faq")) {
      trackTabChange("faq");
    }
  }, [location]);

  useEffect(() => {
    function checkPickemCompletion() {
      if (PromotionStatusEnum.Completed === pickemData?.status && pickemData?.status) {
        navigate(`/f2p/game/history${window.location.search}`);
        return null;
      }
      let pickemWeeks = pickemData.weekPools.filter(
        (obj: { status: string }) =>
          obj.status !== WeekStatus.completed && obj.status !== WeekStatus.live
      );
      if (pickemWeeks.length === 0) {
        navigate(`/f2p/game/history${window.location.search}`);
      }
    }
    if (pickemData) {
      checkPickemCompletion();
    }
  }, [pickemData]);

  if (isPickemError || isSoccerTeamNameError) {
    return <div>Something went wrong...</div>;
  }
  if (isPickemLoading || isSoccerTeamNameLoading) {
    return <LoadingPage />;
  }

  const translatedPickemData = translatePickemDataObject(
    transformedPickemData,
    soccerTeamNames,
    i18n.language
  );
  const banners = promoConfig.components.banner ? promoConfig.components.banner[i18n.language] : [];

  return (
    <>
      {/* <div className="container"> */}
      <BannerSlider banners={banners} />
      {/* </div> */}
      <Navbar />
      <div className={styles.background__anchor}>
        <div className="container">
          <div className={styles.container}>
            <div className={styles.primary}>
              <Wrapper>
                <Routes>
                  <Route
                    path="pickem"
                    element={<PickemWidget pickemData={translatedPickemData} />}
                  />
                  <Route
                    path="leaderboard"
                    element={<LeaderboardWidget pickemData={translatedPickemData} />}
                  />
                  <Route
                    path="history"
                    element={<HistoryWidget pickemData={translatedPickemData} />}
                  />
                  <Route path="faq" element={<FaqWidget />} />

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Wrapper>
            </div>
            {width > 940 && (
              <div className={styles.side}>
                <SidebarWidget
                  promoData={{
                    startDate: translatedPickemData.startDate,
                    endDate: translatedPickemData.endDate,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.background__img}></div>
      </div>
    </>
  );
};

export default WidgetApp;
