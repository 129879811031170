import styles from "./ConfirmationModal.module.scss";
import { useTranslation } from "react-i18next";
import Popup from "../popup/Popup";

type Props = {
  title?: string;
  description?: string | React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  visible?: any;
};

const ConfirmationModal = ({
  title = "",
  description = "",
  onCancel,
  onConfirm,
  visible,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Popup
      isOpen={visible}
      onClose={onCancel}
      className={styles.small}
      headerClassName={styles.noHeader}
    >
      <div className={styles.body}>
        <div className={styles.modal}>
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
        <div className={styles.buttons}>
          <button className={styles.buttons__cancel} onClick={onCancel}>
            {t("matchBet.cancelModal")}
          </button>
          <button className={styles.buttons__confirm} onClick={onConfirm}>
            {t("matchBet.confirmModal")}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmationModal;
