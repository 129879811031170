export function getConditionTitle(task: any, category: any, t: any) {
  if (category === "LEADERBOARD_HIGHEST_MULTIPLIER") {
    switch (task.taskType) {
      case "Win_multiplier":
        return t("casino.winMultiplierHighest");
      case "Win_in_a_row":
        return t("casino.winInARowHighest");
      case "Lose_in_a_row":
        return t("casino.loseInARowHighest");
      default:
    }
  } else {
    const point = task?.checkpoints?.[0]?.point ?? 0;
    switch (task.taskType) {
      case "Spin":
        return t("casino.cumulativeSpinTask", { amount: task?.count, points: point });
      case "Bet":
        return t("casino.cumulativeBetTask", {
          amount: task?.sumAmount,
          currency: task?.currency,
          points: point,
        });
      case "Win":
        return t("casino.cumulativeWinTask", {
          amount: task?.sumAmount,
          currency: task?.currency,
          points: point,
        });
      case "Win_multiplier":
        return t("casino.cumulativeWinMultiplierTask", { amount: task.minAmount, points: point });
      case "Win_in_a_row":
        return t("casino.cumulativeWinInARowTask", { amount: task?.count, points: point });
      case "Lose_in_a_row":
        return t("casino.cumulativeLoseInARowTask", { amount: task?.count, points: point });
      case "Deposit":
        return t("casino.cumulativeDepositTask", {
          amount: task.minAmount,
          currency: task?.currency,
          points: point,
        });
      case "Login":
        return t("casino.cumulativeLoginTask", { amount: task?.count, points: point });
      default:
        return "";
    }
  }
}
