import { useContext, useState } from "react";

import { CasinoPromoConfig } from "../casino.types";

import PromotionRules from "../components/promotionRules/PromotionRules";
import DailyOfferCalendarWidget from "@/widgets/casinoWidget/subWidgets/dailyOfferCalendarWidget/DailyOfferCalendarWidget";
import Popup from "@/components/popup/Popup";
import CasinoHeader from "../components/casinoHeader/CasinoHeader";

import styles from "./CasinoLayout.module.scss";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { useOptInStatus } from "../casino.api";
import LoadingPage from "@/components/loadingPage/LoadingPage";

type Props = {
  casinoData: CasinoPromoConfig;
  userProgress: any;
  setUserProgress: Function;
  isOptedIn: boolean;
};

const CasinoLayoutB = ({ casinoData, userProgress, setUserProgress, isOptedIn }: Props) => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { data: optInStatus, isLoading: isOptInStatusLoading } = useOptInStatus(
    authContext,
    casinoData?.optIn && authContext.isLoggedIn
  );

  const isLoggedIn = authContext.isLoggedIn;

  if (isOptInStatusLoading) {
    return <LoadingPage />;
  }
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  return (
    <div className={styles.casinoLayoutB}>
      <div className={styles.container}>
        <div className={styles.header}>
          <CasinoHeader casinoData={casinoData} />
        </div>
      </div>
      <div
        className={`${styles.game} ${styles.body} ${!isOptedIn || !isLoggedIn ? styles.unavailable : ""}`}
      >
        <DailyOfferCalendarWidget
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={isOptedIn}
        />
      </div>
      <Popup
        isOpen={isRulesModalOpen}
        onClose={() => {
          setIsRulesModalOpen(false);
        }}
      >
        <PromotionRules />
      </Popup>
    </div>
  );
};

export default CasinoLayoutB;
