import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { loadLeaderboardUser } from "../../../pickem.api";
import { expandArrayByRank, filterRewardsByPeriodType } from "../../utils";
import { useConfig } from "@/providers/ConfigProvider";

import styles from "./UserWinnings.module.scss";

type Props = {
  selectedFilter: any;
  isManual?: boolean;
  userData?: any;
  leaderboardData?: any;
};

const Winnings = ({
  selectedFilter,
  isManual = false,
  userData: passedUserData,
  leaderboardData,
}: Props) => {
  const { t } = useTranslation();
  const authContext = useContext<AuthContextProps>(AuthContext);
  const config: any = useConfig();

  let userData = {
    playerId: "",
    rank: "",
    score: 0,
    username: "",
    reward: "-",
  };

  const { data: myPositionData } = loadLeaderboardUser({
    type: selectedFilter.type,
    partitionKey: selectedFilter.value,
    playerId: authContext.getCurrentUser?.id,
    context: authContext,
    enabled: !isManual,
  });

  if (myPositionData) {
    userData = myPositionData;
    if (userData.rank) {
      const rewards = expandArrayByRank(
        filterRewardsByPeriodType(config.reward, selectedFilter.type)
      );
      const reward = rewards.filter((reward) => parseInt(reward.rank) === parseInt(userData.rank));
      if (reward.length > 0) {
        userData.reward = reward[0].name;
      } else {
        userData.reward = "-";
      }
    } else {
      userData.reward = "-";
    }
  }

  if (!isManual) {
    const leaderboardValue = leaderboardData.filter((e: any) => {
      return e.playerId === userData.playerId;
    })[0]?.name;
    userData.reward = leaderboardValue;
  }

  if (isManual && passedUserData) {
    userData.score = passedUserData.score;
    userData.reward = passedUserData.name;
  }

  return (
    <div className={styles.winnings}>
      {!isManual && (
        <div className={styles.winnings__header}>
          <p>{t("leaderboardWinnings.header")}</p>
        </div>
      )}
      <div className={styles.winnings__colorWrapper}>
        {isManual ? (
          <div className={`${styles.winnings__column} ${styles.centered}`}>
            <div className={styles.winnings__box}>
              <p>{t("leaderboardWinnings.header")}</p>
            </div>
          </div>
        ) : (
          <div className={styles.winnings__column}>
            <div className={styles.winnings__box}>
              <div className={`${styles.winnings__icon} ${styles.trophy}`}></div>
              <p>{t("leaderboardWinnings.position")}</p>
            </div>
            <p># {userData.rank || "-"}</p>
          </div>
        )}
        <div className={styles.winnings__column}>
          <div className={styles.winnings__box}>
            <div className={`${styles.winnings__icon} ${styles.points}`}></div>
            {!isManual ? (
              <p>{t("leaderboardWinnings.points")}</p>
            ) : (
              <p>{t("leaderboardWinnings.correctAnswer")}</p>
            )}
          </div>
          <p>{Math.floor(userData.score) || "-"}</p>
        </div>
        <div className={styles.winnings__column}>
          <div className={styles.winnings__box}>
            <div className={`${styles.winnings__icon} ${styles.prizeBox}`}></div>
            <p>{t("leaderboardWinnings.prize")}</p>
          </div>
          {<p>{userData.reward || "-"}</p>}
        </div>
      </div>
    </div>
  );
};

export default Winnings;
