const SelectedPlayer = ({ color = "#7D52F3", ...props }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Icon"
        d="M10.6666 14.9017V14.1017C10.6666 12.9816 10.6666 12.4215 10.4487 11.9937C10.2569 11.6174 9.95095 11.3114 9.57463 11.1197C9.1468 10.9017 8.58675 10.9017 7.46665 10.9017H4.53331C3.41321 10.9017 2.85316 10.9017 2.42533 11.1197C2.04901 11.3114 1.74305 11.6174 1.5513 11.9937C1.33331 12.4215 1.33331 12.9816 1.33331 14.1017V14.9017M10.6666 4.90169L12 6.23503L14.6666 3.56836M8.33331 5.90169C8.33331 7.19036 7.28864 8.23503 5.99998 8.23503C4.71132 8.23503 3.66665 7.19036 3.66665 5.90169C3.66665 4.61303 4.71132 3.56836 5.99998 3.56836C7.28864 3.56836 8.33331 4.61303 8.33331 5.90169Z"
        stroke="#7D52F3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SelectedPlayer;
