import { useCallback, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { setToast } from "@/components/toast/Toast.tsx";

import ConfigService from "@/services/ConfigService.ts";

import styles from "./SubmitProgress.module.scss";

import { saveUserPicks } from "../../pickem.api.ts";

import { AuthContext, AuthContextProps } from "@/providers/AuthProvider.tsx";
import { PostUserSignInMsg } from "@/services/PostMessageService.ts";
import ConfirmationModal from "@/components/confirmationModal/ConfirmationModal.tsx";
import { trackSubmitPickem } from "@/services/TagManagerService.ts";

type SelectedOption = {
  value: string;
};

type Props = {
  progress: number;
  maxProgress: number;
  toggleLogInModal: VoidFunction;
  selectedOptions: {
    [key: string]: SelectedOption;
  };
  hasUserPicks: boolean;
  setIsRoundEditable: (isEditable: boolean) => void;
  weekId: string;
  boostedPropId?: number | null;
  isBooster: boolean;
};

const Submit = ({
  progress = 0,
  maxProgress = 10,
  toggleLogInModal,
  selectedOptions,
  hasUserPicks,
  setIsRoundEditable,
  weekId,
  boostedPropId,
  isBooster,
}: Props) => {
  const [isEditing, setisEditing] = useState(!hasUserPicks);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const authContext = useContext<AuthContextProps>(AuthContext);

  const { mutateAsync: savePicksMutation } = useMutation({
    mutationFn: saveUserPicks,
    onSuccess: (res: any) => {
      if (res.data.error) {
        trackSubmitPickem(false);
        setToast("error", t("submitProgress.error"));
      } else {
        trackSubmitPickem(true);
        setToast("success", t("submitProgress.success"));
        setisEditing(false);
        setIsRoundEditable(false);
      }
      localStorage.removeItem(weekId);
    },
    onError: () => {
      trackSubmitPickem(false);
    },
  });

  const submitPicks = () => {
    if (progress === maxProgress) {
      if (authContext.isLoggedIn) {
        savePicksMutation({
          promoId: ConfigService.getPromoId(),
          selectedOptions: selectedOptions,
          context: authContext,
        });
      }
    }
  };

  const editPicks = () => {
    setisEditing(true);
    setIsRoundEditable(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleConfirm = () => {
    submitPicks();
    setIsModalOpen(false);
  };

  const signInButton = (
    <button
      className={`${styles.submit__button} ${styles.active}`}
      onClick={() => window.parent.postMessage(PostUserSignInMsg(), "*")}
    >
      {t("submitProgress.button.signIn")}
    </button>
  );

  const submitButtonClick = () => {
    if (isBooster && boostedPropId === null) {
      setIsModalOpen(true);
    } else {
      submitPicks();
    }
  };

  const submitButton = (
    <button
      className={`${styles.submit__button} ${progress >= maxProgress ? styles.active : ""}`}
      onClick={submitButtonClick}
    >
      {t("submitProgress.button.submit")}
    </button>
  );

  const editButton = (
    <button
      className={`${styles.submit__EditButton} ${maxProgress === progress || !isEditing ? styles.active : ""}`}
      onClick={editPicks}
    >
      {t("submitProgress.button.edit")}
      <div className={styles.editIcon}>
        <div className={styles.editIcon__img}></div>
      </div>
    </button>
  );

  const progressBarWidth = (progress / maxProgress) * 100;
  const currentButton = !authContext.isLoggedIn
    ? signInButton
    : isEditing
      ? submitButton
      : editButton;

  return (
    <div className={styles.submit}>
      <div className={styles.submit__picks}>
        <p>
          {progress}/{maxProgress} {t("submitProgress.progress")}
        </p>
        <div className={styles.submit__slider}>
          <div className={styles.submit__progress} style={{ width: `${progressBarWidth}%` }}></div>
        </div>
      </div>
      {currentButton}
      {
        <ConfirmationModal
          visible={isModalOpen}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          title={t("submitProgress.boosterModalTitle")}
          description={t("submitProgress.boosterModalDescription")}
        />
      }
    </div>
  );
};

export default Submit;
