import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import httpClient from "@/api/httpClient";
import ConfigService from "@/services/ConfigService";
import { ConfigContext } from "@/providers/ConfigProvider";

import { setToast } from "@/components/toast/Toast.tsx";

import styles from "./QuestSetReward.module.scss";

import prizeBox from "../../assets/img/prizeBox.png";
import prizeBoxClosed from "../../assets/img/prizeboxClosed.png";

interface Props {
  tasks: {
    //   task: any[];
    casinoReward: any[];
    id: number;
    stepId: number;
    currentSetProgress: number;
    maxSetProgress: number;
  };

  userSegment: string | null;
  userProgress: any;
  setUserProgress: any;
}

const QuestSetReward = ({ tasks, userSegment, userProgress, setUserProgress }: Props) => {
  const { t } = useTranslation();
  const { redirectUrl } = useContext<any>(ConfigContext);

  const [claimingRewards, setClaimingRewards] = useState<Record<string, boolean>>({});

  const stepReward = useMemo(() => {
    const reward = tasks?.casinoReward?.find(
      (reward) =>
        (userSegment === null && reward.segmentId === null) ||
        (userSegment === "" && reward.segmentId === null) ||
        userSegment === reward.segmentId
    );

    const stepAward = userProgress?.awards?.find(
      (award: any) => award?.casinoReward?.quest?.id === tasks?.id
    );

    return reward ? { ...reward, userProgress: stepAward } : null;
  }, [tasks?.casinoReward, userSegment, userProgress, tasks?.id]);

  const stepRewardProgress = useMemo(() => {
    const stepAward = userProgress?.quests?.find((quest: any) => quest.stepId === tasks?.stepId);

    return stepAward;
  }, [tasks?.id, userProgress?.awards]);
  console.log("userProgress", userProgress);

  async function handleRewardClaim(fieldName: string, id: string | number) {
    setClaimingRewards((prev) => ({ ...prev, [id]: true }));
    try {
      let { data } = await httpClient.post(
        `/casino/${ConfigService.getPromoId()}/user/award/claim`,
        {
          [fieldName]: id,
        }
      );
      if (data) {
        // updateUserProgress(tasks.id, fieldName, id);
        setToast("success", t("casino.success"));
      } else {
        setToast("error", t("casino.error"));
      }
    } catch (err) {
      setToast("error", t("casino.error"));
    } finally {
      setClaimingRewards((prev) => ({ ...prev, [id]: false }));
    }
  }

  async function updateUserProgress(questId: any, name: string, id: string | number) {
    switch (name) {
      case "taskId":
        setUserProgress((prev: any) => {
          const newProgress = { ...prev };

          const task = newProgress.quests.find((quest: any) => quest.questId === questId);
          if (task) {
            task.tasks.forEach((task: any) => {
              if (task.taskId === id) {
                task.award.claimed = true;
              }
            });
          }
          return newProgress;
        });
        break;
      case "questId":
        setUserProgress((prev: any) => {
          const newProgress = { ...prev };

          const quest = newProgress.quests.find((quest: any) => quest.questId === id);
          if (quest) {
            quest.award.claimed = true;
          }
          return newProgress;
        });
        break;
      default:
        break;
    }
  }

  return (
    <div className={styles.questSetReward}>
      <div className={`${styles.reward}  ${styles.animate__slide__up} ${styles[`delay-1`]}`}>
        {stepRewardProgress?.award?.claimed ? (
          <img src={prizeBox} alt="" />
        ) : (
          <img src={prizeBoxClosed} alt="" />
        )}
        <p className={styles.rewardTitle}>{t("casino.missionReward")}</p>
        <h3 className={styles.rewardAmount}>
          {t("casino.rewardTranslation", {
            amount: stepReward.value,
            name: stepReward.name,
            game: stepReward.gameName,
            provider: stepReward.providerName,
          })}
        </h3>
        <div className={styles.progress}>
          <div className={styles.progressTrack}>
            <div
              style={{
                width: `${(tasks.currentSetProgress / tasks.maxSetProgress) * 100}%`,
              }}
            ></div>
          </div>
          <p>
            {tasks.currentSetProgress}/{tasks.maxSetProgress}
          </p>
        </div>
      </div>
      {/* )} */}
      {tasks.currentSetProgress === tasks.maxSetProgress &&
      stepRewardProgress?.award?.claimed === false &&
      tasks.currentSetProgress !== tasks.maxSetProgress ? (
        <div
          className={`${styles.mainClaim} ${claimingRewards[tasks.id] ? styles.disabled : ""}`}
          onClick={() => handleRewardClaim("questId", tasks.id)}
        >
          {claimingRewards[tasks.id] ? t("casinoWidget.loading") : t("casino.claim")}
        </div>
      ) : (
        <div
          className={`${styles.mainClaim} ${styles.disabled}`}
          onClick={() => handleRewardClaim("questId", tasks.id)}
        >
          {claimingRewards[tasks.id] ? t("casinoWidget.loading") : t("casino.claim")}
        </div>
      )}
    </div>
  );
};

export default QuestSetReward;
