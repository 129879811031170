const CloseIcon = ({ ...props }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path d="M16.0021 15.0021L20.7971 10.2071C20.9297 10.0745 21.0042 9.89464 21.0042 9.70711C21.0042 9.51957 20.9297 9.33972 20.7971 9.20711C20.6645 9.0745 20.4846 9 20.2971 9C20.1096 9 19.9297 9.0745 19.7971 9.20711L15.0021 14.0021L10.2071 9.20911C10.0745 9.0765 9.89464 9.002 9.70711 9.002C9.51957 9.002 9.33972 9.0765 9.20711 9.20911C9.0745 9.34172 9 9.52157 9 9.70911C9 9.89664 9.0745 10.0765 9.20711 10.2091L14.0021 15.0021L9.20911 19.7971C9.0765 19.9297 9.002 20.1096 9.002 20.2971C9.002 20.4846 9.0765 20.6645 9.20911 20.7971C9.34172 20.9297 9.52157 21.0042 9.70911 21.0042C9.89664 21.0042 10.0765 20.9297 10.2091 20.7971L15.0021 16.0021L19.7971 20.7971C19.9297 20.9297 20.1096 21.0042 20.2971 21.0042C20.4846 21.0042 20.6645 20.9297 20.7971 20.7971C20.9297 20.6645 21.0042 20.4846 21.0042 20.2971C21.0042 20.1096 20.9297 19.9297 20.7971 19.7971L16.0021 15.0021Z" />
    </svg>
  );
};

export default CloseIcon;
