import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";
import styles from "./MissionsFilter.module.scss";
import docs from "../../assets/img/docs.svg";
import Popup from "@/components/popup/Popup";
import rulesIcon from "@/assets/img/rules.svg";
import PromotionRules from "@/widgets/casinoWidget/components/promotionRules/PromotionRules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";

type Props = {
  filters: { label: string; value: string }[];
  activeFilter: string;
  onSelectFilter: (filter: string) => void;
};

const MissionsFilter: React.FC<Props> = ({ filters, activeFilter, onSelectFilter }) => {
  const { t } = useTranslation();
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);

  return (
    <div className={styles.filter}>
      <div className={styles.filter__content}>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView="auto"
          spaceBetween={8}
          freeMode={{
            enabled: true,
            momentum: true,
            momentumRatio: 0.8,
            momentumVelocityRatio: 0.8,
          }}
          modules={[FreeMode]}
          className={styles.swiper}
        >
          {filters.map((filter, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <div
                className={`${styles.filterButton} ${activeFilter === filter.value ? styles.active : ""}`}
                onClick={() => onSelectFilter(filter.value)}
              >
                {filter.label}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.filter__right}>
        <button className={styles.rules} onClick={() => setIsRulesOpen(true)}>
          <img src={docs} alt="Rules" />
          {t("casinoWidget.rules")}
        </button>
      </div>
      <Popup
        title={
          <div className={styles.rulesHeader}>
            <img src={rulesIcon} alt="rules" />
            {t("userAuthPrompt.rules")}
          </div>
        }
        headerClassName={styles.modalHeader}
        isOpen={isRulesOpen}
        onClose={() => setIsRulesOpen(false)}
      >
        <PromotionRules />
      </Popup>
    </div>
  );
};

export default MissionsFilter;
