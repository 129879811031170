import React, { createContext, useEffect, useState } from "react";
import ConfigService from "../services/ConfigService.ts";
import LoadingPage from "../components/loadingPage/LoadingPage.tsx";
import httpClient from "../api/httpClient.ts";
import { useMutation } from "@tanstack/react-query";

import { ACK_USER_SIGN_OUT } from "@/services/PostMessageService.ts";

interface UserResponse {
  id: string;
  displayName: string;
  segment?: string[];
}

interface AuthResponse {
  data?: {
    error?: { code: string; message: string };
    user: UserResponse;
    token: string;
  };
}

export interface AuthContextProps {
  isLoggedIn: boolean;
  getCurrentUser: UserResponse | null;
  token: string | null;
}

const loadUser = () => {
  const sessionId = ConfigService.getSession();
  if (!sessionId || sessionId === "undefined") {
    return {
      mutate: () => {},
      isPending: false,
      data: undefined,
    };
  }
  return useMutation<AuthResponse>({
    mutationKey: [`user-context-${sessionId}`],
    mutationFn: () => httpClient.post(`/auth`, { session: sessionId }),
    onError: (error: any) => {
      if (error.response && error.response.status === 401) {
        console.log("Unauthorized request. Please login again.", error.response.data);
        return null;
      }
    },
  });
};

const handleAuth = (response: AuthResponse | undefined) => {
  if (response && response.data?.user) {
    return {
      isLoggedIn: true,
      getCurrentUser: response.data.user,
      token: response.data.token,
    };
  }
  return {
    isLoggedIn: false,
    getCurrentUser: null,
    token: null,
  };
};

const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  getCurrentUser: null,
  token: null,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { mutate: getUserData, isPending, data: userData } = loadUser();
  const [authState, setAuthState] = useState(handleAuth(userData));

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    setAuthState(handleAuth(userData));
  }, [userData]);

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      // if (message.origin !== window.location.origin) {
      //   return;
      // }

      switch (message.data?.event) {
        case ACK_USER_SIGN_OUT:
          setAuthState({
            isLoggedIn: false,
            getCurrentUser: null,
            token: null,
          });
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (isPending) {
    return <LoadingPage />;
  }

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};

export { AuthContext };
