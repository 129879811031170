import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Pagination, Autoplay } from "swiper/modules";

import Banner from "@/components/bannerSlider/banner/Banner";

import styles from "./BannerSlider.module.scss";
import "swiper/css";
import "swiper/css/pagination";

type Props = {
  banners: any[];
  className?: string;
};
const BannerSlider = ({ banners, className = "" }: Props) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      className={`${styles.slider} ${className}`}
      pagination={true}
      modules={[Pagination, Autoplay]}
      loop={true}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
    >
      {banners &&
        banners.map((e: any, index: number) => {
          return (
            <SwiperSlide className={styles.slider__slide} key={index}>
              <Banner uri={e.uri} />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default BannerSlider;
