import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import styles from "./AutoRotatingSlider.module.scss";

interface SliderItem {
  id: string;
  content: React.ReactNode;
}

interface AutoRotatingSliderProps {
  items: SliderItem[];
  autoplayDelay?: number;
  spaceBetween?: number;
  className?: string;
}

const AutoRotatingSlider: React.FC<AutoRotatingSliderProps> = ({
  items,
  autoplayDelay = 5000,
  spaceBetween = 20,
  className = "",
}) => {
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={spaceBetween}
      slidesPerView="auto"
      autoplay={{ delay: autoplayDelay, disableOnInteraction: false }}
      loop={true}
      className={`${styles.autoRotatingSlider} ${className}`}
    >
      {items.map((item, index) => (
        <SwiperSlide key={`${item.id}-${index}`} className={styles.slide}>
          <div className={styles.sliderItem}>{item.content}</div>
        </SwiperSlide>
      ))}
      {items.map((item, index) => (
        <SwiperSlide key={`${item.id}-duplicate-${index}`} className={styles.slide}>
          <div className={styles.sliderItem}>{item.content}</div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AutoRotatingSlider;
