import styles from "./LobbyFilter.module.scss";

type Props = {
  filters: { label: string; value: string }[];
  activeFilter: string;
  onSelectFilter: (filter: string) => void;
};

const LobbyFilter = ({ filters, activeFilter, onSelectFilter }: Props) => {
  return (
    <div className={styles.filter}>
      <div className={styles.filter__content}>
        {filters.map((filter, index) => (
          <button
            key={index}
            className={activeFilter === filter.value ? styles.active : ""}
            onClick={() => onSelectFilter(filter.value)}
          >
            {filter.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LobbyFilter;
