import { useState } from "react";

import styles from "./MysteryBoxGame.module.scss";

import boxOpen from "./assets/img/box-open.jpg";
import boxClosed from "./assets/img/box-closed.jpg";

const Prize = () => {
  return (
    <div className={styles.prize}>
      <h4 className={styles.prize__value}>2 x 2 $</h4>
      <p className={styles.prize__name}>Freespin</p>
    </div>
  );
};

const MysteryBoxGame = () => {
  const [activeBox, setActiveBox] = useState<null | number>(null);
  const mockBoxes = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const gridSize = Math.sqrt(mockBoxes.length); // Example for 3x3 grid
  return (
    <div className={styles.container}>
      <div className={styles.game} style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
        {mockBoxes.map((box, index) => {
          return (
            <div
              onClick={() => setActiveBox(box)}
              key={index}
              className={`${styles.box} ${activeBox === box ? styles.active : ""} ${box === 9 ? styles.disabled : ""}`}
              style={{
                flex: `1 1 calc((100% / ${gridSize}) - 10px)`,
                height: `calc((100% / ${gridSize}) - 10px)`,
              }}
            >
              {/* {box} */}
              <img src={activeBox === box ? boxOpen : boxClosed} alt="" />
              {activeBox === box && (
                <div className={styles.box__overlay}>
                  <Prize />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MysteryBoxGame;
