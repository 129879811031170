import React, { ReactNode } from "react";

import styles from "./PlayerPropsCard.module.scss";

import Booster from "@/components/booster/Booster";

type PlayerProps = {
  className?: string;
  children?: ReactNode;
  editable?: boolean;
  disabled?: boolean;
  isBooster?: boolean;
  isBoosted?: boolean;
  toggleBooster?: any;
  matchStatus?: string | null;
};

const PlayerPropsCard: React.FC<PlayerProps> = ({
  className,
  editable,
  disabled,
  children,
  isBooster,
  isBoosted,
  toggleBooster,
  matchStatus,
}) => {
  return (
    <div
      className={`${styles.card} ${className ? className : ""} ${disabled ? styles.disabledBg : ""} ${isBoosted ? (matchStatus ? styles[matchStatus] : styles.isBoosted) : ""}`}
    >
      <div className={`${!editable ? styles.noEvents : ""} `}>
        {children}
        {isBooster && (
          <Booster isBoosted={isBoosted} toggleBooster={toggleBooster} matchStatus={matchStatus} />
        )}
      </div>
    </div>
  );
};

export default PlayerPropsCard;
