import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useViewportSize from "@/hooks/useViewportSize";
import { PickemPeriodType } from "@/widgets/pickemWidget/pickem.types";
import { ConfigContext } from "@/providers/ConfigProvider";

import styles from "./MainOverview.module.scss";
import Popup from "../popup/Popup";

type Props = {
  promoData: { startDate: string; endDate: string };
  className?: string;
  isPopup?: boolean;
};

enum RewardTemplateEnum {
  NONE = "NONE",
  RANKING = "RANKING",
  EXACT_GUESS = "EXACT_GUESS",
}

const filterRewardsByPeriod = (rewards: any[], period: PickemPeriodType) => {
  return rewards.filter((e: any) => e.period === period);
};

const sortRewards = (array: any[], order: "asc" | "desc") => {
  return array.sort((a, b) => {
    const rankA = parseInt(a.rank);
    const rankB = parseInt(b.rank);

    if (order === "asc") {
      return rankA - rankB;
    } else {
      return rankB - rankA;
    }
  });
};

const MainOverview = ({ promoData, className = "", isPopup }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const { reward, periodType, rewarding } = useContext<any>(ConfigContext);

  const uniquePeriods = useMemo(() => {
    const periods = Array.from(new Set(reward.map((r: any) => r.period))) as PickemPeriodType[];
    if (periods.includes(PickemPeriodType.OVERALL) && periods.length > 1) {
      periods.splice(periods.indexOf(PickemPeriodType.OVERALL), 1);
      periods.splice(1, 0, PickemPeriodType.OVERALL);
    }
    return periods;
  }, [reward]);

  const [selectedPeriod, setSelectedPeriod] = useState<PickemPeriodType>(
    uniquePeriods.length > 0 ? uniquePeriods[0] : PickemPeriodType.OVERALL
  );

  const filteredRewards = useMemo(() => {
    if (uniquePeriods.length === 0) {
      return reward;
    }
    return filterRewardsByPeriod(reward, selectedPeriod);
  }, [reward, selectedPeriod, uniquePeriods]);

  rewarding === RewardTemplateEnum.EXACT_GUESS
    ? sortRewards(filteredRewards, "desc")
    : sortRewards(filteredRewards, "asc");

  return (
    <div className={`${styles.overview} ${className}`}>
      <div className={styles.overview__head}>
        <h2>{t("title")}</h2>
        <h5>
          {t("description")}{" "}
          {!isPopup && width <= 940 && (
            <span className={styles.more} onClick={() => setIsModalOpen(true)}>
              {t("mainOverview.learnMore")}
            </span>
          )}
        </h5>
        <h6>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(promoData.startDate))}{" "}
          -{" "}
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(promoData.endDate))}
        </h6>
        {uniquePeriods.length > 1 && (width > 940 || isPopup) && (
          <div className={styles.periods}>
            <div
              className={styles.periodHighlight}
              style={{
                transform: `translateX(${selectedPeriod === PickemPeriodType.OVERALL ? "100%" : "0%"})`,
              }}
            ></div>
            {uniquePeriods.map((period) => (
              <button
                key={period}
                className={styles.periodButton}
                onClick={() => setSelectedPeriod(period)}
              >
                <p>{t(`leaderboardWidget.${period.toLowerCase()}`)}</p>
              </button>
            ))}
          </div>
        )}
      </div>
      {(width > 940 || isPopup) && (
        <div className={styles.overview__body}>
          <div className={styles.overview__table}>
            <table>
              <thead>
                <tr>
                  {rewarding === RewardTemplateEnum.EXACT_GUESS ? (
                    <th>{t("mainOverview.correctAnswer")}</th>
                  ) : (
                    <th>{t("mainOverview.rank")}</th>
                  )}
                  <th>{t("mainOverview.prize")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredRewards.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <p className={styles.prizeDesc}>{item.rank}</p>
                    </td>
                    <td>
                      <p>
                        {item.value} {item.name}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Popup isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MainOverview promoData={promoData} className={styles.popup} isPopup={true} />
      </Popup>
    </div>
  );
};

export default MainOverview;
