import MobileSlider from "@/components/mobileSlider/MobileSlider";
import Popup from "@/components/popup/Popup";
import arrowRight from "@/assets/img/arrow-icon-right.svg";
import useViewportSize from "@/hooks/useViewportSize";
import styles from "./ShowProviderNameModal.module.scss";
import { useState } from "react";
import { t } from "i18next";

type Props = {
  mission: any;
  setIsPopupVisibility: any;
};

const ShowProviderNameModal = ({ mission, setIsPopupVisibility }: Props) => {
  const { width } = useViewportSize();
  const providers = mission.providerNames;
  const games = mission.gameNames;
  const [isPopupOpen, setIsPopupOpen] = useState<any>(false);

  const popupVisible = () => {
    setIsPopupOpen(true);
    setIsPopupVisibility(true);
  };
  const popupHide = () => {
    setIsPopupOpen(false);
    setIsPopupVisibility(false);
  };

  switch (mission.taskType) {
    case "Login":
      break;
    case "Deposit":
      break;
    default:
      return (
        <>
          <div className={`${styles.arrow}`} onClick={popupVisible}>
            <h3>
              {mission.gameNames.length}
              {""}
              {t("casino.games")}
            </h3>
            <img src={arrowRight} />
          </div>
          {width >= 940 ? (
            <Popup
              isOpen={isPopupOpen}
              onClose={popupHide}
              title={
                <div className={styles.popupTitle} onClick={popupHide}>
                  <div className={`${styles.arrow} ${styles.arrow__prev}`}>
                    <img src={arrowRight} alt="" />
                  </div>
                  <p>Back</p>
                </div>
              }
              className={styles.popup}
              headerClassName={styles.popupHeader}
            >
              <div className={styles.renderGames}>
                <div className={styles.providerName}>
                  {providers.map((provider: string, index: number) => (
                    <h3 key={index}>{provider} , </h3>
                  ))}
                </div>
                <div className={styles.gameNames}>
                  {games.map((game: string, index: number) => (
                    <p key={index}>{game}</p>
                  ))}
                </div>
              </div>
            </Popup>
          ) : (
            <MobileSlider
              isOpen={isPopupOpen}
              onClose={popupHide}
              title={
                <div className={styles.mobileSliderTitle} onClick={popupHide}>
                  <div className={`${styles.arrow} ${styles.arrow__prev}`}>
                    <img src={arrowRight} alt="" />
                  </div>
                  <p>Back</p>
                </div>
              }
              className={styles.mobileSlider}
              hasGameNames={true}
            >
              <div className={styles.renderGames}>
                <div className={styles.providerName}>
                  {providers.map((provider: string, index: number) => (
                    <h3 key={index}>{provider} , </h3>
                  ))}
                </div>
                <div className={styles.gameNames}>
                  {games.map((game: string, index: number) => (
                    <p key={index}>{game}</p>
                  ))}
                </div>
              </div>
            </MobileSlider>
          )}
        </>
      );
  }
};

export default ShowProviderNameModal;
