import axios from "axios";
import URLParamService from "./URLParamService";
import httpClient from "@/api/httpClient.ts";

const queryParams = URLParamService.getQueryParams();
const promoId = queryParams.promoId;
const organizationId = queryParams.organizationId;
const sessionId = queryParams.session;
const lng = queryParams.lng;
const cdnBaseURL = import.meta.env.VITE_WIDGET_CDN_BASE_URL;

const isPromoValid = (): boolean => {
  if (queryParams.hasOwnProperty("promoId") && promoId) {
    return true;
  }
  return false;
};

const getPromoId = (): string => {
  if (queryParams.hasOwnProperty("promoId") && promoId?.length > 0) {
    return promoId;
  }
  return "";
};
const getOrganizationId = (): string => {
  if (queryParams.hasOwnProperty("organizationId") && organizationId?.length > 0) {
    return organizationId;
  }
  return "";
};

const getSession = (): string  => {
  if (queryParams.hasOwnProperty("session") && sessionId?.length > 0) {
    return sessionId;
  }
  return "undefined";
};

const getLng = (): string => {
  if (queryParams.hasOwnProperty("lng") && lng?.length > 0) {
    return lng;
  }
  return "";
};

const getPromoConfig = async () => {
  return axios.get(`${cdnBaseURL}/promo/${getPromoId()}/config.json`);
};


const ConfigService = {
  cdnBaseURL,
  isPromoValid,
  getPromoId,
  getPromoConfig,
  getSession,
  getLng,
};

export default ConfigService;
