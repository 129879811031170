import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCasinoData, useOptInStatus, useUserProgress } from "./casino.api";
import { AuthContext, AuthContextProps } from "@/providers/AuthProvider";
import { CasinoGameType, CasinoPromoConfig } from "./casino.types";
import { getCasinoGameType } from "./utils";

import CasinoLayoutA from "./layouts/CasinoLayoutA";
import CasinoLayoutB from "./layouts/CasinoLayoutB";
import LoadingPage from "@/components/loadingPage/LoadingPage";
import EmptyState from "@/components/emptyState/EmptyState";

import emptyStateImg from "@/assets/img/newEmpty.svg";

const CasinoWidget = () => {
  const authContext = useContext<AuthContextProps>(AuthContext);
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const {
    data: casinoData,
    isLoading: isCasinoDataLoading,
    isError: isCasinoDataError,
  } = useCasinoData();

  const {
    data: optInStatus,
    isLoading: isOptInStatusLoading,
    isError: isOptInStatusError,
  } = useOptInStatus(authContext, casinoData?.optIn && authContext.isLoggedIn);

  const isOptedIn =
    optInStatus === true ||
    (!!optInStatus && typeof optInStatus === "object" && !optInStatus.error);

  const {
    data: userProgressData,
    isLoading: isUserProgressLoading,
    isError: isUserProgressError,
  } = useUserProgress(
    authContext,
    authContext.isLoggedIn && (casinoData?.optIn === false || (casinoData?.optIn && isOptedIn))
  );

  if (isCasinoDataLoading || isOptInStatusLoading || isUserProgressLoading) return <LoadingPage />;

  if (error || isCasinoDataError || isOptInStatusError || isUserProgressError)
    return (
      <EmptyState img={emptyStateImg} title={t("emptyState.oops")} text={t("casinoWidget.error")} />
    );

  return (
    <CasinoApiRenderer
      casinoData={casinoData}
      userProgressData={userProgressData}
      isOptedIn={isOptedIn}
    />
  );
};
type CasinoApiProps = {
  casinoData: CasinoPromoConfig;
  userProgressData: any;
  isOptedIn: boolean;
};

const CasinoApiRenderer = ({ casinoData, userProgressData, isOptedIn }: CasinoApiProps) => {
  const [userProgress, setUserProgress] = useState<any>(userProgressData || null);

  switch (getCasinoGameType(casinoData.category)) {
    case CasinoGameType.AdventureMap:
    case CasinoGameType.Matrix:
    case CasinoGameType.Leaderboard:
    case CasinoGameType.Wheel:
    case CasinoGameType.MysteryBox:
      return (
        <CasinoLayoutA
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={casinoData?.optIn === true ? isOptedIn : true}
        />
      );
    case CasinoGameType.DailyOfferCalendar:
      return (
        <CasinoLayoutB
          casinoData={casinoData}
          userProgress={userProgress}
          setUserProgress={setUserProgress}
          isOptedIn={casinoData?.optIn === true ? isOptedIn : true}
        />
      );
    default:
      return null;
  }
};

export default CasinoWidget;
