import httpClient, { PromofyAuthHeaders } from "@/api/httpClient";
import { AuthContextProps } from "@/providers/AuthProvider";
import { useQuery } from "@tanstack/react-query";

export const loadMissions = (context: AuthContextProps) => {
  return useQuery({
    queryKey: ["missionsData"],
    queryFn: () =>
      httpClient.get(`/mission/lobby${!context.isLoggedIn ? "/organization" : ""}`, {
        headers: { ...PromofyAuthHeaders(context) },
      }),
    select: (res) => res.data,
  });
};
